import React,{useState,useEffect} from 'react'
import {Link,useHistory} from 'react-router-dom';
import { Grid, Paper, Avatar, TextField, Typography, makeStyles,FormControl,  InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Chip, Checkbox, Input, ListItemText } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import logo from './../../images/Hungama_logo.png'
import StoreModal from './../Reusable/stores.json';
import cogoToast from 'cogo-toast';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { object } from 'prop-types';
import * as qs from 'query-string'
import { parse } from 'date-fns';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '25ch',
    },
    button:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2),
    }
  }));

  


function Login(props){

    useEffect(() => {
        const parsed = qs.parse(window.location.search);
        console.log('====',parsed);
        setName(parsed.username)
        setId(parsed.id)
    }, [])

    const paperStyle = { padding: 20, height: '76vh', width: 300, margin: "70px auto" }
    const optionStyle = {  width: 200 }
    // const avatarStyle = { backgroundColor: '#1bbd7e' }
    // const btnstyle = { margin: '8px 0' }
    const [name, setName] = useState("")
    const [id, setId] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [Repassword, setRePassword] = useState("")
    const [contents, setContents] = useState("1");
    const [langList, setLanglist] = useState([]);
    const classes = useStyles()
    const validationSchema = Yup.object().shape({
        username: Yup.string().email('please enter valid email').required("Required"),
        password: Yup.string().required("Required")
    });
    const navigate = useHistory(); 

    console.log(StoreModal);

     const onSubmit = async () => {
        if(password === Repassword){
        let object ={
            "id":id,
            "username":name,
            "old_password":email,
            "new_password":password
        }
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/user/resetPassword`,object).then(
            res => {
                console.log('ressssss',res);
                if(res && res.data.message === "success"){
                    cogoToast.success('password reset successfully please try to login with new credentials');
                    setTimeout(() => {
                        navigate.push('/login');
                    }, 1500);
                }
                else{
                    cogoToast.error(res.data.message)
                }
             
            }
          ).catch(err => {
            cogoToast.error('invalid user')
            console.log('errrrrrrrrr',err);
          })
        }
        else{
            cogoToast.error('both password does not match')
        }
    }
    return (
        <Grid>
            <Paper style={paperStyle}>
                <Grid align='center'>
                    <img src={logo} width="200px"/>
                    {/* <Avatar style={avatarStyle}><LockOutlinedIcon /></Avatar> */}
                    <h5>Reset Password</h5>
                </Grid>
                {/* <TextField className={classes.inputField} id="standard-basic" label="Email"
                name="email" 
                value={email} 
                onChange={(e) => setEmail(e.taget.value)}
                /> */}
                <Grid>
                <TextField  className={classes.inputField} disabled width="25" label="Username" value={name} onChange={(e) => setName(e.target.value)}>
                            </TextField>
                            </Grid>
                <Grid>
                <TextField  className={classes.inputField}  type='password' width="25" label="Old Password" value={email} onChange={(e) => setEmail(e.target.value)}>
                            </TextField>
                            </Grid>
                            <Grid>
                            <TextField type='password'  className={classes.inputField} width="25" label="New Password" value={password} onChange={(e) => setPassword(e.target.value)}>
                            </TextField>
                            </Grid>
                            <Grid>
                            <TextField type='password'  className={classes.inputField} width="25" label="Re-Enter Password" value={Repassword} onChange={(e) => setRePassword(e.target.value)}>
                            </TextField>
                            </Grid>
                            <br></br>

                        
                {/* <TextField className={classes.inputField} id="standard-basic" label="Password" name="password"
                onChange={(e) => setPassword(e.taget.value)} 
                value={password} /> */}
                
                
                            
        
                
                {/* <Typography >
                    <Link href="#" >
                        Forgot password ?
                </Link>
                </Typography> */}

                <Button className={classes.button} variant="contained" onClick={onSubmit} color="primary">Reset</Button>
                {/* <Typography > Do you have an account ?
                     <Link href="#" onClick={() => handleChange("event", 1)} >
                        Sign Up
                </Link>
                </Typography> */}
            </Paper>
        </Grid>
    )
            
}

export default Login;
