const initialState = {
    page:{},
    error_message:null,
    success_message:null,
}

const pageReducer=(state=initialState, action)=>{
    switch(action.type){
        case "ON_ADDPAGE_SUCCESS":
            return{
                ...state,
                success_message:action.payload,
                error_message:null,
            }
        case "ON_ADDPAGE_FAILURE":
            return{
                ...state,
                success_message:null,
                error_message:action.payload,
            }    
        default:
            return{
                ...state
            }    
    }
}

export default pageReducer;