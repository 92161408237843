import React,{useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import StoreModal from './../Reusable/stores.json';
import { Grid, Paper, Avatar, TextField, Typography, makeStyles,FormControl,  InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Chip, Checkbox, Input, ListItemText } from '@material-ui/core'

export default function CopyModal(props) { 
    const onCopyButton = () => {
        props.copyContent(contents);
        props.toggle1()
    }
    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        textField: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(2),
          width: '25ch',
        },
        button:{
            marginTop:theme.spacing(2),
            marginBottom:theme.spacing(2),
        }
      }));
      const classes = useStyles()
    const optionStyle = {  width: 200 };
    const [contents, setContents] = useState("1");
    return (
        <div>
            <Modal isOpen={props.copyModal} toggle={props.toggle} style={{marginTop:"100px"}}>
                {/* <ModalHeader toggle={props.toggle}>Modal title</ModalHeader> */}
                <ModalBody>
                    Are you sure you want to copy?
                </ModalBody>
                <ModalFooter>
                    Select store
                <FormControl style={optionStyle} variant="outlined" className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Stores</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contents}
                                onChange={(e) => setContents(e.target.value)}
                                label="Stores"
                            >
                                {
                                    StoreModal && StoreModal.length > 0 ?
                                        StoreModal.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.storeId}>{element.description}&nbsp;<img src={element.image}/></MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Stores Available</MenuItem>
                                }

                            </Select>

                        </FormControl>
                        &nbsp;
                    <Button color="primary" onClick={onCopyButton}>Copy</Button>{' '}
                    <Button color="secondary" onClick={props.toggle1}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
