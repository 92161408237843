
import React, { Fragment, useState, useEffect } from "react";

import {
    TablePagination,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    Paper,
    Box,
    CircularProgress
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import axios from 'axios';
import cogoToast from 'cogo-toast';

export default function ListLanguage(props) {

    const classes = useStyles();
    const [pagination, setPagination] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    // const headers = data?Object.keys(data[0]):null;

    const fetchData = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {
                console.log(res)
                setData(res.data.result.result)
                setIsLoading(false);
                cogoToast.success(res.data.result.message);

            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPagination(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPagination(0);
    };
    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <h1>List Language</h1>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>code</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading == true ? <CircularProgress /> :
                                data && data.length > 0 ? data
                                    .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                                    .map((emp, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{emp.name}</TableCell>
                                            <TableCell>{emp.code}</TableCell>
                                            <TableCell>
                                                <EditOutlinedIcon className={classes.btnedit} />
                                                <DeleteForeverOutlinedIcon
                                                    className={classes.btndelete}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )) : <p className="mt-2 text-center">no data</p>}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 50,100]}
                        component="div"
                        count={data ? data.length : null}
                        rowsPerPage={rowsPerPage}
                        page={pagination}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
        </Fragment>
    );

}
