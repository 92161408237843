import { TextField } from '@material-ui/core';
import React from 'react'
import { ReactTransliterate ,TriggerKeys} from 'react-transliterate';
import { Modal, ModalBody, ModalHeader,ModalFooter, Button } from 'reactstrap';

export default function MultiLanguageModal(props) {
    return (
        <>
              <Modal style={{ marginTop: "100px" }} isOpen={props.modal} toggle={props.toggle}>
                        <ModalHeader toggle={props.toggle}>MultiLanguage</ModalHeader>
                        <ModalBody>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="English" name="en" value={props.englishLang} onChange={(e)=>props.setEnglishLang(e.target.value)}>
                            </TextField>
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="hi" label="Hindi" {...props} inputRef={inputRef} />;
                                }}
                                value={props.hindiLang}
                                onChangeText={(text) => {
                                    props.setHindiLang(text);
                                }}
                                lang="hi"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            {/* <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Marathi" name="name" value={marathiLang} onChange={e => setMarathiiLang(e.target.value)} >
                            </TextField> */}
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="mr" label="Marathi" {...props} inputRef={inputRef} />;
                                }}
                                value={props.marathiLang}
                                onChangeText={(text) => {
                                    props.setMarathiiLang(text);
                                }}
                                lang="mr"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            {/* <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Kannada" name="name" value={kannadaLang} onChange={e => setKannadaLang(e.target.value)} >
                            </TextField> */}
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="kn" label="Kannada" {...props} inputRef={inputRef} />;
                                }}
                                value={props.kannadaLang}
                                onChangeText={(text) => {
                                    props.setKannadaLang(text);
                                }}
                                lang="kn"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            {/* <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Telugu" name="name" value={teluguLang} onChange={e => setTeluguLang(e.target.value)} >
                            </TextField> */}
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="te" label="Telugu" {...props} inputRef={inputRef} />;
                                }}
                                value={props.teluguLang}
                                onChangeText={(text) => {
                                    props.setTeluguLang(text);
                                }}
                                lang="te"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            {/* <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Punjabi" name="name" value={punjabiLang} onChange={e => setPunjabiLang(e.target.value)} >
                            </TextField> */}
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="pa" label="Punjabi" {...props} inputRef={inputRef} />;
                                }}
                                value={props.punjabiLang}
                                onChangeText={(text) => {
                                    props.setPunjabiLang(text);
                                }}
                                lang="pa"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            {/* <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Tamil" name="name" value={tamilLang} onChange={e => setTamilLang(e.target.value)} >
                            </TextField> */}
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="ta" label="Tamil" {...props} inputRef={inputRef} />;
                                }}
                                value={props.tamilLang}
                                onChangeText={(text) => {
                                    props.setTamilLang(text);
                                }}
                                lang="ta"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            {/* <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Gujrati" name="name" value={gujaratiLang} onChange={e => setGujaratiLang(e.target.value)} >
                            </TextField> */}
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="gu" label="Gujrati" {...props} inputRef={inputRef} />;
                                }}
                                value={props.gujaratiLang}
                                onChangeText={(text) => {
                                    props.setGujaratiLang(text);
                                }}
                                lang="gu"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />

<ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="or" label="Odia" {...props} inputRef={inputRef} />;
                                }}
                                value={props.odisaLang}
                                onChangeText={(text) => {
                                    props.setOdisaLang(text);
                                }}
                                lang="or"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="ml" label="Malayalam" {...props} inputRef={inputRef} />;
                                }}
                                value={props.malayalamLang}
                                onChangeText={(text) => {
                                    props.setMalayalamLang(text);
                                }}
                                lang="ml"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="bn" label="Bengali" {...props} inputRef={inputRef} />;
                                }}
                                value={props.bengaliLang}
                                onChangeText={(text) => {
                                    props.setBengaliLang(text);
                                }}
                                lang="bn"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="ur" label="Urdu" {...props} inputRef={inputRef} />;
                                }}
                                value={props.urduLang}
                                onChangeText={(text) => {
                                    props.setUrduLang(text);
                                }}
                                lang="ur"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="si" label="Sinhala" {...props} inputRef={inputRef} />;
                                }}
                                value={props.sinhalaLang}
                                onChangeText={(text) => {
                                    props.setSinhalaLang(text);
                                }}
                                lang="si"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="si" label="Arabic" {...props} inputRef={inputRef} />;
                                }}
                                value={props.arabicLang}
                                onChangeText={(text) => {
                                    props.setArabicLang(text);
                                }}
                                lang="ar"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                             <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="khm" label="Khmer" {...props} inputRef={inputRef} />;
                                }}
                                value={props.khmerLang}
                                onChangeText={(text) => {
                                    props.setKhmerLang(text);
                                }}
                                lang="khm"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                             <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="af" label="Afgani" {...props} inputRef={inputRef} />;
                                }}
                                value={props.afganiLang}
                                onChangeText={(text) => {
                                    props.setAfganiLang(text);
                                }}
                                lang="af"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required name="bur" label="Burmese" {...props} inputRef={inputRef} />;
                                }}
                                value={props.burmeseLang}
                                onChangeText={(text) => {
                                    props.setBurmeseLang(text);
                                }}
                                lang="bur"
                                triggerKeys={[
                                    TriggerKeys.KEY_RETURN,
                                    TriggerKeys.KEY_ENTER,
                                    TriggerKeys.KEY_SPACE,
                                    TriggerKeys.KEY_TAB,
                                  ]}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={props.toggle}>Save</Button>{' '}
                            <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
        </>
    )
}
