import React, { useState, Fragment } from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Select,
    InputLabel,
    MenuItem,
    IconButton


} from '@material-ui/core';

import { useStyles } from '../../styles/style';
import axios from 'axios';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from "@material-ui/icons/Clear";
import cogoToast from 'cogo-toast';
import {product_type} from './../bucket/objects'
import {mode_type} from './../bucket/objects'

export default function CreateEvent(props) {
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [name, setName] = useState();
    const [artistName, setArtistName] = useState("");
    const [artistID, setArtistID] = useState("");
    const [contentID, setcontentID] = useState("");
    const [about, setAbout] = useState("");
    const [url, setUrl] = useState("");
    const [ticketCost, setTicketCost] = useState(0);
    const [stencil, setStencil] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const [squareImage, setSquareImage] = useState("");
    const [discountPrice, setDiscountPrice] = useState(0);
    const [currency, setCurrency] = useState("");
    const [country, setCountry] = useState("");
    const [coins, setCoins] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isOff, setIsOff] = useState("UN");
    const [mode, setMode] = useState("");

    const postApi = async (data) => {
        const fd = new FormData();
        fd.append("type", "events")
        fd.append("name", data.name);
        fd.append("artistName", data.artistName);
        fd.append("date", data.date);
        fd.append("artistId", data.artistId);
        fd.append("contentId", data.contentId);
        fd.append("url", data.url);
        fd.append("ticketCost", data.ticketCost);
        fd.append("about", data.about);
        fd.append("duration", data.duration);
        fd.append("discountPrice", data.discountPrice);
        fd.append("currency", data.currency);
        fd.append("country", data.country);
        fd.append("coins", data.coins);
        fd.append("product", data.product);
        fd.append("mode", data.mode);
        fd.append("storeId", data.storeId);
        for (let index = 0; index < data.image.length; index++) {
            const element = data.image[index];
            fd.append('file', element)
        }
        for (let index = 0; index < data.thumbnail.length; index++) {
            const element = data.thumbnail[index];
            fd.append('avatar', element)
        }
        for (let index = 0; index < data.squareImage.length; index++) {
            const element = data.squareImage[index];
            fd.append('squareImage', element)
        }
        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/event`, fd)
            .then(
                res => {
                    cogoToast.success("success")
                }
            ).catch(err => {
                console.log(err.response);
                cogoToast.error(err.response.data.message);
            })

    }


    const onSubmit = () => {
        let obj = {
            name: name,
            artistName: artistName,
            artistId: artistID,
            contentId : contentID,
            url: url,
            ticketCost: parseInt(ticketCost, 10),
            about: about,
            date: date === null ? "" : date,
            image: stencil,
            thumbnail: thumbnail,
            squareImage:squareImage,
            discountPrice:parseInt(discountPrice, 10) ,
            currency: currency,
            country: country,
            coins: parseInt(coins, 10),
            duration:duration,
            product:isOff,
            mode:mode,
            "storeId":localStorage.getItem("storeId")
        }
        if(duration){
            if(duration >=0){
                postApi(obj)
            }
            else{
                cogoToast.error("duration can not be less than 0")
            }
        }
        else{
            postApi(obj) 
        }
        setName("")
        setArtistName("")
        setArtistID("")
        setcontentID("")
        setAbout("")
        setStencil("")
        setThumbnail("")
        setTicketCost("")
        setUrl("")
        setDiscountPrice("")
        setCoins("")
        setCountry("")
        setCurrency("")
        setDuration("")
    }

    const onClear = () => {
        setName("")
        setArtistName("")
        setArtistID("")
        setcontentID("")
        setAbout("")
        setStencil("")
        setThumbnail("")
        setTicketCost("")
        setUrl("")
        setDiscountPrice("")
        setCoins("")
        setCountry("")
        setCurrency("")
        setDuration("")
    }

    const deleteStencil = (e, i) => {
        let newArray = stencil.filter((ele, index) => {
            return index !== i;
        })

        setStencil(newArray)
    }

    const deleteBackground = (e, i) => {
        let newArray = thumbnail.filter((ele, index) => {
            return index !== i;
        })

        setThumbnail(newArray)
    }
    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            cogoToast.error("duration can not be less than 0")
            // console.log(e)
            // e.preventDefault();
        }
    }
    const deletesquareImage = (e, i) => {
        let newArray = squareImage.filter((ele, index) => {
            return index !== i;
        })

        setSquareImage(newArray)
    }
    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div>
                    <h1 className="text-center mb-5" >Add Event</h1>
                    <Grid container style={{ marginLeft: "20px" }}>
                        <Grid item md={6} sm={12}>
                            <TextField style={{ width: "60%" }} variant="outlined" className={classes.inputField} width="25" required label="Name" value={name} onChange={(e) => setName(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            {/* Product:  <button tyle={{ width: "40%" }} width="25" required label="Product" onClick={() => setIsOff(!isOff)}>{ isOff ? 'ON' : 'OFF' }</button> */}
                            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Product Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={isOff}
                                onChange={(e) => setIsOff(e.target.value)}
                                label="Product type"
                            >
                                {
                                    product_type && product_type.length > 0 ?
                                        product_type.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.value}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Product Type</MenuItem>
                                }

                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            {/* Product:  <button tyle={{ width: "40%" }} width="25" required label="Product" onClick={() => setIsOff(!isOff)}>{ isOff ? 'ON' : 'OFF' }</button> */}
                            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Mode</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                label="Mode"
                            >
                                {
                                    mode_type && mode_type.length > 0 ?
                                        mode_type.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.value}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Mode</MenuItem>
                                }

                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Artist Name" value={artistName} onChange={(e) => setArtistName(e.target.value)}>
                            </TextField>
                        </Grid>
                
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Artist Id" value={artistID} onChange={(e) => setArtistID(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" label="Content Id" value={contentID} onChange={(e) => setcontentID(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="About" value={about} onChange={(e) => setAbout(e.target.value)}>
                            </TextField>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Date time" className={classes.inputField} value={date} onChange={e => setDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!date}
                                    onClick={() => setDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Duration" value={duration} onKeyPress={preventMinus} onChange={(e) => setDuration(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                style={{ marginLeft: "10px" }}
                                variant="outlined"
                                component="label"
                            >
                                Thumbnail
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setThumbnail([...thumbnail, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {thumbnail.length > 0 ?
                                (thumbnail.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                style={{ marginLeft: "10px" }}
                                variant="outlined"
                                component="label"
                            >
                                SquareImage
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setSquareImage([...squareImage, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {squareImage.length > 0 ?
                                (squareImage.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletesquareImage(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                style={{ marginLeft: "10px" }}
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Detail Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setStencil([...stencil, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 10000 } }} variant="outlined" className={classes.inputField} width="25" required label="Ticket Cost" value={ticketCost} onChange={(e) => setTicketCost(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 10000 } }} variant="outlined" className={classes.inputField} width="25" label="Dicount Price" value={discountPrice} onChange={(e) => setDiscountPrice(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" label="Currency" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" label="Country" value={country} onChange={(e) => setCountry(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 10000 } }} variant="outlined" className={classes.inputField} width="25" label="Coins" value={coins} onChange={(e) => setCoins(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Url" value={url} onChange={(e) => setUrl(e.target.value)}>
                            </TextField>
                        </Grid>


                        <Grid item md={12} className="mt-4">
                            <Button className="mr-2" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>

                    </Grid>

                </div>

            </Paper>
        </Fragment>
    )
}
