import React, { useState, Fragment, useEffect } from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Select,
    InputLabel,
    MenuItem,
    IconButton


} from '@material-ui/core';

import { useStyles } from '../../styles/style';
import axios from 'axios';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from "@material-ui/icons/Clear";
import cogoToast from 'cogo-toast';
import { setSeconds } from 'date-fns';
import {product_type} from './../bucket/objects'
import {mode_type} from './../bucket/objects'



export default function EditEvent(props) {
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [name, setName] = useState();
    const [artistName, setArtistName] = useState("");
    const [artistID, setArtistID] = useState("");
    const [contentID, setcontentID] = useState("");
    const [about, setAbout] = useState("");
    const [url, setUrl] = useState("");
    const [ticketCost, setTicketCost] = useState("");
    const [stencil, setStencil] = useState("");
    const [deleteStencil, setDeleteStencil] = useState([]);
    const [thumbnail, setThumbnail] = useState("");
    const [squareImage, setSquareImage] = useState("");
    const [deleteThumbnail, setDeleteThumbnail] = useState([]);
    const [deleteSquareImage, setDeleteSquareImage] = useState([]);
    const [discountPrice, setDiscountPrice] = useState("");
    const [currency, setCurrency] = useState("");
    const [country, setCountry] = useState("");
    const [coins, setCoins] = useState(0);
    const [duration, setDuration] = useState("");
    const [isOff, setIsOff] = useState("");
    const [mode, setMode] = useState("");

    const fetchEventById = () => {
        axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/event/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    setAbout(res.data.data[0].about)
                    setArtistID(res.data.data[0].artistId)
                    setcontentID(res.data.data[0].contentId ? res.data.data[0].contentId : '')
                    setArtistName(res.data.data[0].artistName)
                    setIsOff(res.data.data[0].product)
                    setMode(res.data.data[0].mode)
                    // if (res.data.data[0].date !== null && res.data.data[0].date !== undefined) {
                    //     let d = new Date(res.data.data[0].date)
                    //     d.setHours(d.getHours() - 5);
                    //     d.setMinutes(d.getMinutes() - 30);
                    //     setDate(d)
                    // } else {
                    //     setDate(null)
                    // }
                    setDate(res.data.data[0].date)
                    setName(res.data.data[0].name)
                    setTicketCost(res.data.data[0].ticketCost)
                    setUrl(res.data.data[0].url)
                    setStencil(res.data.data[0].image)
                    setThumbnail(res.data.data[0].thumbnail)
                    setSquareImage(res.data.data[0].squareImage ? res.data.data[0].squareImage : "")
                    setDiscountPrice(res.data.data[0].discountPrice?res.data.data[0].discountPrice:0)
                    setCurrency(res.data.data[0].currency?res.data.data[0].currency:"")
                    setCountry(res.data.data[0].country?res.data.data[0].country:"")
                    setCoins(res.data.data[0].coins?res.data.data[0].coins:0)
                    setDuration(res.data.data[0].duration?res.data.data[0].duration:"")
                }
            ).catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        fetchEventById()
    }, [])


    const postApi = async (data) => {
        const fd = new FormData();
        fd.append("type", "events")
        fd.append("name", data.name);
        fd.append("artistName", data.artistName);
        fd.append("date", data.date);
        fd.append("artistId", data.artistId);
        fd.append("contentId", data.contentId);
        fd.append("url", data.url);
        fd.append("ticketCost", data.ticketCost);
        fd.append("about", data.about);
        fd.append("duration", data.duration);
        fd.append("discountPrice", data.discountPrice);
        fd.append("currency", data.currency);
        fd.append("country", data.country);
        fd.append("coins", data.coins);
        fd.append("product", data.product);
        fd.append("mode", data.mode);
        fd.append("storeId", data.storeId);
        data.deleteImage.map((ele, i) => {
            fd.append(`deleteImage[${i}]`, ele)
        })
        for (let index = 0; index < data.image.length; index++) {
            const element = data.image[index];
            if (typeof (element) !== "string") {
                fd.append('file', element)
            }
        }
        data.deleteThumbnail.map((ele, i) => {
            fd.append(`deleteThumbnail[${i}]`, ele)
        })
        for (let index = 0; index < data.thumbnail.length; index++) {
            const element = data.thumbnail[index];
            if (typeof (element) !== "string") {
                fd.append('avatar', element)
            }
        }
        data.deleteSquareImage.map((ele, i) => {
            fd.append(`deleteSquareImage[${i}]`, ele)
        })
        for (let index = 0; index < data.squareImage.length; index++) {
            const element = data.squareImage[index];
            if (typeof (element) !== "string") {
                fd.append('squareImage', element)
            }
        }
        await axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/event/${props.match.params.id}`, fd)
            .then(
                res => {
                    cogoToast.success("success")
                    props.history.push('/listEvent')
                }
            ).catch(err => {
                console.log(err);
            })

    }


    const onSubmit = () => {
        let obj = {
            name: name,
            artistName: artistName,
            artistId: artistID,
            contentId : contentID,
            url: url,
            ticketCost: parseInt(ticketCost, 10),
            about: about,
            "date": date === null ? "" : date,
            image: stencil,
            thumbnail: thumbnail,
            squareImage:squareImage,
            deleteImage: deleteStencil,
            deleteThumbnail: deleteThumbnail,
            deleteSquareImage:deleteSquareImage,
            discountPrice:parseInt(discountPrice, 10) ,
            currency: currency,
            country: country,
            coins: parseInt(coins, 10),
            duration:duration,
            product:isOff,
            mode:mode,
            "storeId":localStorage.getItem("storeId")
        }
        console.log('==',obj);
        if(duration){
            if(duration >=0){
                postApi(obj)
            }
            else{
                cogoToast.error("duration can not be less than 0")
            }
        }
        else{
            postApi(obj) 
        }

    }

    const onClear = () => {
        setName("")
        setArtistName("")
        setArtistID("")
        setcontentID("")
        setAbout("")
        setStencil("")
        setThumbnail("")
        setTicketCost("")
        setUrl("")
        setDiscountPrice("")
        setCoins("")
        setCountry("")
        setCurrency("")
        setDuration("")
    }

    const deleteStencilImage = (e, i, ele) => {
        let newArray = stencil.filter((ele, index) => {
            return index !== i;
        })
        setDeleteStencil([...deleteStencil, ele])
        setStencil(newArray)
    }

    const deleteBackground = (e, i, ele) => {
        let newArray = thumbnail.filter((ele, index) => {
            return index !== i;
        })
        setDeleteThumbnail([...deleteThumbnail, ele])
        setThumbnail(newArray)
    }
    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            cogoToast.error("duration can not be less than 0")
            // console.log(e)
            // e.preventDefault();
        }
    }
    const deletesquareImage = (e, i, ele) => {
        let newArray = squareImage.filter((ele, index) => {
            return index !== i;
        })
        setDeleteSquareImage([...deleteSquareImage, ele])
        setSquareImage(newArray)
    }
    

    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div>
                    <h1 className="text-center mb-5" >Edit Event</h1>
                    <Grid container style={{ marginLeft: "20px" }}>
                        <Grid item md={6} sm={12}>
                            <TextField style={{ width: "60%" }} variant="outlined" className={classes.inputField} width="25" required placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            {/* Product:  <button tyle={{ width: "40%" }} width="25" required label="Product" onClick={() => setIsOff(!isOff)}>{ isOff ? 'ON' : 'OFF' }</button> */}
                            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Product Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={isOff}
                                onChange={(e) => setIsOff(e.target.value)}
                                label="Product type"
                            >
                                {
                                    product_type && product_type.length > 0 ?
                                        product_type.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.value}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Product Type</MenuItem>
                                }

                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            {/* Product:  <button tyle={{ width: "40%" }} width="25" required label="Product" onClick={() => setIsOff(!isOff)}>{ isOff ? 'ON' : 'OFF' }</button> */}
                            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Mode</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                label="Mode"
                            >
                                {
                                    mode_type && mode_type.length > 0 ?
                                        mode_type.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.value}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Mode</MenuItem>
                                }

                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Artist Name" value={artistName} onChange={(e) => setArtistName(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Artist Id" value={artistID} onChange={(e) => setArtistID(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" label="Content Id" value={contentID} onChange={(e) => setcontentID(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="About" value={about} onChange={(e) => setAbout(e.target.value)}>
                            </TextField>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Date time" className={classes.inputField} value={date} onChange={e => setDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!date}
                                    onClick={() => setDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Duration" value={duration} onKeyPress={preventMinus} onChange={(e) => setDuration(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                style={{ marginLeft: "10px" }}
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setStencil([...stencil, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => (<div><p className="mt-2 ml-4" style={{ cursor: "pointer", display: "inline" }} onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deleteStencilImage(e, i, ele)} /></div>)))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                style={{ marginLeft: "10px" }}
                                variant="outlined"
                                component="label"
                            >
                                Thumbnail
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setThumbnail([...thumbnail, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {thumbnail.length > 0 ?
                                (thumbnail.map((ele, i) => (<div><p className="mt-2 ml-4" style={{ cursor: "pointer", display: "inline" }} onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deleteBackground(e, i, ele)} /></div>)))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                style={{ marginLeft: "10px" }}
                                variant="outlined"
                                component="label"
                            >
                                SquareImage
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setSquareImage([...squareImage, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {squareImage.length > 0 ?
                                (squareImage.map((ele, i) => (<div><p className="mt-2 ml-4" style={{ cursor: "pointer", display: "inline" }} onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deletesquareImage(e, i, ele)} /></div>)))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 10000 } }} variant="outlined" className={classes.inputField} width="25" required label="Ticket Cost" value={ticketCost} onChange={(e) => setTicketCost(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 10000 } }} variant="outlined" className={classes.inputField} width="25"  label="Dicount Price" value={discountPrice} onChange={(e)=>setDiscountPrice(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25"  label="Currency" value={currency} onChange={(e)=>setCurrency(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25"  label="Country" value={country} onChange={(e)=>setCountry(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 10000 } }} variant="outlined" className={classes.inputField} width="25"  label="Coins" value={coins} onChange={(e)=>setCoins(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Url" value={url} onChange={(e) => setUrl(e.target.value)}>
                            </TextField>
                        </Grid>


                        <Grid item md={12} className="mt-4">
                            <Button className="mr-2" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>

                    </Grid>

                </div>

            </Paper>
        </Fragment>
    )
}
