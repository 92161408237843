import React, { useState, Fragment } from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Select,
    InputLabel,
    MenuItem

} from '@material-ui/core';

import { useStyles } from '../../styles/style';
import axios from 'axios';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';


export default function CreatePlayList(props) {
    const classes = useStyles();
    const [category, setCategory] = useState("");
    const [publicStatus, setPublicStatus] = useState("true");
    const [publishedStatus, setPublishedStatus] = useState("true");
    const [liveDate, setLiveDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [select, setSelect] = useState([]);
    const [name, setName] = useState();
    const [name2, setName2] = useState();
    const [stencil, setStencil] = useState("");
    const [background, setBackground] = useState("");

    const handlePublic = (e) => {
        setPublicStatus(e.target.value);
    }

    const handlePublished = (e) => {
        setPublishedStatus(e.target.value);
    }

    const handleCategory = (e) => {
        setCategory(e.target.value)
    }

    const categories = [{
        id: 1,
        value: "Music"
    }, {
        id: 3,
        value: "Video"
    }, {
        id: 3,
        value: "podcast"
    },{
        id: 4,
        value: "Charts"
    },
]

// convert string to boolean
var str2bool = (value) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
}

const postApi = async (data) => {
    const fd = new FormData();
    fd.append("type", "55555");
    fd.append("name", data.name);
    fd.append("category", data.category);
    fd.append("live_date_time", data.live_date_time);
    fd.append("expiry", data.expiry);
    fd.append("public", data.public);
    fd.append("published", data.published);
    for (let index = 0; index < data.stencil.length; index++) {
        const element = data.stencil[index];
        fd.append('file', element)
    }
    for (let index = 0; index < data.background.length; index++) {
        const element = data.background[index];
        fd.append('avatar', element)
    }
    await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist`, fd)
        .then(
            res => {
                console.log(res)
            }
        ).catch(err => {
            console.log(err);
        })

}


const onSubmit = () => {
    let obj = {
        name:name,
        category:category,
        select:select,
        name_:name2,
        public:str2bool(publicStatus),
        published:str2bool(publishedStatus),
        "live_date_time": liveDate,
        "expiry": expiryDate,
        stencil:stencil,
        background:background,
    }
    console.log("obj",obj)
    postApi(obj)
    setName("")
    setName2("")
}

const deleteStencil = (e, i) => {
    let newArray = stencil.filter((ele, index) => {
        return index !== i;
    })

    setStencil(newArray)
}

const deleteBackground = (e, i) => {
    let newArray = background.filter((ele, index) => {
        return index !== i;
    })

    setBackground(newArray)
}

    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div>
                    <h1 className="text-center mb-5" >Add PlayList</h1>
                    <Grid container style={{marginLeft:"20px"}}>
                        <Grid item md={9} sm={12}>
                            <TextField style={{width:"40%"}} variant="outlined" className={classes.inputField} width="25" required label="Name" onChange={(e)=>setName(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <Grid container>
                                <Grid item md={6}>
                                <FormControl style={{minWidth:"80% !important", paddingRight:"0px"}} variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={category}
                                    onChange={handleCategory}
                                    label="Category"
                                >
                                    {
                                        categories && categories.length > 0 ?
                                            categories.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.value}>{element.value}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No Category</MenuItem>

                                    }

                                </Select>

                            </FormControl>
                                


                                </Grid>
                                <Grid item md={3}>
                                    {
                                        category === "Charts" ?
                                        
                                        
                            <FormControl style={{marginLeft:"-200px", width:"80%"}}  variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Select</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={select}
                                onChange={(e)=>setSelect(e.target.value)}
                                label="Category"
                            >
                                <MenuItem value="manual">Manual</MenuItem>
                                <MenuItem value="automatic">Automatic</MenuItem>
                                           

                            </Select>

                        </FormControl>

                                    :null}

                                </Grid>
                                <Grid item md={3}>
                                    {
                                        select === "automatic" && category==="Charts"?
                                        <TextField style={{width:"80%",marginLeft:"-200px"}} variant="outlined" className={classes.inputField} width="25" required label="Name" onChange={(e)=>setName2(e.target.value)}>
                                        </TextField>
                                        :null

                                    }
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl} >
                                <FormLabel className={classes.lables} component="div" >Public</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={publicStatus} onChange={handlePublic} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Published</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={publishedStatus} onChange={handlePublished} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Live Date time" className={classes.inputField} value={liveDate} onChange={e => setLiveDate(e)} ></DateTimePicker>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Expiry Date time" className={classes.inputField} value={expiryDate} onChange={e => setExpiryDate(e)} ></DateTimePicker>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Stencil
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setStencil([...stencil,...e.target.files])}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Background
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setBackground([...background,...e.target.files])}
                                />
                            </Button>
                            {background.length > 0 ?
                                (background.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                : null}
                        </Grid>


                        <Grid item md={12} className="mt-4">
                            <Button className="mr-2"  variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" >Clear</Button>

                        </Grid>

                    </Grid>

                </div>

            </Paper>
        </Fragment>
    )
}
