import { Box, FormControl, Grid, InputLabel, Paper, Select, TextField, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Chip, Checkbox, Input, ListItemText } from '@material-ui/core'
import React, { useState,useEffect } from 'react';
import { useStyles } from '../../styles/style'
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios'
import cogoToast from 'cogo-toast';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddIcon from '@material-ui/icons/Add';
import MultiLanguageModal from '../Reusable/multiLanguageModal';

export default function EditBannerCollectionOfCollection(props) {
    const classes = useStyles();
    const [name, setName] = useState("");

    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);

    const [langName, setLangName] = useState({
        english: "",
        hindi: "",
        gujrati: "",
        marathi: "",
        tamil: "",
        telugu: "",
        punjabi: "",
        urdu: "",
        kashmiri: "",
        assamese: "",
        odia: "",
        rajastani: "",
        bengali: "",
        haryanvi: "",
        bhojpuri: "",
        malayalam: "",
        kannada: "",
        old_hindi: "",
    })
    const [langBucket, setLangBucket] = useState({
        englishBucket: "",
        hindiBucket: "",
        gujratiBucket: "",
        marathiBucket: "",
        tamilBucket: "",
        teluguBucket: "",
        punjabiBucket: "",
        urduBucket: "",
        kashmiriBucket: "",
        assameseBucket: "",
        odiaBucket: "",
        rajastaniBucket: "",
        bengaliBucket: "",
        haryanviBucket: "",
        bhojpuriBucket: "",
        malayalamBucket: "",
        kannadaBucket: "",
        old_hindiBucket: "",
    })
    const [lang, setLang] = useState({
        hi: "",
        en: "",
        gu: "",
        ta: "",
        te: "",
        pa: "",
        ur: "",
        ks: "",
        as: "",
        or: "",
        raj: "",
        bn: "",
        har: "",
        bho: "",
        ml: "",
        kn: "",
        old_hindi: "",
        mr: ""
    })

    const [audioVideo, setAudioVideo] = useState("")

    const handleInputChange = (e) => {
        setLangName({
            ...langName,
            [e.target.name]: e.target.value
        })
    }

    const fetchBannerCollectionbyId = () => {
        axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner/list/collection/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`).then(res=>{
            setName(res.data.data[0].name)

            let name = JSON.parse(res.data.data[0].name)
            setEnglishLang(name.en ? name.en : '')
            setHindiLang(name.hi ? name.hi  : '')
            setMarathiiLang(name.mr ? name.mr  : '')
            setGujaratiLang(name.gu ? name.gu  : '')
            setKannadaLang(name.kn ? name.kn  : '')
            setTeluguLang(name.te ? name.te  : '')
            setTamilLang(name.ta ? name.ta  : '')
            setPunjabiLang(name.pa ? name.pa  : '')
            setMalayalamLang(name.ml ? name.ml : '');
                setOdisaLang(name.or ? name.or : '');
                setUrduLang(name.ur ? name.ur :'' );
                setBengaliLang(name.bn ? name.bn :'' );

            let lang = {};
            for(let key in res.data.data[0].multiLanguage){
                lang[key] = res.data.data[0].multiLanguage[key]._id
            }
            setLang(lang)
            let langName = {
                hi: "hindi",
                en: "english",
                gu: "gujrati",
                ta: "tamil",
                te: "telugu",
                pa: "punjabi",
                ur: "urdu",
                ks: "kashmiri",
                as: "assamese",
                or: "odia",
                raj: "rajastani",
                bn: "bengali",
                har: "haryanvi",
                bho: "bhojpuri",
                ml: "malayalam",
                kn: "kannada",
                old_hindi: "old_hindi",
                mr: "marathi"
            }
            let langNameObj = {}
            let langBucketObj = {}
            for(let [key, value] of Object.entries(langName)){
                for(let [multupleKey, multipleValue] of Object.entries(res.data.data[0].multiLanguage)){
                    if(key === multupleKey){
                        langNameObj[value] = multipleValue._id;
                        langBucketObj[`${value}Bucket`] = multipleValue.name;
                    }
                }
            }

            setLangName(langNameObj)
            setLangBucket(langBucketObj)
            
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        fetchBannerCollectionbyId()
    }, [])

    const fetchBucketName = (id) => {
        const name = axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerList/${id}?storeId=${localStorage.getItem("storeId")}`).then((res) => res.data)
        return name
    }


    const handleLanguage = (language) => {
        if (language === "english") {
            fetchBucketName(langName.english).then(data => {
                
                let name = data.data.name
                setLang({
                    ...lang,
                    en: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    englishBucket: name
                })
            }
            ).catch(err => {
                console.log(err);
                cogoToast.error("wrong bucket Id")
            })
            // setEnglish("")
        } else if (language === "hindi") {
            fetchBucketName(langName.hindi).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    hi: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    hindiBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "gujrati") {
            fetchBucketName(langName.gujrati).then(data => {
                let name = data.data.name
                setLang({
                    ...lang,
                    gu: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    gujratiBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "tamil") {
            fetchBucketName(langName.tamil).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ta: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    tamilBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "telugu") {
            fetchBucketName(langName.telugu).then(data => {
                let name = data.data.name
                setLang({
                    ...lang,
                    te: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    teluguBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "punjabi") {
            fetchBucketName(langName.punjabi).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    pa: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    punjabiBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "urdu") {
            fetchBucketName(langName.urdu).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ur: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    urduBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "kashmiri") {
            fetchBucketName(langName.kashmiri).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ks: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    kashmiriBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "assamese") {
            fetchBucketName(langName.assamese).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    as: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    assameseBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "odia") {
            fetchBucketName(langName.odia).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    or: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    odiaBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "rajastani") {
            fetchBucketName(langName.rajastani).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    raj: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    rajastaniBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "bengali") {
            fetchBucketName(langName.bengali).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    bn: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    bengaliBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "haryanvi") {
            fetchBucketName(langName.haryanvi).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    har: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    haryanviBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "bhojpuri") {
            fetchBucketName(langName.bhojpuri).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    bho: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    bhojpuriBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "malayalam") {
            fetchBucketName(langName.malayalam).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ml: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    malayalamBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "kannada") {
            fetchBucketName(langName.kannada).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    kn: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    kannadaBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "old_hindi") {
            fetchBucketName(langName.old_hindi).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    old_hindi: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    old_hindiBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "marathi") {
            fetchBucketName(langName.marathi).then(data => {
                let name = data.data.name
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    mr: data.data._id
                })
                setLangBucket({
                    ...langBucket,
                    marathiBucket: name
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }



    }

    const clearLanguage = (e, language) => {

        if (language === "english") {
            setLangName({
                ...langName,
                english: ""
            })
            setLang({
                ...lang,
                en: ""
            })
            setLangBucket({
                ...langBucket,
                englishBucket: ""
            })

            // setEnglish("")
        } else if (language === "hindi") {
            setLangName({
                ...langName,
                hindi: ""
            })

            setLang({
                ...lang,
                hi: ""
            })
            setLangBucket({
                ...langBucket,
                hindiBucket: ""
            })

        }
        else if (language === "gujrati") {
            setLangName({
                ...langName,
                gujrati: ""
            })
            setLang({
                ...lang,
                gu: ""
            })
            setLangBucket({
                ...langBucket,
                gujratiBucket: ""
            })


        }
        else if (language === "tamil") {
            setLangName({
                ...langName,
                tamil: ""
            })
            setLang({
                ...lang,
                ta: ""
            })
            setLangBucket({
                ...langBucket,
                tamilBucket: ""
            })

        }
        else if (language === "telugu") {
            setLangName({
                ...langName,
                telugu: ""
            })

            setLang({
                ...lang,
                te: ""
            })
            setLangBucket({
                ...langBucket,
                teluguBucket: ""
            })

        }
        else if (language === "punjabi") {
            setLangName({
                ...langName,
                punjabi: ""
            })
            setLang({
                ...lang,
                pa: ""
            })
            setLangBucket({
                ...langBucket,
                punjabiBucket: ""
            })
        }
        else if (language === "urdu") {
            setLangName({
                ...langName,
                urdu: ""
            })
            setLang({
                ...lang,
                ur: ""
            })
            setLangBucket({
                ...langBucket,
                urduBucket: ""
            })
        }
        else if (language === "kashmiri") {
            setLangName({
                ...langName,
                kashmiri: ""
            })
            setLang({
                ...lang,
                ks: ""
            })
            setLangBucket({
                ...langBucket,
                kashmiriBucket: ""
            })
        }
        else if (language === "assamese") {
            setLangName({
                ...langName,
                assamese: ""
            })
            setLang({
                ...lang,
                as: ""
            })
            setLangBucket({
                ...langBucket,
                assameseBucket: ""
            })
        }
        else if (language === "odia") {
            setLangName({
                ...langName,
                odia: ""
            })
            setLang({
                ...lang,
                or: ""
            })
            setLangBucket({
                ...langBucket,
                odiaBucket: ""
            })
        }
        else if (language === "rajastani") {
            setLangName({
                ...langName,
                rajastani: ""
            })
            setLang({
                ...lang,
                raj: ""
            })
            setLangBucket({
                ...langBucket,
                rajastaniBucket: ""
            })
        }
        else if (language === "bengali") {
            setLangName({
                ...langName,
                bengali: ""
            })
            setLang({
                ...lang,
                bn: ""
            })
            setLangBucket({
                ...langBucket,
                bengaliBucket: ""
            })
        }
        else if (language === "haryanvi") {
            setLangName({
                ...langName,
                haryanvi: ""
            })
            setLang({
                ...lang,
                har: ""
            })
            setLangBucket({
                ...langBucket,
                haryanviBucket: ""
            })
        }
        else if (language === "bhojpuri") {
            setLangName({
                ...langName,
                bhojpuri: ""
            })
            setLang({
                ...lang,
                bho: ""
            })
            setLangBucket({
                ...langBucket,
                bhojpuriBucket: ""
            })
        }
        else if (language === "malayalam") {
            setLangName({
                ...langName,
                malayalam: ""
            })
            setLang({
                ...lang,
                ml: ""
            })
            setLangBucket({
                ...langBucket,
                malayalamBucket: ""
            })
        }
        else if (language === "kannada") {
            setLangName({
                ...langName,
                kannada: ""
            })
            setLang({
                ...lang,
                kn: ""
            })
            setLangBucket({
                ...langBucket,
                kannadaBucket: ""
            })


        }
        else if (language === "old_hindi") {
            setLangName({
                ...langName,
                old_hindi: ""
            })
            setLang({
                ...lang,
                old_hindi: ""
            })
            setLangBucket({
                ...langBucket,
                old_hindiBucket: ""
            })
        }
        else if (language === "marathi") {
            setLangName({
                ...langName,
                marathi: ""
            })
            setLang({
                ...lang,
                mr: ""
            })
            setLangBucket({
                ...langBucket,
                marathiBucket: ""
            })
        }



    }
    const postBucketGroup = (data) => {

        axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner/list/collection/${props.match.params.id}`, data
        ).then(res => {
            props.history("/listBannerCollectionOfCollection")
            cogoToast.success(res.data.message)
        }).catch(err => {
            cogoToast(err.response)
        })
    }

    const onClear = () => {

        setName("")
        setLang({
            hi: "",
            en: "",
            gu: "",
            ta: "",
            te: "",
            pa: "",
            ur: "",
            ks: "",
            as: "",
            or: "",
            raj: "",
            bn: "",
            har: "",
            bho: "",
            ml: "",
            kn: "",
            old_hindi: "",
            mr: ""
        }
        )
        setLangBucket({
            englishBucket: "",
            hindiBucket: "",
            gujratiBucket: "",
            marathiBucket: "",
            tamilBucket: "",
            teluguBucket: "",
            punjabiBucket: "",
            urduBucket: "",
            kashmiriBucket: "",
            assameseBucket: "",
            odiaBucket: "",
            rajastaniBucket: "",
            bengaliBucket: "",
            haryanviBucket: "",
            bhojpuriBucket: "",
            malayalamBucket: "",
            kannadaBucket: "",
            old_hindiBucket: "",
            defaultBucket: ""
        })
        setLangName({
            english: "",
            hindi: "",
            gujrati: "",
            marathi: "",
            tamil: "",
            telugu: "",
            punjabi: "",
            urdu: "",
            kashmiri: "",
            assamese: "",
            odia: "",
            rajastani: "",
            bengali: "",
            haryanvi: "",
            bhojpuri: "",
            malayalam: "",
            kannada: "",
            old_hindi: "",
            default: "",
        })
    }

    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }
    const onSubmit = () => {
        let obj = {
            name: JSON.stringify({
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            }),
            multiLanguage: JSON.stringify(lang),
            storeId:localStorage.getItem("storeId")
        }

        postBucketGroup(obj)
    }
    return (
        <div>
            <Paper component={Box} p={4}>
                <h1 className="text-center">Add Banner Collection Of Collections</h1>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField variant="outlined" className={classes.inputField} width="25" required label="Name" name="name" value={englishLang} onChange={e => setEnglishLang(e.target.value)} ></TextField>
                        <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                    </Grid>
                </Grid>
                <h3 className="mt-5">Banner Collection</h3>

                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="English" name="english" value={langName.english} onChange={handleInputChange} />

                            {langBucket.englishBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.englishBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }


                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("english")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "english")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Hindi" name="hindi" value={langName.hindi} onChange={handleInputChange} />
                            {langBucket.hindiBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.hindiBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("hindi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "hindi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Marathi" name="marathi" value={langName.marathi} onChange={handleInputChange} />
                            {langBucket.marathiBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.marathiBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("marathi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "marathi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Gujrati" name="gujrati" value={langName.gujrati} onChange={handleInputChange} />
                            {langBucket.gujratiBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.gujratiBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("gujrati")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "gujrati")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Tamil" name="tamil" value={langName.tamil} onChange={handleInputChange} />
                            {langBucket.tamilBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.tamilBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("tamil")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "tamil")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Telugu" name="telugu" value={langName.telugu} onChange={handleInputChange} />
                            {langBucket.teluguBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.teluguBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("telugu")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "telugu")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Punjabi" name="punjabi" value={langName.punjabi} onChange={handleInputChange} />
                            {langBucket.punjabiBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.punjabiBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("punjabi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "punjabi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Urdu" name="urdu" value={langName.urdu} onChange={handleInputChange} />
                            {langBucket.urduBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.urduBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("urdu")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "urdu")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Kashmiri" name="kashmiri" value={langName.kashmiri} onChange={handleInputChange} />
                            {langBucket.kashmiriBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.kashmiriBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("kashmiri")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "kashmiri")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Assamese" name="assamese" value={langName.assamese} onChange={handleInputChange} />
                            {langBucket.assameseBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.assameseBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("assamese")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "assamese")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Odia" name="odia" value={langName.odia} onChange={handleInputChange} />
                            {langBucket.odiaBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.odiaBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("odia")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "odia")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Rajastani" name="rajastani" value={langName.rajastani} onChange={handleInputChange} />
                            {langBucket.rajastaniBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.rajastaniBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("rajastani")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "rajastani")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Bengali" name="bengali" value={langName.bengali} onChange={handleInputChange} />
                            {langBucket.bengaliBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.bengaliBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("bengali")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "bengali")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Haryanvi" name="haryanvi" value={langName.haryanvi} onChange={handleInputChange} />
                            {langBucket.haryanviBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.haryanviBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("haryanvi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "haryanvi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Bhojpuri" name="bhojpuri" value={langName.bhojpuri} onChange={handleInputChange} />
                            {langBucket.bhojpuriBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.bhojpuriBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("bhojpuri")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "bhojpuri")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Malayalam" name="malayalam" value={langName.malayalam} onChange={handleInputChange} />
                            {langBucket.malayalamBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.malayalamBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("malayalam")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "malayalam")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Kannada" name="kannada" value={langName.kannada} onChange={handleInputChange} />
                            {langBucket.kannadaBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.kannadaBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("kannada")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "kannada")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Old Hindi" name="old_hindi" value={langName.old_hindi} onChange={handleInputChange} />
                            {langBucket.old_hindiBucket.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.old_hindiBucket}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("old_hindi")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "old_hindi")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>

                <Button className="m-3" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                <Button className="m-3" variant="contained" color="secondary" onClick={onClear}>Clear</Button>
            </Paper>
            <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
        </div>
    )
}
