import React, { Fragment, useState,useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    MenuItem

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import {content_type} from './../bucket/objects'



export default function CreateVarient(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [v0, setv0] = useState([]);
    const [v1, setv1] = useState([]);
    const [v2, setv2] = useState([]);
    const [v3, setv3] = useState([]);
    const [v4, setv4] = useState([]);
    const [v5, setv5] = useState([]);
    const [v6, setv6] = useState([]);
    const [v7, setv7] = useState([]);
    const [v8, setv8] = useState([]);
    const [v9, setv9] = useState([]);
    const [v10, setv10] = useState([]);
    const [contents, setContents] = useState("");
    const [contentId, setContentId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [lang, setLang] = useState("Default")
    const listVarient = ["v0","v1", "v2", "v3", "v4", "v5", "v6"];
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])

    const initialvalues = {
        contentId: "",
    }
    const [state, setState] = useState(initialvalues)

    const handlev0 = (e) => {
        setv0([...v0, ...e.target.files])
    }
    const handlev1 = (e) => {
        setv1([...v1, ...e.target.files])
    }
    const handlev2 = (e) => {
        setv2([...v2, ...e.target.files])
    }
    const handlev3 = (e) => {
        setv3([...v3, ...e.target.files])
    }
    const handlev4 = (e) => {
        setv4([...v4, ...e.target.files])
    }
    const handlev5 = (e) => {
        setv5([...v5, ...e.target.files])
    }
    const handlev6 = (e) => {
        setv6([...v6, ...e.target.files])
    }
    const handlev7 = (e) => {
        setv7([...v7, ...e.target.files])
    }
    const handlev8 = (e) => {
        setv8([...v8, ...e.target.files])
    }
    const handlev9 = (e) => {
        setv9([...v9, ...e.target.files])
    }
    const handlev10 = (e) => {
        setv10([...v10, ...e.target.files])
    }
   

    useEffect(() => {
        fetchLanguage();
    }, [])

    const onHandleChange = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setContentId(e.target.value)
        setState({
            ...state,
            [e.target.name]: value
        })

    }
    const onEnterContentId = (e) => {
        if (contentId !== "" && e.key === "Enter" || e.key === "Tab") {
            axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/solr/${contentId}?storeId=${localStorage.getItem("storeId")}`).then(res => {
                console.log(res.data)
                setDisplayName(res.data.data.response.docs[0].name)
            }).catch(err => {
                console.log(err)
                cogoToast.error("Wrong ContentId")
            })
        }


    }
    const postVariant = async (data) => {
        const fd = new FormData();
        fd.append("language", data.language);
        fd.append("type", data.type);
        fd.append("contentId", data.contentId);
        fd.append("name", data.name);
        fd.append("contentType", data.content_type);
        fd.append("contentName", data.displayName);
        fd.append("storeId", data.storeId);
        for (let index = 0; index < data.v0.length; index++) {
            const element = data.v0[index];
            fd.append('v0', element)
        }
        for (let index = 0; index < data.v1.length; index++) {
            const element = data.v1[index];
            fd.append('v1', element)
        }
        for (let index = 0; index < data.v2.length; index++) {
            const element = data.v2[index];
            fd.append('v2', element)
        }
        for (let index = 0; index < data.v3.length; index++) {
            const element = data.v3[index];
            fd.append('v3', element)
        }
        for (let index = 0; index < data.v4.length; index++) {
            const element = data.v4[index];
            fd.append('v4', element)
        }
        for (let index = 0; index < data.v5.length; index++) {
            const element = data.v5[index];
            fd.append('v5', element)
        }
        for (let index = 0; index < data.v6.length; index++) {
            const element = data.v6[index];
            fd.append('v6', element)
        }
        for (let index = 0; index < data.v7.length; index++) {
            const element = data.v7[index];
            fd.append('v7', element)
        }
        for (let index = 0; index < data.v8.length; index++) {
            const element = data.v8[index];
            fd.append('v8', element)
        }
        for (let index = 0; index < data.v9.length; index++) {
            const element = data.v9[index];
            fd.append('v9', element)
        }
        for (let index = 0; index < data.v10.length; index++) {
            const element = data.v10[index];
            fd.append('v10', element)
        }
        
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                if(res.data.result.message == "Content Id Exists"){
                    cogoToast.error(res.data.result.message);
                   }
                   else{
                    cogoToast.success(res.data.result.message);
                   }
            }).catch(err => {
                cogoToast.error(err.response.data.message)
            })
    }


    const onSubmit = () => {
        const obj = {
            "type": "variant",
            "v0": v0,
            "v1": v1,
            "v2": v2,
            "v3": v3,
            "v4": v4,
            "v5": v5,
            "v6": v6,
            "v7": v7,
            "v8": v8,
            "v9": v9,
            "v10": v10,
            "language": lang,
            name: name,
            contentId: state.contentId,
            content_type:contents,
            displayName:displayName,
            "storeId":localStorage.getItem("storeId")   
        }
        postVariant(obj)
        console.log(obj)
        setState(initialvalues)
        setv0([])
        setv1([])
        setv2([])
        setv3([])
        setv4([])
        setv5([])
        setv6([])
        setv7([])
        setv8([])
        setv9([])
        setv10([])
        setDisplayName("")
        setContentId("")
    }

    const onClear = () => {
        setState(initialvalues)
        setv0([])
        setv1([])
        setv2([])
        setv3([])
        setv4([])
        setv5([])
        setv6([])
        setv7([])
        setv8([])
        setv9([])
        setv10([])
    }
    const deletev0 = (e, i) => {
        let newArray = v0.filter((ele, index) => {
            return index !== i;
        })

        setv0(newArray)
    }

    const deletev1 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv1(newArray)
    }
    const deletev2 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv2(newArray)
    }
    const deletev3 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv3(newArray)
    }
    const deletev4 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv4(newArray)
    }
    const deletev5 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv5(newArray)
    }
    const deletev6 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv6(newArray)
    }
    const deletev7 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv7(newArray)
    }
    const deletev8 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv8(newArray)
    }
    const deletev9 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv9(newArray)
    }
    const deletev10 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv10(newArray)
    }
    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setLangList([...langList, ...res.data.result.result])
            console.log('lang',res.data.result.result);
        }).catch(
            err => {
                console.log(err)
            }
        )
    }


    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add Variant</h1>
                    <Grid container >
                    <Grid item md={8} sm={12}>
                <FormControl style={{minWidth:"45%",marginLeft:"-150px"}} variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        label="Language"
                    >
                        {
                            langList.length > 0 ?
                            langList.map((element, id) => {

                                    return (
                                        <MenuItem key={id} value={element.name}>{element.name}</MenuItem>
                                    )
                                }) :
                                <MenuItem disabled value="no Lamguage">No Lamguage</MenuItem>

                        }

                    </Select>

                </FormControl>

            </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Content Id" name="contentId" value={state.contentId} onChange={onHandleChange} onKeyDown={onEnterContentId}></TextField>
                        </Grid>
                        <Grid item md={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" disabled required label="Content Name" value={displayName} >
                            </TextField>
                        </Grid>
                        {/* <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Variant Name</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    label="Varient Name"
                                >
                                    {
                                        listVarient.map((element, id) => {

                                            return (
                                                <MenuItem key={id} value={element}>{element}</MenuItem>
                                            )
                                        })

                                    }

                                </Select>

                            </FormControl>


                        </Grid> */}
                        <Grid item md={6}>
                        <FormControl variant="outlined" className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Content Type</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contents}
                                onChange={(e) => setContents(e.target.value)}
                                label="Content type"
                            >
                                {
                                    content_type && content_type.length > 0 ?
                                        content_type.map((element, id) => {

                                            return (
                                                <MenuItem key={id} value={element.typeid}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Content Type</MenuItem>

                                }

                            </Select>

                        </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                v0 Thumbnail
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev0}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v0.length > 0 ?
                                (v0.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev0(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v1
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev1}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v1.length > 0 ?
                                (v1.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev1(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v2
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev2}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v2.length > 0 ?
                                (v2.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev2(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v3
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev3}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v3.length > 0 ?
                                (v3.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev3(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v4
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev4}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v4.length > 0 ?
                                (v4.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev4(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v5
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev5}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v5.length > 0 ?
                                (v5.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev5(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v6
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev6}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v6.length > 0 ?
                                (v6.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev6(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v7
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev7}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v7.length > 0 ?
                                (v7.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev7(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v8
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev8}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v8.length > 0 ?
                                (v8.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev8(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v9
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev9}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v9.length > 0 ?
                                (v9.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev9(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v10
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev10}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v10.length > 0 ?
                                (v10.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev10(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
