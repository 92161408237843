
import React, { Fragment, useState, useEffect } from "react";

import {
    TablePagination,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    Paper,
    Box,
    CircularProgress,
    TextField,
    Toolbar,
    InputBase,
    Tooltip
} from "@material-ui/core";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { alpha, makeStyles } from '@material-ui/core/styles';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import SearchIcon from '@material-ui/icons/Search';
import DeleteModal from "../Reusable/deleteModal";
import { connectAdvanced } from "react-redux";




export default function ListBannerCollection(props) {

    const classes = useStyles();
    // const inClasses = cssStyles()
    const [pagination, setPagination] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [searched, setSearched] = useState("");
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    
    const toggle = () => setDeleteModal(!deleteModal);
    const deleteEvent = async (id) => {
        await axios.delete(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerList/${deleteId}`).then(
            res => {
                let newData = data.filter(ele => {
                    return ele._id !== deleteId;
                });
                setData(newData);
                setDeleteId("")
            }
        ).catch(err => {
            console.log(err);
        })
    }


    const fetchBanner = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerList?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                     // let object = res.data.result.filter(ele => {
                    //     ele.name = JSON.parse(ele.name)
                    //     return ele
                    // })
                    // console.log('obj->',object)
                    setData(res.data.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        fetchBanner()
    }, [])

    const searchBanner = async (data) => {
        //${process.env.REACT_APP_CMS_PLAYLIST_IP}
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerlist/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    
                    setData(res.data.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        if (searched === "") {
            fetchBanner()
        }
    },[searched])

    const searchContent = (e) => {
        if(e.key === "Enter"){
            searchBanner(searched)
        }
    }
    const allRegenerate = () =>{
        let data = {
            storeId : localStorage.getItem("storeId")
        }
         axios.put(`${process.env.REACT_APP_BANNER_IP}/v1/redis/purge`, data)
         .then(res => {
           cogoToast.success(res.data.message)
         }).catch(err => {
           cogoToast.error(err.response.message)
         })
      }

    const handleChangePage = (event, newPage) => {
        setPagination(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPagination(0);
    };
    return (
        <Fragment>
            <Paper component={Box} p={4}>
           
                <Toolbar>
                    <h4>List Banners Collection</h4>
                    
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={searched}
                            onChange={(e) => setSearched(e.target.value)}
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={(e) => searchContent(e)}
                        />
                    </div>
                    <Tooltip title="Refresh All"> 
                    <Button className="m-1" variant="contained" color="primary" onClick={()=>allRegenerate()}><AutorenewIcon style={{cursor:"pointer"}} /></Button>
                    </Tooltip>
                </Toolbar>
               

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading == true ? <CircularProgress /> :
                                data && data.length > 0 ? data
                                    .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                                    .map((emp, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{emp._id}</TableCell>
                                            <TableCell>{emp.name}</TableCell>
                                            <TableCell>
                                                <Link to={`/editBannerCollection/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                                                <DeleteForeverOutlinedIcon
                                                    className={classes.btndelete}
                                                    onClick={() => { setDeleteId(emp._id); toggle() }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )) : <p className="mt-2 text-center">no data</p>}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 50, 100]}
                        component="div"
                        count={data ? data.length : null}
                        rowsPerPage={rowsPerPage}
                        page={pagination}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
                <DeleteModal
                    toggle={toggle}
                    deleteModal={deleteModal}
                    deleteContent={deleteEvent}
                />
            </Paper>
        </Fragment>
    );

}
