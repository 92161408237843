
import React, { Fragment, useState, useEffect } from "react";

import {
    TablePagination,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    Paper,
    Box,
    CircularProgress,
    makeStyles,
    AppBar,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import axios from 'axios';
import DeleteModal from './../Reusable/deleteModal'
import PropTypes from 'prop-types';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function ListTracker(props) {

    const classes = useStyles();
    const classes1 = useStyle();
    const [pagination, setPagination] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setData([])
        setValue(newValue);
        setIsLoading(true)
        if (newValue === 0) {
            fetchDataChangeRequest()
        }
        else if (newValue === 1) {
            fetchDataCloseRequest()
        }
    };




      const fetchDataChangeRequest = () => {
        axios.get(`${process.env.REACT_APP_BRIDGE_IP}/v1/change_request`)
          .then(res => {
            setData(res.data.result)
            setIsLoading(false);
          }).catch(err => {
            console.log(err)
            setIsLoading(false);
          })
      }

      const fetchDataCloseRequest = () => {
        axios.get(`${process.env.REACT_APP_BRIDGE_IP}/v1/change_request/close`)
          .then(res => {
              setData(res.data.result)
            setIsLoading(false);
          }).catch(err => {
            console.log(err)
            setIsLoading(false);
          })
      }

      useEffect( () => {
        fetchDataChangeRequest();
      }, [])

    const handleChangePage = (event, newPage) => {
        setPagination(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPagination(0);
    };

    const table = (data) => {
        return (<TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Content Id</TableCell>
                        <TableCell>Content Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading === true ? <CircularProgress /> :
                        data && data.length > 0 ? data
                            .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                            .map((emp, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{emp.contentId}</TableCell>
                                        <TableCell>{emp.contentType}</TableCell>
                                        <TableCell>{emp.date}</TableCell>
                                        <TableCell>{emp.status}</TableCell>
                                        {/* <TableCell>
                                            <Link to={`/editapp/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                                            <DeleteForeverOutlinedIcon
                                                className={classes.btndelete}
                                                onClick={() => {
                                                    setDeleteId(emp._id);
                                                    //  toggle() 
                                                }}
                                            />
                                        </TableCell> */}
                                    </TableRow>
                                )
                            }) : <p className="mt-2 text-center">no data</p>}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 50, 100]}
                component="div"
                count={data?data.length:null}
                rowsPerPage={rowsPerPage}
                page={pagination}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableContainer>

        )
    }
    return (
        <Fragment>
            <Paper component={Box} p={4}>
            <h1 className="mb-5">List Tracker</h1>
            <div className={classes1.root}>
                <AppBar position="static" color="default" style={{width:350}}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary"
                        textColor="primary">
                        <Tab label="Recent" {...a11yProps(0)} />
                        <Tab label="Closed" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                    {table(data)}
                </TabPanel>
                <TabPanel value={value} index={1}>
                {table(data)}
                </TabPanel>
            </div>
            </Paper>
        </Fragment>
    );

}

