import {combineReducers} from 'redux';
import AppReducer from "./../Redux/Reducer/appReducer";
import PageReducer from "./../Redux/Reducer/pageReducer";
import NavReducer from "./../Redux/Reducer/NavReducer";



export default combineReducers(
    {
       app: AppReducer,
       page: PageReducer,
       nav:NavReducer
    }
)