
import React, { Fragment, useState, useEffect } from "react";

import {
  TablePagination,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  Box,
  Toolbar,
  InputBase,
  Tooltip
} from "@material-ui/core";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useStyles } from "./../../styles/style";
import axios from 'axios';
import { Link ,useHistory} from 'react-router-dom';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import DeleteModal from './../Reusable/deleteModal';
import CopyModal from './../Reusable/copyModal';
import LinkIcon from '@material-ui/icons/Link';
import './../bucket/css/bucket.css'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import cogoToast from "cogo-toast";
import SearchIcon from '@material-ui/icons/Search';


export default function ListPage(props) {
  const classes = useStyles();
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  // const headers = data?Object.keys(data[0]):null;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false)
  const [copyModal, setCopyModal] = useState(false)
  const [deleteId, setDeleteId] = useState("");
  const [copyId, setCopyId] = useState("");
  const [searched, setSearched] = useState("");
  const [storeId,setStoreId] = useState("")
  const toggle = () => setDeleteModal(!deleteModal);
  const toggle1 = () => setCopyModal(!copyModal);
  const navigate = useHistory(); 
  const deletePage = async (id) => {
    await axios.delete(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${deleteId}`).then(
      res=>{
        console.log(res);
        let newData = data.filter(ele=>{
          return ele._id !== deleteId;
        });
        setData(newData);
        setDeleteId("")
        // window.location.reload(false);
      }
    ).catch(err=>{
      console.log(err);
    })
  }

  const copyPage = async (id) => {
    console.log('id',id);
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/page/${copyId}/clone?storeId=${id}`).then(
      res=>{
        console.log(res);
        if(res.data.result.message == "success"){
        setCopyId("")
        fetchData()
        cogoToast.success(res.data.result.message);
        }
        else{
          cogoToast.error("Failed");
        }
        // window.location.reload(false);
      }
    ).catch(err=>{
      console.log(err);
    })
  }
  const fetchData = async () => {
    let data = localStorage.getItem("storeId");

    if(data){
      if(localStorage.getItem("userRole") == "SuperAdmin"){
     setStoreId(localStorage.getItem("storeId"));
     await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/game/getPage?storeId=${data}`)
     .then(res => {
       console.log(res)
       setData(res.data.result.result)
       setIsLoading(false);
     }).catch(err => {
       console.log(err)
     })
    }
    else{
      setStoreId(localStorage.getItem("storeId"));
      await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/page?storeId=${data}`)
      .then(res => {
        console.log(res)
        setData(res.data.result.result)
        setIsLoading(false);
      }).catch(err => {
        console.log(err)
      })
    }
    }
    else{
     navigate.push('/login');
    }

  }

  const postRegenerate = async (data) => {
    await axios.post(`${process.env.REACT_APP_BRIDGE_IP}/v1/change_request`, data)
      .then(res => {
        cogoToast.success(res.data.message)
        
      }).catch(err => {
        cogoToast.error(err.response.message)
      })
  }

  const allRegenerate = () =>{
    if(data.length > 0){
    for(let i in data){
      onRegenerate(data[i]._id)
    }
  }
  else{
    cogoToast.error("no pages found")
  }
  }
  const onRegenerate = (id)=>{
    let obj = {
        "contentId":id,
        "contentType":"page",
        "date":new Date()
    }
    postRegenerate(obj)
  }

  const searchTopNav = async (data) => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/game/search/${data}?storeId=${localStorage.getItem("storeId")}`)
        .then(res => {
            let object  = res.data.result.result.filter(ele => {
                return !ele.parentId
            })
            setData(object)
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })
}

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (searched === "") {
      fetchData()
    }
}, [searched])

  const searchContent = (e) => {
    if(e.key === "Enter"){
        searchTopNav(searched)
    }
    
}

  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(0);
  };
  return (
    <Fragment>
      <Paper component={Box} p={4}>
      <Toolbar>
                    <h1>List Page</h1>
                    
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search by code & Id"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={searched}
                            onChange={(e) => setSearched(e.target.value)}
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={(e) => searchContent(e)}
                        />
                    </div>
                    {/* <Tooltip title="Refresh All"> 
                    <Button className="m-4" variant="contained" color="primary" onClick={()=>allRegenerate()}><AutorenewIcon style={{cursor:"pointer"}} /></Button>
                    </Tooltip> */}
                </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Title</TableCell>
              <TableCell align="center">Enabled</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? data
              .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
              .map((emp, index) => (
                <TableRow key={index}>
                  <TableCell>{emp._id}</TableCell>
                  <TableCell>
                 {emp.pageCode?emp.pageCode:""}
                  </TableCell>
                  <TableCell>{emp.title.en?emp.title.en:""}</TableCell>
                  <TableCell align="center">{emp.enabled === true ? <span className="greendot"></span> : <span className="reddot"></span>}</TableCell>
                  <TableCell>
                  <Tooltip title="Edit"> 
                  <Link to={`/editPage/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                   </Tooltip>
                  <Tooltip title="Delete"> 
                  <DeleteForeverOutlinedIcon
                      className={classes.btndelete}
                      onClick={()=>{setDeleteId(emp._id);toggle()}}
                    />
                    </Tooltip>

                    <Tooltip title="Link"> 
                    <Link to={`/linkpage/${emp._id}`}><LinkIcon className={classes.bucketIcon} /></Link>
                    </Tooltip>
                    {
                      localStorage.getItem("userRole") != "SENIOR" ?
                    <Tooltip title="Refresh"> 
                      <AutorenewIcon style={{cursor:"pointer"}} onClick={()=>onRegenerate(emp._id)}/>
                    </Tooltip>:""
                  }
                    &nbsp;
                    <Tooltip title="Copy Page"> 
                  <FileCopyIcon style={{ color: "green" }} className={classes.btncopy} 
                  onClick={()=>{setCopyId(emp._id);toggle1()}}
                  />
                   </Tooltip>
                  </TableCell>
                </TableRow>
              )) : <TableRow ><p className="ml-5 text-center">no data</p></TableRow>}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 50,100]}
          component="div"
          count={data ? data.length : null}
          rowsPerPage={rowsPerPage}
          page={pagination}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <DeleteModal
          toggle={toggle}
          deleteModal={deleteModal}
          deleteContent={deletePage}
        />
        <CopyModal
          toggle1={toggle1}
          copyModal={copyModal}
          copyContent={copyPage}
        />
      </Paper>
    </Fragment>
  );

}
