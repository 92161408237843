import React, { Fragment, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    CircularProgress,
    makeStyles,
    Backdrop
} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import MultiLanguageModal from '../Reusable/multiLanguageModal';


const useStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 3,
        color: '#fff',
    },
}));


export default function CreateCategory(props) {
    const classes = useStyles();
    const backDropClass = useStyle();
    const [name, setName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [thumbnail, setThumbnail] = useState([]);
    const [detailPageImage, setDetailPageImage] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);

    const listVarient = ["v1", "v2", "v3", "v4", "v5", "v6"];


    const thumbnaillHandle = (e) => {
        setThumbnail([...thumbnail, ...e.target.files])
        // thumbnail.push(e.target.files)
    }
    const detailpagellHandle = (e) => {
        setDetailPageImage([...detailPageImage, ...e.target.files])
    }

    const postGenre = async (data) => {
        const fd = new FormData();
        fd.append("name", data.name);
        fd.append("displayName", JSON.stringify(data.displayName));
        fd.append("parentId", data.parentId);
        fd.append("storeId",data.storeId);
        for (let index = 0; index < data.image.length; index++) {
            const element = data.image[index];
            fd.append('image', element)
        }
        for (let index = 0; index < data.detailImage.length; index++) {
            const element = data.detailImage[index];
            fd.append('detailImage', element)
        }
        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category`, fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                setThumbnail("")
                setDetailPageImage("")
                setName("");
                setDisplayName("");
                setIsLoading(false)
                cogoToast.success("success");
            }).catch(err => {
                cogoToast.error(err.response.data.message);
                setIsLoading(false)
            })
    }

    const onSubmit = () => {
        const obj = {
            "name": name,
            "displayName":  {
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            },
            "image": thumbnail,
            "detailImage": detailPageImage,
            "parentId": -1,
            "storeId":localStorage.getItem("storeId")

        }
        postGenre(obj)
        setIsLoading(true)
        // setThumbnail("")
        // setDetailPageImage("")
        // setName("");
        // setDisplayName("");
    }

    const onClear = () => {
        setThumbnail([])
        setDetailPageImage([])
    }

    const deletethumbnail = (e, i) => {
        let newArray = thumbnail.filter((item, index) => index !== i);


        setThumbnail(newArray)
    }

    const deletedetailpageImage = (e, i) => {
        let newArray = detailPageImage.filter((ele, index) => {
            return index !== i;
        })

        setDetailPageImage(newArray)
    }

    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add Categories</h1>
                    <Backdrop className={backDropClass.backdrop} open={isLoading} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Name" name="Name" value={name} onChange={(e) => { setName(e.target.value) }} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Display Name" name="DisplayName" value={englishLang} onChange={(e) => { setEnglishLang(e.target.value) }} ></TextField>
                            <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Thumbnail
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={thumbnaillHandle}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {thumbnail.length > 0 ?
                                (thumbnail.map((ele, i) => (<div><p className="mt-2 ml-4" style={{ cursor: "pointer", display: "inline" }} onClick={() => window.open(URL.createObjectURL(ele), "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deletethumbnail(e, i, ele)} /></div>)))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Detail Page Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={detailpagellHandle}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {detailPageImage.length > 0 ?
                                (detailPageImage.map((ele, i) => (<div><p className="mt-2 ml-4" style={{ cursor: "pointer", display: "inline" }} onClick={() => window.open(URL.createObjectURL(ele), "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deletedetailpageImage(e, i, ele)} /></div>)))
                                : null}
                        </Grid>

                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                </div>
            </Paper>


        </Fragment>
    )
}
