import { Box, FormControl, Grid, InputLabel, Paper, Select, TextField, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Chip, Input, Checkbox, ListItemText,Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../styles/style'
import { content_type } from './objects';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios'
import cogoToast from 'cogo-toast';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddIcon from '@material-ui/icons/Add';
import { name as radio } from './objects'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import MultiLanguageModal from '../Reusable/multiLanguageModal';
import Switch from "react-switch";
import InfoIcon from "@material-ui/icons/InfoRounded"

export default function EditBucketGroup(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [contents, setContents] = useState("");
    const [row, setRow] = useState("");
    const [size, setSize] = useState("");
    const [bucketStyle, setBucketStyle] = useState(0);
    const [enable, setEnable] = useState("true");
    const [more, setMore] = useState("true");
    const [media, setMedia] = useState("");
    const [tag, setTag] = useState([]);
    const [languageSpec, setLanguageSpec] = useState("Yes");
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");
    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [langName, setLangName] = useState({
        default:"",
        english: "",
        hindi: "",
        gujrati: "",
        marathi: "",
        tamil: "",
        telugu: "",
        punjabi: "",
        urdu: "",
        kashmiri: "",
        assamese: "",
        odia: "",
        rajastani: "",
        bengali: "",
        haryanvi: "",
        bhojpuri: "",
        malayalam: "",
        kannada: "",
        old_hindi: "",
        sinhala :"",
        arabic :"",
        afgani:"",
        burmese:"",
        khmer:""
    })
    const [langBucket, setLangBucket] = useState({
        defaultBucket: { name: "", language: "" },
        englishBucket: { name: "", language: "" },
        hindiBucket: { name: "", language: "" },
        gujratiBucket: { name: "", language: "" },
        marathiBucket: { name: "", language: "" },
        tamilBucket: { name: "", language: "" },
        teluguBucket: { name: "", language: "" },
        punjabiBucket: { name: "", language: "" },
        urduBucket: { name: "", language: "" },
        kashmiriBucket: { name: "", language: "" },
        assameseBucket: { name: "", language: "" },
        odiaBucket: { name: "", language: "" },
        rajastaniBucket: { name: "", language: "" },
        bengaliBucket: { name: "", language: "" },
        haryanviBucket: { name: "", language: "" },
        bhojpuriBucket: { name: "", language: "" },
        malayalamBucket: { name: "", language: "" },
        kannadaBucket: { name: "", language: "" },
        old_hindiBucket: { name: "", language: "" },
        sinhalaBucket : {name :"", language:""},
        arabicBucket : {name :"", language:""},
        afganiBucket : {name :"", language:""},
        burmeseBucket : {name :"", language:""},
        khmerBucket : {name :"", language:""}
    })
    const [lang, setLang] = useState({
        default:"",
        hi: "",
        en: "",
        gu: "",
        ta: "",
        te: "",
        pa: "",
        ur: "",
        ks: "",
        as: "",
        or: "",
        raj: "",
        bn: "",
        har: "",
        bho: "",
        ml: "",
        kn: "",
        old_hindi: "",
        mr: "",
        si:"",
        ar:"",
        af:"",
        bur:"",
        khm:""
    })
    const [deleteMediaList, setDeleteMediaList] = useState([]);
    const [audioVideo, setAudioVideo] = useState("")
    const [proUser, setproUser] = useState("");


    const fetchBucketById = () => {
        //${process.env.REACT_APP_CMS_CONTENT_IP}
        axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`).then(
            res => {
                console.log(res.data)
                let name = JSON.parse(res.data.result.result.bucketGroupName)
                    
                setEnable(res.data.result.result.enabled.toString())
                setMore(res.data.result.result.more)
                setEnglishLang(name.en)
                setHindiLang(name.hi)
                setMarathiiLang(name.mr)
                setGujaratiLang(name.gu)
                setKannadaLang(name.kn)
                setTeluguLang(name.te)
                setTamilLang(name.ta)
                setPunjabiLang(name.pa)
                setMalayalamLang(name.ml ? name.ml : '');
                    setOdisaLang(name.or ? name.or : '');
                    setUrduLang(name.ur ? name.ur :'' );
                    setBengaliLang(name.bn ? name.bn :'' );

                    setSinhalaLang(name.si ? name.si : '');
    setKhmerLang(name.khm ? name.khm : '');
     setArabicLang(name.ar ? name.ar : '');
     setBurmeseLang(name.bur ? name.bur : '');
     setAfganiLang(name.af ? name.af : '');

                setContents(res.data.result.result.contentType)
                setMedia(res.data.result.result.media)
                setRow(res.data.result.result.row)
                setSize(res.data.result.result.size)
                setproUser(res.data.result.result.proUser)

                let tag = res.data.result.result.tag
                let tags = [];
                for (let index = 0; index < tag.length; index++) {
                    if(tag[index]!= ''){
                        tags.push(tag[index])
                    }
                }
                console.log('tag==',tags);
                setBucketStyle(res.data.result.result.bucketStyle)
                setTag(tags)
                if (res.data.result.result.languageSpec == "undefined" || res.data.result.result.languageSpec == undefined) {
                    setLanguageSpec("Yes")
                }
                else {
                    setLanguageSpec(res.data.result.result.languageSpec ? res.data.result.result.languageSpec : "Yes")
                }
                setLang({
                    default: res.data.result.result.multiLanguage.default ? res.data.result.result.multiLanguage.default.id : "",
                    hi: res.data.result.result.multiLanguage.hi.id ? res.data.result.result.multiLanguage.hi.id : "",
                    en: res.data.result.result.multiLanguage.en.id ? res.data.result.result.multiLanguage.en.id : "",
                    gu: res.data.result.result.multiLanguage.gu.id ? res.data.result.result.multiLanguage.gu.id : "",
                    ta: res.data.result.result.multiLanguage.ta.id ? res.data.result.result.multiLanguage.ta.id : "",
                    te: res.data.result.result.multiLanguage.te.id ? res.data.result.result.multiLanguage.te.id : "",
                    pa: res.data.result.result.multiLanguage.pa.id ? res.data.result.result.multiLanguage.pa.id : "",
                    ur: res.data.result.result.multiLanguage.ur.id ? res.data.result.result.multiLanguage.ur.id : "",
                    ks: res.data.result.result.multiLanguage.ks.id ? res.data.result.result.multiLanguage.ks.id : "",
                    as: res.data.result.result.multiLanguage.as.id ? res.data.result.result.multiLanguage.as.id : "",
                    or: res.data.result.result.multiLanguage.or.id ? res.data.result.result.multiLanguage.or.id : "",
                    raj: res.data.result.result.multiLanguage.raj.id ? res.data.result.result.multiLanguage.raj.id : "",
                    bn: res.data.result.result.multiLanguage.bn.id ? res.data.result.result.multiLanguage.bn.id : "",
                    har: res.data.result.result.multiLanguage.har.id ? res.data.result.result.multiLanguage.har.id : "",
                    bho: res.data.result.result.multiLanguage.bho.id ? res.data.result.result.multiLanguage.bho.id : "",
                    ml: res.data.result.result.multiLanguage.ml.id ? res.data.result.result.multiLanguage.ml.id : "",
                    kn: res.data.result.result.multiLanguage.kn.id ? res.data.result.result.multiLanguage.kn.id : "",
                    old_hindi: res.data.result.result.multiLanguage.old_hindi.id ? res.data.result.result.multiLanguage.old_hindi.id : "",
                    mr: res.data.result.result.multiLanguage.mr.id ? res.data.result.result.multiLanguage.mr.id : "",

                    si: res.data.result.result.multiLanguage.si? res.data.result.result.multiLanguage.si.id : "",
                    ar: res.data.result.result.multiLanguage.ar? res.data.result.result.multiLanguage.ar.id : "",
                    af: res.data.result.result.multiLanguage.af? res.data.result.result.multiLanguage.af.id : "",
                    bur: res.data.result.result.multiLanguage.bur? res.data.result.result.multiLanguage.bur.id : "",
                    khm: res.data.result.result.multiLanguage.khm ? res.data.result.result.multiLanguage.khm.id : ""
                })
                setLangBucket({
                    defaultBucket: res.data.result.result.multiLanguage.default ? res.data.result.result.multiLanguage.default.name : { name: "", language: "" },
                    englishBucket: res.data.result.result.multiLanguage.en.name ? res.data.result.result.multiLanguage.en.name : { name: "", language: "" },
                    hindiBucket: res.data.result.result.multiLanguage.hi.name ? res.data.result.result.multiLanguage.hi.name : { name: "", language: "" },
                    gujratiBucket: res.data.result.result.multiLanguage.gu.name ? res.data.result.result.multiLanguage.gu.name : { name: "", language: "" },
                    marathiBucket: res.data.result.result.multiLanguage.mr.name ? res.data.result.result.multiLanguage.mr.name : { name: "", language: "" },
                    tamilBucket: res.data.result.result.multiLanguage.ta.name ? res.data.result.result.multiLanguage.ta.name : { name: "", language: "" },
                    teluguBucket: res.data.result.result.multiLanguage.te.name ? res.data.result.result.multiLanguage.te.name : { name: "", language: "" },
                    punjabiBucket: res.data.result.result.multiLanguage.pa.name ? res.data.result.result.multiLanguage.pa.name : { name: "", language: "" },
                    urduBucket: res.data.result.result.multiLanguage.ur.name ? res.data.result.result.multiLanguage.ur.name : { name: "", language: "" },
                    kashmiriBucket: res.data.result.result.multiLanguage.ks.name ? res.data.result.result.multiLanguage.ks.name : { name: "", language: "" },
                    assameseBucket: res.data.result.result.multiLanguage.as.name ? res.data.result.result.multiLanguage.as.name : { name: "", language: "" },
                    odiaBucket: res.data.result.result.multiLanguage.or.name ? res.data.result.result.multiLanguage.or.name : { name: "", language: "" },
                    rajastaniBucket: res.data.result.result.multiLanguage.raj.name ? res.data.result.result.multiLanguage.raj.name : { name: "", language: "" },
                    bengaliBucket: res.data.result.result.multiLanguage.bn.name ? res.data.result.result.multiLanguage.bn.name : { name: "", language: "" },
                    haryanviBucket: res.data.result.result.multiLanguage.har.name ? res.data.result.result.multiLanguage.har.name : { name: "", language: "" },
                    bhojpuriBucket: res.data.result.result.multiLanguage.bho.name ? res.data.result.result.multiLanguage.bho.name : { name: "", language: "" },
                    malayalamBucket: res.data.result.result.multiLanguage.ml.name ? res.data.result.result.multiLanguage.ml.name : { name: "", language: "" },
                    kannadaBucket: res.data.result.result.multiLanguage.kn.name ? res.data.result.result.multiLanguage.kn.name : { name: "", language: "" },
                    old_hindiBucket: res.data.result.result.multiLanguage.old_hindi.name ? res.data.result.result.multiLanguage.old_hindi.name : { name: "", language: "" },

                    sinhalaBucket: res.data.result.result.multiLanguage.sinhala ? res.data.result.result.multiLanguage.sinhala.name : { name: "", language: "" },
                    arabicBucket: res.data.result.result.multiLanguage.ar ? res.data.result.result.multiLanguage.ar.name : { name: "", language: "" },
                    afganiBucket: res.data.result.result.multiLanguage.afgani ? res.data.result.result.multiLanguage.afgani.name : { name: "", language: "" },
                    burmeseBucket: res.data.result.result.multiLanguage.burmese? res.data.result.result.multiLanguage.burmese.name : { name: "", language: "" },
                    khmerBucket: res.data.result.result.multiLanguage.khm ? res.data.result.result.multiLanguage.khm.name : { name: "", language: "" }
                })
                setLangName({
                    default: res.data.result.result.multiLanguage.default ? res.data.result.result.multiLanguage.default.id : "",
                    english: res.data.result.result.multiLanguage.en.id ? res.data.result.result.multiLanguage.en.id : "",
                    hindi: res.data.result.result.multiLanguage.hi.id ? res.data.result.result.multiLanguage.hi.id : "",
                    gujrati: res.data.result.result.multiLanguage.gu.id ? res.data.result.result.multiLanguage.gu.id : "",
                    marathi: res.data.result.result.multiLanguage.mr.id ? res.data.result.result.multiLanguage.mr.id : "",
                    tamil: res.data.result.result.multiLanguage.ta.id ? res.data.result.result.multiLanguage.ta.id : "",
                    telugu: res.data.result.result.multiLanguage.te.id ? res.data.result.result.multiLanguage.te.id : "",
                    punjabi: res.data.result.result.multiLanguage.pa.id ? res.data.result.result.multiLanguage.pa.id : "",
                    urdu: res.data.result.result.multiLanguage.ur.id ? res.data.result.result.multiLanguage.ur.id : "",
                    kashmiri: res.data.result.result.multiLanguage.ks.id ? res.data.result.result.multiLanguage.ks.id : "",
                    assamese: res.data.result.result.multiLanguage.as.id ? res.data.result.result.multiLanguage.as.id : "",
                    odia: res.data.result.result.multiLanguage.or.id ? res.data.result.result.multiLanguage.or.id : "",
                    rajastani: res.data.result.result.multiLanguage.raj.id ? res.data.result.result.multiLanguage.raj.id : "",
                    bengali: res.data.result.result.multiLanguage.bn.id ? res.data.result.result.multiLanguage.bn.id : "",
                    haryanvi: res.data.result.result.multiLanguage.har.id ? res.data.result.result.multiLanguage.har.id : "",
                    bhojpuri: res.data.result.result.multiLanguage.bho.id ? res.data.result.result.multiLanguage.bho.id : "",
                    malayalam: res.data.result.result.multiLanguage.ml.id ? res.data.result.result.multiLanguage.ml.id : "",
                    kannada: res.data.result.result.multiLanguage.kn.id ? res.data.result.result.multiLanguage.kn.id : "",
                    old_hindi: res.data.result.result.multiLanguage.old_hindi.id ? res.data.result.result.multiLanguage.old_hindi.id : "",

                    sinhala: res.data.result.result.multiLanguage.sinhala ? res.data.result.result.multiLanguage.sinhala.id : "",
                    arabic: res.data.result.result.multiLanguage.ar? res.data.result.result.multiLanguage.ar.id : "",
                    afgani: res.data.result.result.multiLanguage.afgani ? res.data.result.result.multiLanguage.afgani.id : "",
                    burmese: res.data.result.result.multiLanguage.burmese ? res.data.result.result.multiLanguage.burmese.id : "",
                    khmer: res.data.result.result.multiLanguage.khm ? res.data.result.result.multiLanguage.khm.id : "",
                })
                setAudioVideo(res.data.result.result.bucketTypeCategory ? res.data.result.result.bucketTypeCategory : "")
            }
        )
    }

    useEffect(() => {
        fetchBucketById()
    }, [])

    // const handleproToggle = (e) =>{
    //     console.log('ee',e)
    //     setproToggle(e)
    // }

    const handleInputChange = (e) => {
        setLangName({
            ...langName,
            [e.target.name]: e.target.value
        })
    }

    const thumbnaillHandle = (e) => {
        setMedia([...media, ...e.target.files])
    }


    const deletethumbnail = (e, i, ele) => {
        let newArray = media.filter((item, index) => index !== i);

        setDeleteMediaList([...deleteMediaList, ele]);

        setMedia(newArray)
    }

    const fetchBucketName = (id) => {
        const name = axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${id}?storeId=${localStorage.getItem("storeId")}`).then((res) => res.data)
        return name
    }


    const handleLanguage = (language) => {
        if (language === "english") {
            fetchBucketName(langName.english).then(data => {
                let name = JSON.parse(data.result.result.names)
                setLang({
                    ...lang,
                    en: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    englishBucket: { name: name[0].en, language: data.result.result.language }
                })
            }
            ).catch(err => {
                console.log(err);
                cogoToast.error("wrong bucket Id")
            })
            // setEnglish("")
        } else if (language === "hindi") {
            fetchBucketName(langName.hindi).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    hi: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    hindiBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "default") {
            fetchBucketName(langName.default).then(data => {
                
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    default: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    defaultBucket: { name: name[0].default, language: data.result.result.language }
                })
                console.log(name[0].default,'==',data.result.result.language)
            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "sinhala") {
            fetchBucketName(langName.sinhala).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    sinhala: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    sinhalaBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "arabic") {
            fetchBucketName(langName.arabic).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ar: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    arabicBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "afgani") {
            fetchBucketName(langName.afgani).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    afgani: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    afganiBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "burmese") {
            fetchBucketName(langName.burmese).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    burmese: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    burmeseBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "khmer") {
            fetchBucketName(langName.khmer).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    khm: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    khmerBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "gujrati") {
            fetchBucketName(langName.gujrati).then(data => {
                let name = JSON.parse(data.result.result.names)
                setLang({
                    ...lang,
                    gu: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    gujratiBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "tamil") {
            fetchBucketName(langName.tamil).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ta: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    tamilBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "telugu") {
            fetchBucketName(langName.telugu).then(data => {
                let name = JSON.parse(data.result.result.names)
                setLang({
                    ...lang,
                    te: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    teluguBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "punjabi") {
            fetchBucketName(langName.punjabi).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    pa: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    punjabiBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "urdu") {
            fetchBucketName(langName.urdu).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ur: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    urduBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "kashmiri") {
            fetchBucketName(langName.kashmiri).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ks: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    kashmiriBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "assamese") {
            fetchBucketName(langName.assamese).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    as: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    assameseBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "odia") {
            fetchBucketName(langName.odia).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    or: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    odiaBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "rajastani") {
            fetchBucketName(langName.rajastani).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    raj: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    rajastaniBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "bengali") {
            fetchBucketName(langName.bengali).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    bn: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    bengaliBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "haryanvi") {
            fetchBucketName(langName.haryanvi).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    har: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    haryanviBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "bhojpuri") {
            fetchBucketName(langName.bhojpuri).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    bho: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    bhojpuriBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "malayalam") {
            fetchBucketName(langName.malayalam).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    ml: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    malayalamBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "kannada") {
            fetchBucketName(langName.kannada).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    kn: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    kannadaBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "old_hindi") {
            fetchBucketName(langName.old_hindi).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    old_hindi: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    old_hindiBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }
        else if (language === "marathi") {
            fetchBucketName(langName.marathi).then(data => {
                let name = JSON.parse(data.result.result.names)
                // setHindiBucket([...hindiBucket, name[0].en])
                setLang({
                    ...lang,
                    mr: data.result.result._id
                })
                setLangBucket({
                    ...langBucket,
                    marathiBucket: { name: name[0].en, language: data.result.result.language }
                })

            }
            ).catch(err => {
                cogoToast.error("wrong bucket Id")
            })
        }



    }

    const clearLanguage = (e, language) => {

        if (language === "english") {
            setLangName({
                ...langName,
                english: ""
            })
            setLang({
                ...lang,
                en: ""
            })
            setLangBucket({
                ...langBucket,
                englishBucket: { name: "", language: "" }
            })

            // setEnglish("")
        } else if (language === "hindi") {
            setLangName({
                ...langName,
                hindi: ""
            })

            setLang({
                ...lang,
                hi: ""
            })
            setLangBucket({
                ...langBucket,
                hindiBucket: { name: "", language: "" }
            })

        }
        else if (language === "sinhala") {
            setLangName({
                ...langName,
                sinhala: ""
            })

            setLang({
                ...lang,
                si: ""
            })
            setLangBucket({
                ...langBucket,
                sinhalaBucket: { name: "", language: "" }
            })

        }
        else if (language === "arabic") {
            setLangName({
                ...langName,
                arabic: ""
            })

            setLang({
                ...lang,
                ar: ""
            })
            setLangBucket({
                ...langBucket,
                arabicBucket: { name: "", language: "" }
            })

        }
        else if (language === "afgani") {
            setLangName({
                ...langName,
                afgani: ""
            })

            setLang({
                ...lang,
                af: ""
            })
            setLangBucket({
                ...langBucket,
                afganiBucket: { name: "", language: "" }
            })

        }
        else if (language === "burmese") {
            setLangName({
                ...langName,
                burmese: ""
            })

            setLang({
                ...lang,
                bur: ""
            })
            setLangBucket({
                ...langBucket,
                burmeseBucket: { name: "", language: "" }
            })

        }
        else if (language === "khmer") {
            setLangName({
                ...langName,
                khmer: ""
            })

            setLang({
                ...lang,
                khm: ""
            })
            setLangBucket({
                ...langBucket,
                khmerBucket: { name: "", language: "" }
            })

        }
        else if (language === "default") {
            setLangName({
                ...langName,
                default: ""
            })

            setLang({
                ...lang,
                default: ""
            })
            setLangBucket({
                ...langBucket,
                defaultBucket: { name: "", language: "" }
            })

        }
        else if (language === "gujrati") {
            setLangName({
                ...langName,
                gujrati: ""
            })
            setLang({
                ...lang,
                gu: ""
            })
            setLangBucket({
                ...langBucket,
                gujratiBucket: { name: "", language: "" }
            })


        }
        else if (language === "tamil") {
            setLangName({
                ...langName,
                tamil: ""
            })
            setLang({
                ...lang,
                ta: ""
            })
            setLangBucket({
                ...langBucket,
                tamilBucket: { name: "", language: "" }
            })

        }
        else if (language === "telugu") {
            setLangName({
                ...langName,
                telugu: ""
            })

            setLang({
                ...lang,
                te: ""
            })
            setLangBucket({
                ...langBucket,
                teluguBucket: { name: "", language: "" }
            })

        }
        else if (language === "punjabi") {
            setLangName({
                ...langName,
                punjabi: ""
            })
            setLang({
                ...lang,
                pa: ""
            })
            setLangBucket({
                ...langBucket,
                punjabiBucket: { name: "", language: "" }
            })
        }
        else if (language === "urdu") {
            setLangName({
                ...langName,
                urdu: ""
            })
            setLang({
                ...lang,
                ur: ""
            })
            setLangBucket({
                ...langBucket,
                urduBucket: { name: "", language: "" }
            })
        }
        else if (language === "kashmiri") {
            setLangName({
                ...langName,
                kashmiri: ""
            })
            setLang({
                ...lang,
                ks: ""
            })
            setLangBucket({
                ...langBucket,
                kashmiriBucket: { name: "", language: "" }
            })
        }
        else if (language === "assamese") {
            setLangName({
                ...langName,
                assamese: ""
            })
            setLang({
                ...lang,
                as: ""
            })
            setLangBucket({
                ...langBucket,
                assameseBucket: { name: "", language: "" }
            })
        }
        else if (language === "odia") {
            setLangName({
                ...langName,
                odia: ""
            })
            setLang({
                ...lang,
                or: ""
            })
            setLangBucket({
                ...langBucket,
                odiaBucket: { name: "", language: "" }
            })
        }
        else if (language === "rajastani") {
            setLangName({
                ...langName,
                rajastani: ""
            })
            setLang({
                ...lang,
                raj: ""
            })
            setLangBucket({
                ...langBucket,
                rajastaniBucket: { name: "", language: "" }
            })
        }
        else if (language === "bengali") {
            setLangName({
                ...langName,
                bengali: ""
            })
            setLang({
                ...lang,
                bn: ""
            })
            setLangBucket({
                ...langBucket,
                bengaliBucket: { name: "", language: "" }
            })
        }
        else if (language === "haryanvi") {
            setLangName({
                ...langName,
                haryanvi: ""
            })
            setLang({
                ...lang,
                har: ""
            })
            setLangBucket({
                ...langBucket,
                haryanviBucket: { name: "", language: "" }
            })
        }
        else if (language === "bhojpuri") {
            setLangName({
                ...langName,
                bhojpuri: ""
            })
            setLang({
                ...lang,
                bho: ""
            })
            setLangBucket({
                ...langBucket,
                bhojpuriBucket: { name: "", language: "" }
            })
        }
        else if (language === "malayalam") {
            setLangName({
                ...langName,
                malayalam: ""
            })
            setLang({
                ...lang,
                ml: ""
            })
            setLangBucket({
                ...langBucket,
                malayalamBucket: { name: "", language: "" }
            })
        }
        else if (language === "kannada") {
            setLangName({
                ...langName,
                kannada: ""
            })
            setLang({
                ...lang,
                kn: ""
            })
            setLangBucket({
                ...langBucket,
                kannadaBucket: { name: "", language: "" }
            })


        }
        else if (language === "old_hindi") {
            setLangName({
                ...langName,
                old_hindi: ""
            })
            setLang({
                ...lang,
                old_hindi: ""
            })
            setLangBucket({
                ...langBucket,
                old_hindiBucket: { name: "", language: "" }
            })
        }
        else if (language === "marathi") {
            setLangName({
                ...langName,
                marathi: ""
            })
            setLang({
                ...lang,
                mr: ""
            })
            setLangBucket({
                ...langBucket,
                marathiBucket: { name: "", language: "" }
            })
        }



    }

    const postBucketGroup = (data) => {
        const fd = new FormData()
        fd.append("type", "bucketGroup");
        fd.append("bucketGroupName", JSON.stringify(data.bucketGroupName));
        fd.append("row", data.row);
        fd.append("size", data.size);
        fd.append("contentType", data.contentType);
        fd.append("enabled", data.enabled);
        fd.append("more", data.more);
        fd.append("bucketStyle", data.bucketStyle);
        fd.append("bucketTypeCategory", data.bucketTypeCategory);
        fd.append("languageSpec", data.languageSpec);
        fd.append('proUser',data.proUser)

        console.log('tagg',data.tag);
        for (let index = 0; index < data.tag.length; index++) {
            const element = data.tag[index];
            console.log('ele',element);
            if(element != 'null'){
                fd.append(`tag[${index}]`, element)
            }
            // fd.append(`tag[${index}]`, element)
        }
        if(data.tag.length == 0){
            fd.append(`tag[]`,[])
        }
        for (let index = 0; index < data.media.length; index++) {
            const element = data.media[index];
            fd.append('file', element)
        }
        data.deleteMedia.map((ele, i) => {
            fd.append(`deleteMedia[${i}]`, ele)
        });
        fd.append("multiLanguage", JSON.stringify(data.lang))
        // for(let key in Object.keys(data.lang).length){
        //     fd.append(`mulilanguage.${key}`,data.lang[key])
        //     }
        // Object.keys(data.lang).forEach(key => fd.append(['mulilanguage'][key], data.lang[key]));
        axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}`, fd, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            // setRow("");
            // setSize("")
            // setContents()
            // setMedia([])
            // setEnable("true")
            // setMore("true")
            // setBucketStyle("")
            // setName("")
            // setLang({
            //     hi: "",
            //     en: "",
            //     gu: "",
            //     ta: "",
            //     te: "",
            //     pa: "",
            //     ur: "",
            //     ks: "",
            //     as: "",
            //     or: "",
            //     raj: "",
            //     bn: "",
            //     har: "",
            //     bho: "",
            //     ml: "",
            //     kn: "",
            //     old_hindi: "",
            //     mr: ""
            // }
            // )
            // setLangBucket({
            //     englishBucket: "",
            //     hindiBucket: "",
            //     gujratiBucket: "",
            //     marathiBucket: "",
            //     tamilBucket: "",
            //     teluguBucket: "",
            //     punjabiBucket: "",
            //     urduBucket: "",
            //     kashmiriBucket: "",
            //     assameseBucket: "",
            //     odiaBucket: "",
            //     rajastaniBucket: "",
            //     bengaliBucket: "",
            //     haryanviBucket: "",
            //     bhojpuriBucket: "",
            //     malayalamBucket: "",
            //     kannadaBucket: "",
            //     old_hindiBucket: "",
            // })
            // setLangName({
            //     english: "",
            //     hindi: "",
            //     gujrati: "",
            //     marathi: "",
            //     tamil: "",
            //     telugu: "",
            //     punjabi: "",
            //     urdu: "",
            //     kashmiri: "",
            //     assamese: "",
            //     odia: "",
            //     rajastani: "",
            //     bengali: "",
            //     haryanvi: "",
            //     bhojpuri: "",
            //     malayalam: "",
            //     kannada: "",
            //     old_hindi: "",
            // })
            props.history.push("/listBucketGroup")
            cogoToast.success(res.data.result.message)
        }).catch(err => {
            cogoToast(err.response)
        })
    }

    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }
    const onSubmit = () => {
        let obj = {
            row: row,
            size: size,
            lang: lang,
            contentType: contents,
            enabled: str2bool(enable),
            more: str2bool(more),
            media: media,
            bucketStyle: bucketStyle,
            bucketGroupName:  {
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,

                    or:odisaLang,
                    ur:urduLang,
                    bn:bengaliLang,
                    ml:malayalamLang,
                    si:sinhalaLang,
                    ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            },
            deleteMedia: deleteMediaList,
            bucketTypeCategory: audioVideo,
            tag: tag,
            languageSpec: languageSpec,
            "storeId":localStorage.getItem("storeId"),
            "proUser":proUser
        }

        postBucketGroup(obj)
    }
    return (
        <div>
            <Paper component={Box} p={4}>
                <h1 className="text-center">Edit Bucket Group</h1>
                <Grid container>
                    <Grid item xs={6}>
                    <TextField variant="outlined" className={classes.inputField} style={{width:"40%"}} required label="Bucket Group Name" name="name" value={englishLang} onChange={e => setEnglishLang(e.target.value)} ></TextField>
                        <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        <FormControl className={classes.formControl}>
                            <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                            <RadioGroup aria-label="enable" name="enable" value={enable} onChange={(e) => setEnable(e.target.value)} row>

                                <FormControlLabel value="true" control={<Radio />} label="true" />
                                <FormControlLabel value="false" control={<Radio />} label="false" />

                            </RadioGroup>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormLabel className={classes.lables} component="div" >More</FormLabel>
                            <RadioGroup aria-label="More" name="more" value={more} onChange={(e) => setMore(e.target.value)} row>

                                <FormControlLabel value="true" control={<Radio />} label="true" />
                                <FormControlLabel value="false" control={<Radio />} label="false" />

                            </RadioGroup>
                        </FormControl>
                        <Button
                            className={classes.upload}
                            variant="outlined"
                            component="label"

                        >
                            Media
                            <input
                                multiple
                                type="file"
                                hidden
                                onChange={thumbnaillHandle}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                            />
                        </Button>
                        {media && media.length > 0 ?
                            (media.map((ele, i) => (<div><p className="mt-2 ml-4" style={{ cursor: "pointer", display: "inline" }} onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/bucketGroup/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deletethumbnail(e, i, ele)} /></div>)))
                            : null}

                        <FormControl className={classes.formControl} >
                            <InputLabel id="demo-mutiple-checkbox-label">Radio</InputLabel>
                            <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                                input={<Input />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={props.MenuProps}
                            >
                                {radio.map((name) => (
                                    <MenuItem key={name.id} value={name.value}>
                                        <Checkbox checked={tag.indexOf(name.value) > -1} />
                                        <ListItemText primary={name.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: "60%" }}>
                            <InputLabel id="demo-simple-select-outlined-label">Language Specific</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={languageSpec}
                                onChange={(e) => setLanguageSpec(e.target.value)}
                                label="Varient Name"
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>


                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Content Type</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contents}
                                onChange={(e) => setContents(e.target.value)}
                                label="Content type"
                            >
                                {
                                    content_type && content_type.length > 0 ?
                                        content_type.map((element, id) => {

                                            return (
                                                <MenuItem key={id} value={element.Description}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Content Type</MenuItem>

                                }

                            </Select>

                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: "60%" }}>
                            <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={audioVideo}
                                onChange={(e) => setAudioVideo(e.target.value)}
                                label="Varient Name"
                            >
                                <MenuItem value="audio">Audio</MenuItem>
                                <MenuItem value="video">Video</MenuItem>


                            </Select>

                        </FormControl>

                        <TextField variant="outlined" className={classes.inputField} width="25" required label="Row" name="row" value={row} onChange={(e) => setRow(e.target.value)} />
                        <TextField variant="outlined" className={classes.inputField} width="25" required label="Size" name="size" value={size} onChange={(e) => setSize(e.target.value)} />
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 50 } }} variant="outlined" className={classes.inputField} width="25" required label="Bucket Style" name="bucketStyle" value={bucketStyle} onChange={(e) => setBucketStyle(e.target.value)} />

                    </Grid>
                    {/* <Grid item md={6} sm={12}>
            
            <div style={{ "display":"flex", "flex-direction":"row" }}><Tooltip title="to create pro user bucket"><InfoIcon/></Tooltip>&nbsp;<div>Pro User :</div>&nbsp;
            <div><Switch 
            onChange={handleproToggle} value={proToggle} checked={proToggle}
             />
             </div>
             </div>
                </Grid> */}
                 <Grid item md={6} sm={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Pro User</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={proUser}
                        onChange={(e) => setproUser(e.target.value)}
                        label="proUser"
                        name="proUser"
                    >
                    <MenuItem  value="0">Free User</MenuItem>
                    <MenuItem  value="1">Pro User</MenuItem>
                    <MenuItem  value="2">Both</MenuItem>
                                    
                    </Select>

                </FormControl>

            </Grid>
                </Grid>
                <h3 className="mt-5">Buckets</h3>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Default" name="default" value={langName.default} onChange={handleInputChange} />
                            
                            {langBucket.defaultBucket && langBucket.defaultBucket.name && langBucket.defaultBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.defaultBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.defaultBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }


                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("default")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "default")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="English" name="english" value={langName.english} onChange={handleInputChange} />

                            {langBucket.englishBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.englishBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.englishBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }


                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("english")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "english")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Hindi" name="hindi" value={langName.hindi} onChange={handleInputChange} />
                            {langBucket.hindiBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.hindiBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.hindiBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("hindi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "hindi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Marathi" name="marathi" value={langName.marathi} onChange={handleInputChange} />
                            {langBucket.marathiBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.marathiBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.marathiBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("marathi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "marathi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Gujrati" name="gujrati" value={langName.gujrati} onChange={handleInputChange} />
                            {langBucket.gujratiBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.gujratiBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.gujratiBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("gujrati")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "gujrati")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Tamil" name="tamil" value={langName.tamil} onChange={handleInputChange} />
                            {langBucket.tamilBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.tamilBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.tamilBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("tamil")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "tamil")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Telugu" name="telugu" value={langName.telugu} onChange={handleInputChange} />
                            {langBucket.teluguBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.teluguBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.teluguBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("telugu")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "telugu")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Punjabi" name="punjabi" value={langName.punjabi} onChange={handleInputChange} />
                            {langBucket.punjabiBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.punjabiBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.punjabiBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("punjabi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "punjabi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Urdu" name="urdu" value={langName.urdu} onChange={handleInputChange} />
                            {langBucket.urduBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.urduBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.urduBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("urdu")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "urdu")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Kashmiri" name="kashmiri" value={langName.kashmiri} onChange={handleInputChange} />
                            {langBucket.kashmiriBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.kashmiriBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.kashmiriBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("kashmiri")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "kashmiri")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Assamese" name="assamese" value={langName.assamese} onChange={handleInputChange} />
                            {langBucket.assameseBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.assameseBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.assameseBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("assamese")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "assamese")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Odia" name="odia" value={langName.odia} onChange={handleInputChange} />
                            {langBucket.odiaBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.odiaBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.odiaBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("odia")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "odia")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Rajastani" name="rajastani" value={langName.rajastani} onChange={handleInputChange} />
                            {langBucket.rajastaniBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.rajastaniBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.rajastaniBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("rajastani")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "rajastani")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Bengali" name="bengali" value={langName.bengali} onChange={handleInputChange} />
                            {langBucket.bengaliBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.bengaliBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.bengaliBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("bengali")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "bengali")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Haryanvi" name="haryanvi" value={langName.haryanvi} onChange={handleInputChange} />
                            {langBucket.haryanviBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.haryanviBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.haryanviBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("haryanvi")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "haryanvi")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Bhojpuri" name="bhojpuri" value={langName.bhojpuri} onChange={handleInputChange} />
                            {langBucket.bhojpuriBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.bhojpuriBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.bhojpuriBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("bhojpuri")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "bhojpuri")} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Malayalam" name="malayalam" value={langName.malayalam} onChange={handleInputChange} />
                            {langBucket.malayalamBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.malayalamBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.malayalamBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("malayalam")} />
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "malayalam")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Kannada" name="kannada" value={langName.kannada} onChange={handleInputChange} />
                            {langBucket.kannadaBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.kannadaBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.kannadaBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("kannada")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "kannada")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Old Hindi" name="old_hindi" value={langName.old_hindi} onChange={handleInputChange} />
                            {langBucket.old_hindiBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.old_hindiBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.old_hindiBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px", cursor: "pointer" }} variant="contained" color="primary" onClick={() => handleLanguage("old_hindi")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px", cursor: "pointer" }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "old_hindi")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Sinhala" name="sinhala" value={langName.sinhala} onChange={handleInputChange} />
                            {langBucket.sinhalaBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.sinhalaBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.sinhalaBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px",cursor:"pointer"  }} variant="contained" color="primary" onClick={() => handleLanguage("sinhala")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px",cursor:"pointer"  }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "sinhala")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Arabic" name="arabic" value={langName.arabic} onChange={handleInputChange} />
                            {langBucket.arabicBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.arabicBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.arabicBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px",cursor:"pointer"  }} variant="contained" color="primary" onClick={() => handleLanguage("arabic")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px",cursor:"pointer"  }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "arabic")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Afgani" name="afgani" value={langName.afgani} onChange={handleInputChange} />
                            {langBucket.afganiBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.afganiBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.afganiBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px",cursor:"pointer"  }} variant="contained" color="primary" onClick={() => handleLanguage("afgani")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px",cursor:"pointer"  }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "afgani")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Burmese" name="burmese" value={langName.burmese} onChange={handleInputChange} />
                            {langBucket.burmeseBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.burmeseBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.burmeseBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px",cursor:"pointer"  }} variant="contained" color="primary" onClick={() => handleLanguage("burmese")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px",cursor:"pointer"  }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "burmese")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Grid container className="mt-3 align-items-center p-2">
                        <Grid item xs={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Khmer" name="khmer" value={langName.khmer} onChange={handleInputChange} />
                            {langBucket.khmerBucket.name.split("").length > 0 &&
                                <div>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.khmerBucket.name}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                    <li style={{ listStyle: "none", display: "inline", margin: "8px" }}>
                                        <Chip
                                            label={langBucket.khmerBucket.language}
                                        //   onDelete={handleDelete(data)}
                                        //   className={classes.chip}
                                        />
                                    </li>
                                </div>
                            }

                        </Grid>
                        <Grid item xs={2}>
                            <AddIcon className="mb-4" style={{ marginLeft: "-160px",cursor:"pointer"  }} variant="contained" color="primary" onClick={() => handleLanguage("khmer")}></AddIcon>
                            <DeleteForeverOutlinedIcon className="mb-4" style={{ marginLeft: "5px",cursor:"pointer"  }} variant="contained" color="secondary" onClick={(e) => clearLanguage(e, "khmer")}></DeleteForeverOutlinedIcon>
                        </Grid>
                    </Grid>
                </Paper>
                <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>

                <Button className="m-3" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                <Button className="m-3" variant="contained" color="secondary">Clear</Button>
            </Paper>

        </div>
    )
}
