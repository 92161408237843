const initialState = {
    app:{},
    error_message:null,
    success_message:null,
}

const appReducer=(state=initialState, action)=>{
    switch(action.type){
        case "ON_ADDAPP_SUCCESS":
            return{
                ...state,
                success_message:action.payload,
                error_message:null,
            }
        case "ON_ADDAPP_FAILURE":
            return{
                ...state,
                success_message:null,
                error_message:action.payload,
            }    
        default:
            return{
                ...state
            }    
    }
}

export default appReducer;