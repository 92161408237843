import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    MenuItem,
    IconButton


} from '@material-ui/core';

import { useStyles } from '../../styles/style';
import axios from 'axios';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import { add } from 'date-fns';
import cogoToast from 'cogo-toast';
import MultiLanguageModal from '../Reusable/multiLanguageModal';


export default function CreateTopNavigation(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [enable, setEnable] = useState("true");
    const [liveDate, setLiveDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [pages, setPages] = useState([]);
    const [singlePage, setSinglePage] = useState('')
    const [displayName, setDisplayName] = useState("");


    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);
    

    const enhandleChange = (event) => {
        setEnable(event.target.value);

    };

    const handlePageChange = (event) => {
        setSinglePage(event.target.value);
    }

    const fetchPage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/page?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    setPages(res.data.result.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        fetchPage();
    }, [])




    const postApi = async (data) => {

        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, data)
            .then(
                res => {
                    console.log(res)
                    cogoToast.success(res.data.result.message)

                }
            ).catch(err => {
                console.log(err);
                cogoToast.error(err.response.result.message)
            })

    }

    // convert string to boolean
    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }


    const onSubmit = () => {
        var obj = {
            "type": "top-nav",
            "name": name,
            "enabled": str2bool(enable),
            "live_date_time": liveDate,
            "expiry": expiryDate,
            "pageId": singlePage,
            "displayName": JSON.stringify({
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            }),
            "storeId":localStorage.getItem("storeId")
        }

        postApi(obj)
        setName("")
        setSinglePage("")
        setDisplayName("")
    }

    const onClear = () => {
        setName("");
        setSinglePage("")
    }



    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div className="text-center">
                    <h1>Add Top Navigation</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField className={classes.inputField} width="25" variant="outlined" required label="Name" name="name" value={name} onChange={e => setName(e.target.value)} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField className={classes.inputField} width="25" variant="outlined" required label="Display Name" name="name" value={englishLang} onChange={e => setEnglishLang(e.target.value)} ></TextField>
                            <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Live Date time" className={classes.inputField} value={liveDate} onChange={e => setLiveDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!liveDate}
                                    onClick={() => setLiveDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Expiry Date time" className={classes.inputField} value={expiryDate} onChange={e => setExpiryDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!expiryDate}
                                    onClick={() => setExpiryDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={enable} onChange={enhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Page</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={singlePage}
                                    onChange={handlePageChange}
                                    label="Pages"
                                >
                                    {
                                        pages && pages.length > 0 ?
                                            pages.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element._id}>{element.pageCode} ({element.title.en})</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No Pages</MenuItem>

                                    }

                                </Select>

                            </FormControl>


                        </Grid>

                        <Grid item md={12}>
                            <Button className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>
                        </Grid>

                    </Grid>
                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                </div>
            </Paper>




        </Fragment>
    )
}