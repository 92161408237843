import { Fragment } from 'react';
import { Provider } from 'react-redux'
import Store from './components/Redux/store';
import './App.css';
import Sidebar from './components/Pages/sidebar/sideBar';
import { useStyles } from './components/styles/style';
import { createMuiTheme, CssBaseline, ThemeProvider, StylesProvider } from '@material-ui/core';
import { BrowserRouter, Route, withRouter, Switch } from 'react-router-dom';
import 'react-transliterate/dist/index.css';

import Login from './components/Pages/login/login';
import ResetPassword from './components/Pages/reset/resetPassword';
import CreateApp from './components/Pages/AppCreate/createApp';
import EditApp from './components/Pages/AppCreate/editApp';
import ListApp from './components/Pages/AppCreate/listApp';
import LinkAppWithPage from './components/Pages/AppCreate/linkAppWithPage';

import CreatePage from './components/Pages/createPage/createPage';
import LinkPageWithBucket from './components/Pages/createPage/linkPageWithBucket';
import ListPage from './components/Pages/createPage/listPage';
import EditPage from './components/Pages/createPage/editPage';

import CreateTopNavigation from './components/Pages/navigator/createTopNavigation';
import ListTopNav from './components/Pages/navigator/listTopNav';
import EditTopNavigation from './components/Pages/navigator/editTopNav';
import AddNavigationCollection from './components/Pages/navigator/addNavigationCollection';
import ListNavigationCollection from './components/Pages/navigator/listNavigationCollection';
import EditNavigationCollection from './components/Pages/navigator/editNavigationCollection';

import CreateStory from './components/Pages/Storys/createStory';
import ListStory from './components/Pages/Storys/listStory';
import EditStory from './components/Pages/Storys/editStory';
import CreateStoryCollection from './components/Pages/Storys/createStoryCollection';
import ListStoryCollection from './components/Pages/Storys/listStoryCollection';
import EditStoryCollection from './components/Pages/Storys/editStoryCollection';

import CreateBucket from './components/Pages/bucket/createBucket';
import ListBucket from './components/Pages/bucket/bucketList';
import EditBucket from './components/Pages/bucket/editBucket';
import AddBucketGroup from './components/Pages/bucket/addBucketGroup';
import ListBucketGroup from './components/Pages/bucket/bucketGroupList';
import EditBucketGroup from './components/Pages/bucket/editBucketGroup';

import CreatePaytmBucket from './components/Pages/paytm_bucket/createBucket';
import ListPaytmBucket from './components/Pages/paytm_bucket/bucketList';
import EditPaytmBucket from './components/Pages/paytm_bucket/editBucket';

import CreatePlayList from './components/Pages/playList/createPlayList';
import ListPlayList from './components/Pages/playList/listPlaylist';

import CreateVarient from './components/Pages/varientPages/createVarient';
import EditVerient from './components/Pages/varientPages/editVarient'
import ListVariant from './components/Pages/varientPages/ListVariant';

import CreateUser from './components/Pages/user/createUser';
import EditUser from './components/Pages/user/editUser';
import ListUser from './components/Pages/user/listUser';

import UploadFile from './components/Pages/search/createVarient';
import Editkeyword from './components/Pages/search/editVarient';
import ListKeyword from './components/Pages/search/ListVariant';

import Createcategory from './components/Pages/category/createCategory';
import ListCategory from './components/Pages/category/categoryList';
import LinkCategoryWithBucket from './components/Pages/category/linkCategoryWithBucket';
import CreateCategoryBucket from './components/Pages/category/createCategoryBucket'
import ListBucketCategory from './components/Pages/category/bucketCategoryList';
import EditCategory from './components/Pages/category/editCategory';
import EditCategoryBucket from './components/Pages/category/editCategoryBucket';

import CreateLanguage from './components/Pages/settingsPages/addLanguage';
import ListLanguage from './components/Pages/settingsPages/listLanguage';
import RecomPlaylist from './components/Pages/Recom_Playlist/recomPlaylist';
import EditRecomPlaylist from './components/Pages/Recom_Playlist/editrecomVarient';
import ListRecomPlaylist from './components/Pages/Recom_Playlist/ListrecomVariant';


import AddCharts from './components/Pages/playList/Charts/addCharts';
import ListCharts from './components/Pages/playList/Charts/listCharts';

import AddCollection from './components/Pages/playList/Collections/addCollections';
import ListCollection from './components/Pages/playList/Collections/listCollection';
import EditCollection from './components/Pages/playList/Collections/editCollection';
import AddCollectionOfCollection from './components/Pages/playList/Collections/addCollectionOfCollection';
import ListCollectionOfCollection from './components/Pages/playList/Collections/listCollectionOfCollection';
import EditCollectionOfCollection from './components/Pages/playList/Collections/editCollectionOfCollection';

import CreatePolls from './components/Pages/polls/addPolls';
import ListPolls from './components/Pages/polls/listPolls';
import EditPolls from './components/Pages/polls/editPolls';

import CreateEvent from './components/Pages/events/addEvent';
import ListEvents from './components/Pages/events/listEvents';
import EditEvent from './components/Pages/events/editEvents';
import AddEventCollection from './components/Pages/events/addEventCollection';
import ListEventsCollection from './components/Pages/events/listEventCollection';
import EditEventCollection from './components/Pages/events/editEventCollection';

import CreateOfflineEvent from './components/Pages/off_live_event/addBanner';
import ListOfflineEvents from './components/Pages/off_live_event/listBanner';
import EditOfflineEvent from './components/Pages/off_live_event/editBanner';

import CreateBanner from './components/Pages/banners/addBanner'
import ListBanner from './components/Pages/banners/listBanner';
import EditBanners from './components/Pages/banners/editBanner';
import AddBannerCollection from './components/Pages/banners/addBannerCollection';
import ListBannerCollection from './components/Pages/banners/listBannerCollection';
import EditBannerCollection from './components/Pages/banners/editBannerCollection';
import AddBannerCollectionOfCollection from "./components/Pages/banners/addBannerCollectionOfCollection";
import ListBannerCollectionOfCollection from './components/Pages/banners/bannerCollectionOfCollectionList';
import EditBannerCollectionOfCollection from './components/Pages/banners/editBannerCollectionOfCollection';

import ListTracker from './components/Pages/tracker/listTracker';

import apiConfig from './components/resources/config.json'

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f4f5fd',
    }
  },

});


function App() {
  const classes = useStyles();

  const MainPage = withRouter(({ location }) => {
    return (
      <div>
        {location.pathname !== "/login" && location.pathname !== "/resetPassword"  && <Sidebar></Sidebar>}
        <Route path="/login" render={(props) => <Login apiConfig={apiConfig} {...props} />}></Route>
          <Route path="/resetPassword" render={(props) => <ResetPassword apiConfig={apiConfig} {...props} />}></Route>

        <main className={classes.content}>

          <div className={classes.toolbar} />
          <Switch>
          {/* <Route path="/resetPassword" render={(props) => <ResetPassword apiConfig={apiConfig} {...props} />}></Route> */}
            <Route path="/linkapp/:id" render={(props) => <LinkAppWithPage apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/linkpage/:id" render={(props) => <LinkPageWithBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addApp" render={(props) => <CreateApp apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editapp/:id" render ={(props)=><EditApp apiConfig={apiConfig} {...props}/>}></Route>
            <Route path="/listApp" render={(props) => <ListApp apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addPage" render={(props) => <CreatePage apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listPage" render={(props) => <ListPage apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editPage/:id" render={(props) => <EditPage apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addNavigation" render={(props) => <CreateTopNavigation apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listNavigation" render={(props) => <ListTopNav apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editNavigation/:id" render={(props) => <EditTopNavigation apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addNavigationCollection" render={(props) => <AddNavigationCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listNavigationCollection" render={(props) => <ListNavigationCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editNavigationCollection/:id" render={(props) => <EditNavigationCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addStories" render={(props) => <CreateStory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listStories" render={(props) => <ListStory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/createStoryCollection" render={(props) => <CreateStoryCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listStoryCollection" render={(props) => <ListStoryCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editStoryCollection/:id" render={(props) => <EditStoryCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editStories/:id" render={(props) => <EditStory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addBucket" render={(props) => <CreateBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listBucket" render={(props) => <ListBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editBucket/:id" render={(props) => <EditBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addBucketGroup" render={(props) => <AddBucketGroup apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listBucketGroup" render={(props) => <ListBucketGroup apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editBucketGroup/:id" render={(props) => <EditBucketGroup apiConfig={apiConfig} {...props} />}></Route>


            <Route path="/addPaytmBucket" render={(props) => <CreatePaytmBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listPaytmBucket" render={(props) => <ListPaytmBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editPaytmBucket/:id" render={(props) => <EditPaytmBucket apiConfig={apiConfig} {...props} />}></Route>


            <Route path="/addPlaylist" render={(props) => <CreatePlayList apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listPlaylist" render={(props) => <ListPlayList apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addVarient" render={(props) => <CreateVarient apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listVarient" render={(props) => <ListVariant apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editVarient/:id" render={(props) => <EditVerient apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addCategory" render={(props) => <Createcategory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listCategory" render={(props) => <ListCategory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editCategory/:id" render={(props) => <EditCategory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addCategoryBucket" render={(props) => <CreateCategoryBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editCategoryBucket/:id" render={(props) => <EditCategoryBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listCategoryBucket" render={(props) => <ListBucketCategory apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/linkCategory/:id" render={(props) => <LinkCategoryWithBucket apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addLanguage" render={(props) => <CreateLanguage apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/recomPlaylist" render={(props) => <RecomPlaylist apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listrecomPlaylist" render={(props) => <ListRecomPlaylist apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editrecomPlaylist/:id" render={(props) => <EditRecomPlaylist apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listLanguage" render={(props) => <ListLanguage apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addCharts" render={(props) => <AddCharts apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listCharts" render={(props) => <ListCharts apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addCollection" render={(props) => <AddCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listCollection" render={(props) => <ListCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editCollection/:id" render={(props) => <EditCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addCollectionOfCollection" render={(props) => <AddCollectionOfCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listCollectionOfCollection" render={(props) => <ListCollectionOfCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editCollectionOfCollection/:id" render={(props) => <EditCollectionOfCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addPolls" render={(props) => <CreatePolls apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listPolls" render={(props) => <ListPolls apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editPolls/:id" render={(props) => <EditPolls apiConfig={apiConfig} {...props} />}></Route>

            <Route path="/addOfflineEvent" render={(props) => <CreateOfflineEvent apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listOfflineEvent" render={(props) => <ListOfflineEvents apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editOfflineEvent/:id" render={(props) => <EditOfflineEvent apiConfig={apiConfig} {...props} />}></Route>

            <Route path="/addEvent" render={(props) => <CreateEvent apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listEvent" render={(props) => <ListEvents apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editEvent/:id" render={(props) => <EditEvent apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addEventCollection" render={(props) => <AddEventCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listEventCollection" render={(props) => <ListEventsCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editEventCollection/:id" render={(props) => <EditEventCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addBanner" render={(props) => <CreateBanner apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listBanner" render={(props) => <ListBanner apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editBanner/:id" render={(props) => <EditBanners apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addBannerCollection" render={(props) => <AddBannerCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listBannerCollection" render={(props) => <ListBannerCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/EditBannerCollection/:id" render={(props) => <EditBannerCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/addBannerCollectionOfCollection" render={(props) => <AddBannerCollectionOfCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listBannerCollectionOfCollection" render={(props) => <ListBannerCollectionOfCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editBannerCollectionOfCollection/:id" render={(props) => <EditBannerCollectionOfCollection apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/listTracker" render={(props) => <ListTracker apiConfig={apiConfig} {...props} />}></Route>

            <Route path="/addUser" render={(props) => <CreateUser apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editUser/:id" render ={(props)=><EditUser apiConfig={apiConfig} {...props}/>}></Route>
            <Route path="/listUser" render={(props) => <ListUser apiConfig={apiConfig} {...props} />}></Route>

            <Route path="/uploadFile" render={(props) => <UploadFile apiConfig={apiConfig} {...props} />}></Route>
            <Route path="/editKeyword/:id" render ={(props)=><Editkeyword apiConfig={apiConfig} {...props}/>}></Route>
            <Route path="/listKeyword" render={(props) => <ListKeyword apiConfig={apiConfig} {...props} />}></Route>

          </Switch>
        </main>
      </div>

    )
  })

  return (
    <Provider store={Store}>
      <StylesProvider injectFirst>
        <BrowserRouter>
          <MainPage></MainPage>


        </BrowserRouter>
        <CssBaseline></CssBaseline>
      </StylesProvider>
    </Provider>
  );
}

export default App;
