import React, { Fragment, useState, useEffect} from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import MultiLanguageModal from '../Reusable/multiLanguageModal';

export default function EditCategory(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const [deleteThumbnail, setDeleteThumbnail] = useState([]);
    const [detailPageImage, setDetailPageImage] = useState("");
    const [deleteDetailPageImage, setDeleteDetailPageImage] = useState([]);

    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);
    
    const listVarient = ["v1", "v2", "v3", "v4", "v5", "v6"];

    const fetchCategoryById = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
          .then(res => {
            setName(res.data.data[0].name)
            setDisplayName(res.data.data[0].displayText)
            let name = JSON.parse(res.data.data[0].displayName)
            setEnglishLang(name.en ? name.en : '')
            setHindiLang(name.hi ? name.hi  : '')
            setMarathiiLang(name.mr ? name.mr  : '')
            setGujaratiLang(name.gu ? name.gu  : '')
            setKannadaLang(name.kn ? name.kn  : '')
            setTeluguLang(name.te ? name.te  : '')
            setTamilLang(name.ta ? name.ta  : '')
            setPunjabiLang(name.pa ? name.pa  : '')
            setMalayalamLang(name.ml ? name.ml : '');
                setOdisaLang(name.or ? name.or : '');
                setUrduLang(name.ur ? name.ur :'' );
                setBengaliLang(name.bn ? name.bn :'' );
                setKhmerLang(name.khm ? name.khm : '')
            setThumbnail(res.data.data[0].image)
            setDetailPageImage(res.data.data[0].detailImage)
          }).catch(err => {
            console.log(err)
          })
      }
    
      useEffect(()=>{
        fetchCategoryById()
      },[])

    const thumbnaillHandle = (e) => {
        setThumbnail([...thumbnail,...e.target.files])
    }
    const detailpagellHandle = (e) => {
        setDetailPageImage([...detailPageImage,...e.target.files])
    }

    const postGenre = async (data) => {
        const fd = new FormData();
        fd.append("name", data.name);
        fd.append("displayName", JSON.stringify(data.displayName));
        fd.append("parentId", data.parentId);
        fd.append("storeId",data.storeId);
        data.deleteImage.map((ele,i) => {
            fd.append(`deleteImage[${i}]`, ele)
        })
        data.deleteDetailImage.map((ele,i) => {
            fd.append(`deleteDetailImage[${i}]`, ele)
        })
        for (let index = 0; index < data.image.length; index++) {
            const element = data.image[index];
            if (typeof (element) !== 'string') {
                fd.append('image',element)
            }
        }
        for (let index = 0; index < data.detailImage.length; index++) {
            const element = data.detailImage[index];
            if (typeof (element) !== 'string') {
                fd.append('detailImage',element)
            }    
        }
        await axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category/${props.match.params.id}/detail`, fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                cogoToast.success("success");
                props.history.push('/listCategory')
            }).catch(err => {
                console.log(err.response)
                cogoToast.error("Somthing went wrong!");
            })
    }

    const onSubmit = () => {
        const obj = {
            "name": name,
            "displayName":{
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            },
            "image": thumbnail,
            "detailImage":detailPageImage,
            "parentId":-1,
            "deleteImage":deleteThumbnail,
            "deleteDetailImage":deleteDetailPageImage,
            "storeId":localStorage.getItem("storeId")
        }
        postGenre(obj)
        // console.log(obj)
    }

    const onClear = () => {
        setThumbnail([])
        setDetailPageImage([])
    }

    const deletethumbnail = (e,i, ele) => {
        let newArray = thumbnail.filter((ele, index)=>{
            return index !== i;
        })
        setDeleteThumbnail([...deleteThumbnail,ele])
        console.log()
        setThumbnail(newArray)
    }

    const deletedetailpageImage = (e,i,ele) => {
        let newArray = detailPageImage.filter((ele, index)=>{
            return index !== i;
        })
        setDeleteDetailPageImage([...deleteDetailPageImage,ele])
        setDetailPageImage(newArray)
    }

    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Edit Categories</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Name" name="Name" value={name} onChange={(e) => { setName(e.target.value) }} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Display Name" name="DisplayName" value={englishLang} onChange={(e) => { setEnglishLang(e.target.value) }} ></TextField>
                            <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Thumbnail
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={thumbnaillHandle}
                                />
                            </Button>
                            {thumbnail.length>0 ?
                            (thumbnail.map((ele,i)=> (<div><p className="mt-2 ml-4" style={{cursor:"pointer",display:"inline"}} onClick={()=>window.open(ele.name?null:`https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{cursor:"pointer"}} onClick={(e) => deletethumbnail(e, i,ele)} /></div>))) 
                            : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Detail Page Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={detailpagellHandle}
                                />
                            </Button>
                            {detailPageImage.length>0 ?
                            (detailPageImage.map((ele,i)=>(<div><p className="mt-2 ml-4" style={{cursor:"pointer",display:"inline"}} onClick={()=>window.open(ele.name?null:`https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{cursor:"pointer"}} onClick={(e) => deletedetailpageImage(e, i,ele)} /></div>))) 
                            : null}
                        </Grid>

                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                </div>
            </Paper>


        </Fragment>
    )
}
