import React, { Fragment, useState, useEffect } from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Select,
    InputLabel,
    MenuItem,
    Checkbox,
    TextareaAutosize

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import { CompactPicker,SketchPicker } from 'react-color';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';


export default function EditPolls(props) {
    const classes = useStyles();
    const [topic, setTopic] = useState("")
    const [name, setName] = useState("")
    const [varient, setVarient] = useState("")
    const [fields, setFields] = useState([]);

    const getPollsById = () =>{
        axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/poll/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`).then(res=>{
            setName(res.data.data[0].name)
            setTopic(res.data.data[0].topic);
            setVarient(res.data.data[0].varient)
            setFields(res.data.data[0].options)
        })
    }

    useEffect(()=>{
        getPollsById();
    },[])

    const listVarient = ["v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8", "v9", "v10"];

    function handleChange(event,i,type) {
        const values = [...fields];
        if(type==="option"){
         values[i].option = event.target.value;
        }else if(type==="bgColor"){
            values[i].Bgcolor = event.hex;

        }else if(type==="textColor"){
            values[i].textColor = event.hex;

        }else if(type==="url"){
            values[i].url = event.target.value;

        }else if(type==="external"){
            values[i].external = event.target.checked;

        }
        
        setFields(values);
      }

    function handleAdd() {
        const values = [...fields];
        values.push({
            option: "",
            Bgcolor: '#ffffff',
            textColor: '#000000',
            url: "",
            external: false,
        });
        setFields(values);
    }

    function handleRemove(e,i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
      }

    const postPoll = async (data) =>{

        await axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/poll/${props.match.params.id}`, data)
            .then(res => {
                props.history.push('/listPolls')
                cogoToast.success(res.data.message);
            }).catch(err => {
                cogoToast.error(err.response.data.message)
            })
    }

    const onSubmit = () =>{
        let obj = {
            name:name,
            topic:topic,
            varient:varient,
            options:fields,
            storeId:localStorage.getItem("storeId")
        }
        postPoll(obj)
    }

    const onClear = () =>{
        setFields([]);
        setTopic("")
        setVarient("")
        setName("")
    }

    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div>
                    <h1 className="text-center">Edit Polls</h1>
                    <Grid container>
                    <Grid item md={8}>
                            <TextField style={{ minWidth: "30%" }} variant="outlined" className={classes.inputField} width="25" required label="name" value={name} onChange={(e) => setName(e.target.value)}>
                            </TextField>

                        </Grid>
                        <Grid item md={8}>
                            <TextField style={{ minWidth: "90%" }} variant="outlined" className={classes.inputField} width="25" required label="Topic" value={topic} onChange={(e) => setTopic(e.target.value)}>
                            </TextField>

                        </Grid>
                        <Grid item md={2}>
                            <FormControl style={{ minWidth: "90%", marginLeft:"-50px" }} variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Varient Name</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={varient}
                                    onChange={(e) => setVarient(e.target.value)}
                                    label="Varient Name"
                                >
                                    {
                                        listVarient.map((element, id) => {

                                            return (
                                                <MenuItem key={id} value={element}>{element}</MenuItem>
                                            )
                                        })

                                    }

                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid md={2}>
                            <Button style={{marginLeft:"-50px" }} variant="contained" className="mt-3" color="primary" onClick={handleAdd}>ADD Option</Button>
                        </Grid>

                    </Grid>
                    {fields.map((field, idx) => {
                        return (
                            <Grid key={`${field}-${idx}`} container className="mt-3">
                                <Grid item>
                                    <TextField  style={{ minWidth: "94%" }} variant="outlined" className={classes.inputField} width="25" required label="Option" value={fields[idx].option} onChange={(e)=>handleChange(e,idx, "option")}>
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    {/* <TextField variant="outlined" className={classes.inputField} width="25" required label="bgcolor">
                                    </TextField> */}
                                    <h6>Background Color</h6>
                                    <CompactPicker
                                     color={fields[idx].Bgcolor}
                                     onChange={(e)=>handleChange(e,idx, "bgColor")}
                                     />
                                </Grid>
                                <Grid item style={{paddingLeft:"10px",paddingRight:"10px"}}>
                                    <h6>Text Color</h6>
                                    <CompactPicker 
                                    color={fields[idx].textColor}
                                    onChange={(color)=>handleChange(color,idx, "textColor")}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField style={{ minWidth: "90%" }} variant="outlined" className={classes.inputField} width="25" required label="URL" value={fields[idx].url} onChange={(e)=>handleChange(e,idx, "url")}>
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        className="mt-3"
                                        control={
                                            <Checkbox

                                                checked={fields[idx].external}
                                                onChange={(e)=>handleChange(e,idx, "external")}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="External"
                                    />
                                </Grid>
                                <Grid item>
                                    <CancelIcon className="mt-4" onClick={(e)=>handleRemove(e,idx)}/>

                                </Grid>
                            </Grid>
                        );
                    })}
                    <Grid container>
                        <Grid item>
                        <Button variant="contained" className="mt-3" color="primary" onClick={onSubmit}>Update</Button>
                        <Button  variant="contained" className="mt-3 ml-2" color="primary" onClick={onClear}>Clear</Button>
                            

                        </Grid>
                    </Grid>
                </div>
            </Paper>

        </Fragment>
    )
}
