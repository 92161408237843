import React, { Fragment, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';


export default function CreateLanguage(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [code, setCode] = useState("");


    const postLanguage = async (data) => {
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, data)
            .then(res => {
                cogoToast.success(res.data.result.message);
            }).catch(err => {
                cogoToast.error(err.response.data.message);
            })
    }

    const onSubmit = () => {
        const obj = {
            "type": "language",
            "name": name,
            "code": code,
            "storeId":localStorage.getItem("storeId")
        }
        postLanguage(obj)
        setName("")
        setCode("")
        // console.log(obj)
    }

    const onClear = () => {
        setName("")
        setCode("")
    }

    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add Language</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Name" name="Name" value={name} onChange={(e) => { setName(e.target.value) }} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Code" name="Code" value={code} onChange={(e) => { setCode(e.target.value) }} ></TextField>
                        </Grid>


                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
