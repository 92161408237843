import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Button,
    Divider,
    Paper
} from '@material-ui/core'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cogoToast from 'cogo-toast';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export default function LinkAppWithPage(props) {
    const classes = useStyle();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [appName, setAppName] = useState([]);


    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const fetchPage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/page?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    setRight(res.data.result.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const fetchAppName = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    setAppName(res.data.result.result[0].title)
                }
            ).catch(
                err => {
                    console.log(err)
                }
            )

    }

    const fetchPageLinkWithApp = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/mapping/data/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    console.log(res)
                    // let object = res.data.mappingContent.filter(ele => {
                    //     ele.title = JSON.parse(ele.title)
                    //     return ele
                    // })
                    // console.log(object)
                    setLeft(res.data.mappingContent)
                }
            ).catch(
                err => {
                    console.log(err)
                }
            )
    }

    useEffect(() => {
        fetchPageLinkWithApp();
        fetchPage();
        fetchAppName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const PostMapping = (data) => {
        axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/mapping`, data)
            .then(res => {
                console.log(res)
                cogoToast.success(res.data.message)
            }).catch(err => {
                console.log(err)
                cogoToast.error("something went wrong!")
            })
    }

    const onSubmit = () => {
        const PagesLinkApp = left.map((ele, i) => {
            let obj = {
                id: ele._id,
                title: ele.title,
                sequence: i
            }
            return obj;
        })
        const objects = {
            id: props.match.params.id,
            items: PagesLinkApp,
            storeId:localStorage.getItem("storeId")
        }
        console.log(objects)
        // PostMapping(objects);
    }

    // useEffect(() => {
    //     if (reload === true) {
    //         fetchPage();

    //     }

    // }, [reload])




    const pageList = (title, items) => (
        (items && items.length > 0 ?
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                            disabled={items.length === 0}
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selected`}
                />
                <Divider />

                <List className={classes.list} dense component="div" role="list">
                    {items.map((value) => {
                        const labelId = `${value._id}`;

                        return (
                            <ListItem key={value._id} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.title.en} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Card> :
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader="no data"
                />
                <Divider />

                <List className={classes.list} dense component="div" role="list">
                    <p className="text-center">no data</p>
                    <ListItem />
                </List>
            </Card>)

    );

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(left);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setLeft(items)


    }

    const appList = (title, items) => (
        <Card >
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value._id}`;

                                return (
                                    <Draggable key={value._id} draggableId={value._id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.title.en} />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );

    return (
        <Fragment>
            <Paper spacing={3}>
                <Link to="/listApp"><p style={{ paddingTop: "30px", paddingLeft: "30px" }}>Back to App List</p></Link>

                <h1 style={{ paddingBottom: "30px", paddingLeft: "30px" }}>{appName}</h1>

            </Paper>
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>

                <Grid item>
                    <h4>Apps</h4>
                    {appList('Choices', left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item><h4>Pages</h4>{pageList('Chosen', right)}


                </Grid>
                <Grid item md={12} style={{ marginLeft: "170px" }}>
                    <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>

                </Grid>
            </Grid>

        </Fragment>
    );
}
