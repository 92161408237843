import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function DeleteModal(props) { 
    const onDeleteButton = () => {
        props.deleteContent();
        props.toggle()
    }
    return (
        <div>
            <Modal isOpen={props.deleteModal} toggle={props.toggle} style={{marginTop:"100px"}}>
                {/* <ModalHeader toggle={props.toggle}>Modal title</ModalHeader> */}
                <ModalBody>
                    Are you sure you want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onDeleteButton}>Delete</Button>{' '}
                    <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
