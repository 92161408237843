import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    makeStyles,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputBase,
    Tooltip,
    IconButton

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import {RefreshOutlined} from '@material-ui/icons';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));


export default function AddEventCollection(props) {
    const classes1 = useStyles();
    const classes = useStyle();
    const [name, setName] = useState("");
    const [checked, setChecked] = useState([]);
    const [selected, setselected] = React.useState([]);
    const [stories, setStories] = React.useState([]);
    const [searched, setSearched] = useState("")
    const [fixed, setFixed] = useState(false)


    const fetchEvent = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/event/typeCode/events?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    let data =[]
                    for(let i in res.data.data){
                        if(res.data.data[i].product == 'UN'){
                            data.push(res.data.data[i])
                            }
                    }
                            setStories(data)
                    
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const searchStory = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/event/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    let data =[]
                    for(let i in res.data.result){
                        if(res.data.result[i].product == 'UN'){
                            data.push(res.data.result[i])
                            }
                    }

                    setStories(data)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        fetchEvent()
    }, [])

    const leftChecked = intersection(checked, selected);
    const rightChecked = intersection(checked, stories);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setStories(stories.concat(leftChecked));
        setselected(not(selected, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setselected(selected.concat(rightChecked));
        setStories(not(stories, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(selected);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setselected(items)
    }


    const searchContent = (e) => {
        if(e.key === "Enter")searchStory(searched)
    }

    const deleteLeftData =() =>{
        setselected(not(selected, leftChecked));
        setChecked(not(checked, leftChecked));
    }

    const refreshList = () =>{
        fetchEvent()
    }

    const storyList = (title, items) => (

        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }

                action={
                    <Tooltip title="Refresh">
                    <IconButton aria-label="delete" onClick={refreshList}>
                        <RefreshOutlined />
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <div className={classes1.contentSearch}>
                <div className={classes1.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes1.inputRoot,
                        input: classes1.inputInput,
                    }}
                    value={searched}
                    onChange={(e) => setSearched(e.target.value)}
                    onKeyDown={(e) => searchContent(e)}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>
            <List className={classes.list} dense component="div" role="list" >
                {items.map((value, index) => {
                    const labelId = `${value._id}`;

                    return (
                        <ListItem role="listitem" button onClick={handleToggle(value)} >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} >{value.name[0].en ? value.name[0].en : value.name ? value.name : null}</ListItemText>

                        </ListItem>

                    );
                })}
            </List>
        </Card>
    );

    const selectedStoryList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="delete">
                    <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                        <DeleteForeverOutlinedIcon className={leftChecked.length === 0?null:classes1.btndelete}/>
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value._id}`;

                                return (
                                    <Draggable isDragDisabled={value.fixed} key={value._id} draggableId={value._id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.name[0].en ? value.name[0].en : value.name ? value.name : null} />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );

    const postCategoryBucket = async (data) => {
        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/eventCollection`, data).then(res => {
            cogoToast.success("Success")
        }).catch(err => {
            console.log(err)
            cogoToast.error("Something Went Wrong!")
        })
    }

    const onSubmit = () => {
        let categoryItems = selected.map((ele, i) => {
            let obj = {
                "sequence": i,
                "event": ele._id
            }
            return obj
        })

        let object = {
            type:"eventCollection",
            name: name,
            detail: categoryItems,
            "storeId":localStorage.getItem("storeId")
        }
        // console.log(object)
        postCategoryBucket(object)
        setselected([])
        setName("")
        fetchEvent()
    }


    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add Event Collection</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes1.inputField} width="25" required label="Name" name="Name" value={name} onChange={(e) => { setName(e.target.value) }} ></TextField>
                        </Grid>


                    </Grid>

                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        <Grid item>
                            <h4>Selected</h4>
                            {selectedStoryList('Choices', selected)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item><h4>Event</h4>{storyList('Chosen', stories)}


                        </Grid>

                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" >Clear</Button>

                        </Grid>

                    </Grid>
                </div>

            </Paper>


        </Fragment>
    )
}
