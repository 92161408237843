import React, { Fragment, useState,useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    MenuProps

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import {content_type} from './../bucket/objects'
import StoreModal from './../Reusable/stores.json';



export default function CreateVarient(props) {
    const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
    const classes = useStyles();
    const [v10, setv10] = useState("");
    const [contents, setContents] = useState("true");
    const [contentId, setContentId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [lang, setLang] = useState([])
    const listVarient = ["v0","v1", "v2", "v3", "v4", "v5", "v6"];
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])

    const initialvalues = {
        contentId: "",
    }
    const [state, setState] = useState(initialvalues)

    useEffect(() => {
        fetchDataById();
    }, [])

    const fetchDataById = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/user/get/${props.match.params.id}`)
            .then(res => {
                console.log('thumb',res.data.result[0].name)
                setContentId(res.data.result[0].name)
                setDisplayName(res.data.result[0].username)
                setv10(res.data.result[0].password)
                setContents(String(res.data.result[0].adminUser))   
                setLang(res.data.result[0].storeId)
            }).catch(err => {
                console.log(err)
            })
    }

    const onRadioHandleChange = (e) => {
        setContents(e.target.value)
    }
    const onHandleChange = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setContentId(e.target.value)
    }
    const onHandleChange2 = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setv10(e.target.value)
    }
    const onHandleChange3 = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setDisplayName(e.target.value)
    }

    const postVariant = async () => {
        // "password":v10,
       let object ={
        "name":contentId,
        "username":displayName,
        "adminUser":contents,
        "storeId":lang,
        "updatedBy":localStorage.getItem('userId')
       }
       console.log('===',object);
        await axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/user/update/${props.match.params.id}`, object)
            .then(res => {
                if(res.data.result.message == "Content Id Exists"){
                    cogoToast.error(res.data.result.message);
                   }
                   else{
                    cogoToast.success(res.data.result.message);
                   }
            }).catch(err => {
                cogoToast.error(err.response.data.message)
            })
    }


    const onSubmit = () => {
        console.log('langg',lang);
        postVariant();
    }

    const onClear = () => {
        setv10("")
        setContentId("")
        setDisplayName("")
        setContents("")
        setLang([])
    }

    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Edit User</h1>
                    <Grid container >
                  
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Name" name="contentId" value={contentId} onChange={onHandleChange}></TextField>
                        </Grid>
                        <Grid item md={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="User Name" value={displayName} onChange={onHandleChange3}>
                            </TextField>
                        </Grid>
                        {/* <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Password" name="contentId" value={v10} onChange={onHandleChange2}></TextField>
                        </Grid> */}
                        <Grid item md={6} sm={12}>
                <FormControl className={classes.formControl}>
                    <FormLabel className={classes.lables} component="div" >Admin</FormLabel>
                    <RadioGroup aria-label="enable" name="enable" value={contents} onChange={onRadioHandleChange} row>

                        <FormControlLabel value="true" control={<Radio />} label="true" />
                        <FormControlLabel value="false" control={<Radio />} label="false" />

                    </RadioGroup>
                </FormControl>

            </Grid>
                        <Grid item md={6} sm={12}>
                <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">storeId</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="select-currency"
                        value={lang}
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        onChange={(e) => setLang(e.target.value)}
                        label="Language"
                    >
                        {
                            StoreModal.length > 0 ?
                            StoreModal.map((element, id) => {

                                    return (
                                        <MenuItem type="checkbox" key={id} value={element.storeId}>
                                            <Checkbox
                                                checked={lang.includes(element.storeId)}
                                                onChange={e => { }}
                                                value=""
                                            />{element.description}&nbsp;<img src={element.image} />
                                        </MenuItem>
                                        )
                                }) :
                                <MenuItem disabled value="no Lamguage">No Stores</MenuItem>

                        }

                    </Select>

                </FormControl>

            </Grid>
                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
