const initialState = {
    nav:{},
    error_message:null,
    success_message:null,
}

const navReducer=(state=initialState, action)=>{
    switch(action.type){
        case "ON_ADDNAV_SUCCESS":
            return{
                ...state,
                nav:action.payload,
            }
        case "ON_ADDNAV_FAILURE":
            return{
                ...state,
                error_message:action.payload,
            }    
        default:
            return{
                ...state
            }    
    }
}

export default navReducer;