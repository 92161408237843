import React, { Fragment, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemToggle,
    ListItemText,
    Checkbox,
    ListItemIcon,
    makeStyles,
    InputBase

} from '@material-ui/core';
import { useStyles } from '../../../styles/style';
import Icon from '@material-ui/core/Icon';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import axios from 'axios';
import NoImage from './../../../images/no_image.png'
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import cogoToast from 'cogo-toast';



const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        // width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export default function AddCharts(props) {
    const classes = useStyles();
    const classes1 = useStyle();
    const datetime = new Date()
    const [startDate, setStartDate] = React.useState(new Date());
    const [fromDate, setFromDate] = React.useState(datetime.getFullYear() + '-' +  ("0" + (datetime.getMonth() + 1)).slice(-2)  + '-' +  ("0"+(datetime.getDate())).slice(-2));
    const [endDate, setEndDate] = React.useState(datetime.getFullYear() + '-' +  ("0" + (datetime.getMonth() + 1)).slice(-2)  + '-' +  ("0"+(datetime.getDate())).slice(-2));
    const [toDate, setToDate] = React.useState(new Date());
    const [playlistName, setPlaylistName] = useState("")
    const [items, setItems] = useState([]);
    const [searched, setSearched] = useState("")
    const [stencil, setStencil] = useState("");
    const [background, setBackground] = useState("");
    const [title, setTitle] = useState("")


    const fetchPlaylist = () => {
        axios.get(`${process.env.REACT_APP_PAGE_IP}/v2/page/content/${playlistName}/playlist/detail?storeId=${localStorage.getItem("storeId")}`).then(
            res => {
                console.log(res.data.data)
                setTitle(res.data.data.head.data.title)
                setItems(res.data.data.body.rows)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    const deleteStencil = (e, i) => {
        let newArray = stencil.filter((ele, index) => {
            return index !== i;
        })
    
        setStencil(newArray)
    }
    
    const deleteBackground = (e, i) => {
        let newArray = background.filter((ele, index) => {
            return index !== i;
        })
    
        setBackground(newArray)
    }

    const postApi = async (data) => {
        const fd = new FormData();
        fd.append("playlistId", data.playlistId);
        fd.append("fromDate", data.fromDate);
        fd.append("toDate", data.toDate);
        fd.append("storeId", data.storeId);
        for (let index = 0; index < data.stencil.length; index++) {
            const element = data.stencil[index];
            fd.append('stencil', element)
        }
        for (let index = 0; index < data.thumbnail.length; index++) {
            const element = data.thumbnail[index];
            fd.append('thumbnail', element)
        }
        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/chart`, fd)
            .then(
                res => {
                    cogoToast.success("success")
                }
            ).catch(err => {
                cogoToast.error(err.response.data)
            })
    
    }

    const onSubmit = () => {
        let obj = {
            playlistId:playlistName,
            fromDate:fromDate,
            toDate:endDate,
            stencil:stencil,
            thumbnail:background,
            "storeId":localStorage.getItem("storeId")
        }
console.log('obj',obj);
        postApi(obj)
        setPlaylistName("")
        setStencil("")
        setBackground("")
        setItems([])

    }

    const onClear = () =>{
        setPlaylistName("")
        setStencil("")
        setBackground("")
        setItems([])
    }

    const onChangeStartDate = (date) =>{
        var finaldate = date.getFullYear() + '-' +  ("0" + (date.getMonth() + 1)).slice(-2)  + '-' +  ("0"+(date.getDate())).slice(-2)
        setFromDate(finaldate)
        setStartDate(date)
    }
    
    const onChangeendtDate = (date) =>{
        var finaldate = date.getFullYear() + '-' +  ("0" + (date.getMonth() + 1)).slice(-2)  + '-' +  ("0"+(date.getDate())).slice(-2)
        setToDate(date)
        setEndDate(finaldate)
    }

    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div className="text-center">
                    <h1 >Generate Charts</h1>
                    <Grid container style={{ marginLeft: "20px" }}>
                        <Grid item md={12} sm={12}>
                            <TextField style={{ width: "60%" }} variant="outlined" className={classes.inputField} width="25" required label="Playlist" value={playlistName} onChange={(e) => setPlaylistName(e.target.value)}>
                            </TextField>

                            <Button style={{ height: "55px" }} className="mt-2" size="large" variant="contained" color="primary" endIcon={<Icon>send</Icon>} onClick={fetchPlaylist}>Fetch</Button>
                        </Grid>


                    </Grid>

                    <Grid container>
                        <Grid item md={2}>

                        </Grid>
                        <Grid item md={8} >
                            <div className="text-center" style={{ maxWidth: "890px", justifyContent: "center" }}>
                                {items && items.length > 0 ?
                                    <Card >
                                        <CardHeader
                                            className={classes1.cardHeader}

                                            title={title?title:"Playlist"}
                                        // subheader={`${numberOfChecked(items)}/${items.length} selected`}
                                        />

                                        <Divider />
                                        <div className={classes.contentSearch}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase
                                                placeholder="Search…"
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                value={searched}
                                                onChange={(e) => setSearched(e.target.value)}
                                                inputProps={{ 'aria-label': 'search' }}
                                            />
                                        </div>



                                        <List className={classes1.list} dense component="div" role="list">
                                            {
                                                items
                                                    .filter(val=>{
                                                        if(searched===""){
                                                            return val
                                                        }else if(val.data.title.toLowerCase().includes(searched.toLowerCase())){
                                                            return val
                                                          }
                                                    })
                                                    .map((value) => {
                                                        const labelId = `${value.data?value.data.id:null}`;

                                                        return (
                                                            <ListItem key={value._id} role="listitem" button
                                                            // onClick={handleToggle(value)}
                                                            >
                                                                <ListItemIcon>
                                                                    <img src={value.data ? value.data.image : NoImage} height="50" width="50" />
                                                                </ListItemIcon>
                                                                {/* <img src={value.image ? value.image : NoImage} height="50" width="50" /> */}


                                                                <ListItemText id={labelId} primary={value.data?value.data.title:"no data"} />
                                                            </ListItem>
                                                        );
                                                    })}
                                            <ListItem />
                                        </List>
                                    </Card> :
                                    <Card className="text-center" style={{ width: "39% !important" }}>
                                        <CardHeader
                                            className={classes.cardHeader}
                                            avatar={
                                                <Checkbox
                                                    inputProps={{ 'aria-label': 'all items selected' }}
                                                />
                                            }
                                            title={`Playlist`}
                                        />
                                        <Divider />

                                        <List className={classes.list} dense component="div" role="list">
                                            <p className="text-center">no data</p>
                                            <ListItem />
                                        </List>
                                    </Card>}
                            </div>
                        </Grid>
                        <Grid item md={2}>

                        </Grid>
                        <Grid item md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker format="MM/dd/yyyy" label="Start Date" className={classes.inputField} value={startDate} onChange={e => onChangeStartDate(e)} ></KeyboardDatePicker>
                                {/* <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!expiryDate}
                                    onClick={() => setExpiryDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton> */}

                            </MuiPickersUtilsProvider>


                        </Grid>
                        <Grid item md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker format="MM/dd/yyyy" label="End Date" className={classes.inputField} value={toDate} onChange={e => onChangeendtDate(e)} ></KeyboardDatePicker>
                                {/* <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!expiryDate}
                                    onClick={() => setExpiryDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton> */}

                            </MuiPickersUtilsProvider>


                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Stencil
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setStencil([...stencil,...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Thumbnails
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setBackground([...background,...e.target.files])}
                                />
                            </Button>
                            {background.length > 0 ?
                                (background.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={12} className="mt-4">
                            <Button className="mr-2" variant="contained" color="primary" onClick={onSubmit}>Create Chart</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>

                    </Grid>
                </div>

            </Paper>
        </Fragment>
    )
}
