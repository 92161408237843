import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    List,
    Card,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Button,
    Divider,
    Paper,
    Box,
    Typography,
    Tab,
    Tabs,
    AppBar,
    InputBase,
    TextField,
    IconButton,
    Tooltip,
    CircularProgress
} from '@material-ui/core'
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cogoToast from 'cogo-toast';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './../../styles/style';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { RefreshOutlined } from '@material-ui/icons';
// import country from '../Reusable/countries.json';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const staticValue = [
    { _id: "Welcome User + Continue Watching", name: "Welcome User + Continue Watching", typeName: "static", displayName: "" ,country : ['ALL']},
    { _id: "Continue Watching", name: "Continue Watching", typeName: "static", displayName: "",country : ['ALL'] },
    { _id: "Mood Radio", name: "Mood Radio", typeName: "static", displayName: "",country : ['ALL'] },
    { _id: "Playlists from Friends", name: "Playlists from Friends", typeName: "static", displayName: "",country : ['ALL'] },
    { _id: "Daily Dose", name: "Daily Dose", typeName: "static", displayName: "" ,country : ['ALL']},
    { _id: "Rewind 2022", name: "Rewind 2022", typeName: "static", displayName: "" ,country : ['ALL']},
    { _id: "Popular Playlists", name: "Popular Playlists", typeName: "static", displayName: "",country : ['ALL'] },
    { _id: "Buzzing Right Now", name: "Buzzing Right Now", typeName: "static", displayName: "" ,country : ['ALL']},
]

export default function LinkPageWithBucket(props) {
    const [country , setCountry] = useState([])
    const [countryData, setCountryData] = useState([])
    const classes = useStyle();
    const clasesJs = useStyles()
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [pageName, setPageName] = useState([]);
    // const [reload, setReload] = useState(false)
    const [startFetch, setStartFetch] = useState(false)
    const [value, setValue] = useState(0)
    const [storeId, setStoreId] = useState("")
    const navigate = useHistory();
    const [device, setDevice] = useState([])
    const [contentRights, setContentRights] = useState([{ id: "android", title: "android" }, { id: "ios", title: "ios" }, { id: "web", title: "web" }, { id: "tv", title: "tv" }, { id: "oppo", title: "oppo" }])
    // const [stories, setStories] = useState(false)
    // const [bucket, setbucket] = useState(false)
    // const [events, setEvents] = useState(false)
    // const [collections, setCollections] = useState(false)
    // const [categories, setCategories] = useState(false)
    const [searched, setSearched] = useState("")
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [isLoading, setIsLoading] = useState(false);
    // const [displayNames, setDisplayNames] = useState("")



    const tabhandleChange = (event, newValue) => {
        setRight([])
        setValue(newValue);
        setIsLoading(true)
        setSearched("")
        if (newValue === 0) {
            fetchTopNavCollection()
        }
        else if (newValue === 1) {
            fetchStatic()
        }
        else if (newValue === 2) {
            fetchBucket()
        }
        else if (newValue === 3) {
            fetchEventBucket()
        }
        else if (newValue === 4) {
            fetchBucketGroup()
        }
        else if (newValue === 5) {
            fetchStory();
        }
        else if (newValue === 6) {
            fetchCollection();
        }
        else if (newValue === 7) {
            fetchEvents();
        }
        else if (newValue === 8) {
            fetchBanner();
        }
        else if (newValue === 9) {
            fetchCategories();
        }
        else if (newValue === 10) {
            fetchBannerList();
        }
    };

    const fetchStatic = () => {
        let object = staticValue
        let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))

        setRight(filterObj)
        setIsLoading(false)
    }

    const fetchBucket = async () => {
        let data = localStorage.getItem("storeId");

        if (data) {
            if (localStorage.getItem("userRole") == "SuperAdmin") {
                await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/game/forbucket?storeId=${localStorage.getItem("storeId")}`)
                    .then(
                        res => {
                            let object = res.data.result.result.filter(ele => {
                                if (typeof (ele.names) === "string") {
                                    console.log('name==>', ele.names);
                                    ele.names = JSON.parse(ele.names)
                                    return ele
                                }
                                return ele

                            })
                            let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                            filterObj = filterObj.map(ele => {
                                ele.typeName = "bucket"

                                return ele

                            })
                            filterObj = filterObj.map(ele => {
                                ele.displayName = ""
                                ele.country = ['ALL']
                                return ele

                            })

                            setRight(filterObj)
                            setIsLoading(false)

                        }
                    ).catch(
                        err => {
                            console.log(err);
                        }
                    )
            }
            else {
                await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/bucket?storeId=${localStorage.getItem("storeId")}`)
                    .then(
                        res => {
                            let object = res.data.result.result.filter(ele => {
                                if (typeof (ele.names) === "string") {
                                    console.log('name==>', ele.names);
                                    ele.names = JSON.parse(ele.names)
                                    return ele
                                }
                                return ele

                            })
                            let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                            filterObj = filterObj.map(ele => {
                                ele.typeName = "bucket"

                                return ele

                            })
                            filterObj = filterObj.map(ele => {
                                ele.displayName = ""
                                ele.country = ['ALL']
                                return ele

                            })

                            setRight(filterObj)
                            setIsLoading(false)

                        }
                    ).catch(
                        err => {
                            console.log(err);
                        }
                    )
            }
        }
        else {
            navigate.push('/login');
        }
    }

    const fetchEventBucket = async () => {
        let data = localStorage.getItem("storeId");

        if (data) {
            await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/eventBucket?storeId=${localStorage.getItem("storeId")}`)
                .then(
                    res => {
                        let object = res.data.result.result.filter(ele => {
                            if (typeof (ele.names) === "string") {
                                console.log('name==>', ele.names);
                                ele.names = JSON.parse(ele.names)
                                return ele
                            }
                            return ele

                        })
                        let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                        filterObj = filterObj.map(ele => {
                            ele.typeName = "eventBucket"

                            return ele

                        })
                        filterObj = filterObj.map(ele => {
                            ele.displayName = ""
                            ele.country = ['ALL']
                            return ele

                        })

                        setRight(filterObj)
                        setIsLoading(false)

                    }
                ).catch(
                    err => {
                        console.log(err);
                    }
                )

        }
        else {
            navigate.push('/login');
        }
    }

    const fetchBucketGroup = async () => {
        let data = localStorage.getItem("storeId");

        if (data) {
            await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/bucketGroup?storeId=${localStorage.getItem("storeId")}`)
                .then(res => {
                    let object = res.data.result.result.filter(ele => {
                        ele.bucketGroupName = JSON.parse(ele.bucketGroupName)
                        return ele
                    })
                    console.log(object);
                    let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "bucketGroup"

                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })

                    setRight(filterObj)
                    setIsLoading(false)

                }).catch(err => {
                    console.log(err)
                })
        }
        else {
            navigate.push('/login');
        }
    }
    const fetchTopNavCollection = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/topNavCollection?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {
                let object = res.data.result.result
                let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                filterObj = filterObj.map(ele => {
                    ele.typeName = "topNav"

                    return ele

                })
                filterObj = filterObj.map(ele => {
                    ele.displayName = ""
                    ele.country = ['ALL']
                    return ele

                })
                console.log(filterObj)
                setRight(filterObj)
                setIsLoading(false)

            }).catch(err => {
                console.log(err)
            })
    }
    const fetchEvents = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/eventCollection?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "event"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)
                    setIsLoading(false)

                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const fetchCollection = async () => {
        // ${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/collection
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/collectionOfCollection?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    console.log(res.data.result)
                    let filterObj = res.data.result.map(ele => {
                        ele.typeName = "collection"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)
                    setIsLoading(false)

                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const fetchCategories = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/categorylist?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    let filterObj = res.data.data.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "category"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)
                    setIsLoading(false)

                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const fetchBanner = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner/list/collection?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    let filterObj = res.data.data.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "banner"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)
                    setIsLoading(false)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const fetchBannerList = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerList?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "banner"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)
                    setIsLoading(false)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const searchBanner = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner/list/collection/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    console.log(res.data);
                    let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "banner"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })

                    setRight(filterObj)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const searchBannerList = async (data) => {
        //${process.env.REACT_APP_CMS_PLAYLIST_IP}
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerlist/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    console.log(res.data);
                    let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "bannerList"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })

                    setRight(filterObj)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const fetchPageName = async () => {
        let data = localStorage.getItem("storeId");

        if (data) {
            setStoreId(localStorage.getItem("storeId"));
            await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
                .then(
                    res => {
                        setPageName(res.data.result.result.pageCode)
                    }
                ).catch(
                    err => {
                        console.log(err)
                    }
                )
        }
        else {
            navigate.push('/login');
        }
    }

    const fetchBucketsLinkWithPage = async () => {
        // ${process.env.REACT_APP_CMS_CONTENT_IP}/v1/mapping/data/${props.match.params.id}
        let data = localStorage.getItem("storeId");
        if (data) {
            setStoreId(localStorage.getItem("storeId"));
            await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/mapping/data/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
                .then(
                    res => {
                        let object = res.data.mappingContent.filter(ele => {
                            if (ele != null) {
                                if (ele.typeName === "bucket") {
                                    ele.names = JSON.parse(ele.names)
                                    return ele
                                } else if (ele.typeName === "eventBucket") {
                                    ele.names = JSON.parse(ele.names)
                                    return ele
                                }
                                else if (ele.typeName === "bucketGroup") {
                                    ele.name = JSON.parse(ele.name)
                                    return ele
                                }
                                return ele
                            }
                        })
                        console.log('===============================>', object);
                        setLeft(object)
                        setStartFetch(true)
                    }
                ).catch(
                    err => {
                        console.log(err)
                        setStartFetch(true)
                    }
                )
        }
        else {
            navigate.push('/login');
        }
    }
    const fetchStory = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/storyCollection?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    // let filterstory = res.data.result.result.filter(ele => {
                    //     return !ele.parentId
                    // })
                    // let toStrStory = filterstory.filter(value => {
                    //     value.name = JSON.parse(value.name)
                    //     return value
                    // })
                    let filterObj = res.data.result.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "story"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)
                    setIsLoading(false)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const searchCategories = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/categorylist/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    console.log(res);
                    let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "category"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)

                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const searchEvents = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/eventCollection/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    if (res.data.message !== "no data found") {
                        let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                        filterObj = filterObj.map(ele => {
                            ele.typeName = "event"
                            return ele

                        })
                        filterObj = filterObj.map(ele => {
                            ele.displayName = ""
                            ele.country = ['ALL']
                            return ele

                        })
                        setRight(filterObj)
                    } else {
                        setRight([])
                    }


                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const searchCollection = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/collectionOfCollection/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    // console.log(res)
                    let filterObj = res.data.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "collection"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)


                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const searchBucket = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/bucket/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    let object = res.data.result.result.filter(ele => {
                        if (typeof (ele.names) === "string") {
                            ele.names = JSON.parse(ele.names)
                            return ele
                        }
                        return ele

                    })
                    let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "bucket"

                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })

                    setRight(filterObj)


                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const searchStory = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/storyCollection/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    let filterObj = res.data.result.result.filter(qId => !left.some(o => o._id === qId._id))
                    filterObj = filterObj.map(ele => {
                        ele.typeName = "story"
                        return ele

                    })
                    filterObj = filterObj.map(ele => {
                        ele.displayName = ""
                        ele.country = ['ALL']
                        return ele

                    })
                    setRight(filterObj)


                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const searchBucketGroup = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/bucketGroup/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {

                let object = res.data.result.result.filter(ele => {
                    ele.bucketGroupName = JSON.parse(ele.bucketGroupName)
                    return ele
                })

                let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                filterObj = filterObj.map(ele => {
                    ele.typeName = "bucketGroup"

                    return ele

                })
                filterObj = filterObj.map(ele => {
                    ele.displayName = ""
                    ele.country = ['ALL']
                    return ele

                })

                setRight(filterObj)
                setIsLoading(false)

            }).catch(err => {
                console.log(err)
            })
    }

    const searchTopNav = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/topNavCollection/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {
                let object = res.data.result.result
                let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                filterObj = filterObj.map(ele => {
                    ele.typeName = "topNav"

                    return ele

                })
                filterObj = filterObj.map(ele => {
                    ele.displayName = ""
                    ele.country = ['ALL']
                    return ele

                })
                console.log(filterObj)
                setRight(filterObj)
                setIsLoading(false)

            }).catch(err => {
                console.log(err)
            })
    }


    const searchContent = (e, type) => {
        if (e.key === "Enter") {


            if (searched !== "" && type === "Categories") {
                searchCategories(searched)
            }
            else if (searched !== "" && type === "Events") {
                searchEvents(searched)
            }
            else if (searched !== "" && type === "collection") {
                searchCollection(searched)
            }
            else if (searched !== "" && type === "bucket") {
                searchBucket(searched)
            }
            else if (searched !== "" && type === "stories") {
                searchStory(searched)
            }
            else if (searched !== "" && type === "Banner") {
                searchBanner(searched)
            }
            else if (searched !== "" && type === "BannerList") {
                searchBannerList(searched)
            }
            else if (searched !== "" && type === "bucketGroup") {
                searchBucketGroup(searched)
            }
            else if (searched !== "" && type === "TopNav") {
                searchTopNav(searched)
            }
        }
    }



    useEffect(() => {

        fetchBucketsLinkWithPage();
        fetchPageName();
        fetchCountry();

        // fetchStory();
        // fetchEvents();
        // fetchCollection();
        // fetchCategories();


    }, [])
    useEffect(() => {
        if (searched === "") {
            fetchBucketsLinkWithPage();
            if (value === 0) {
                fetchTopNavCollection()
            }
            else if (value === 1) {
                fetchStatic()
            }
            else if (value === 2) {
                fetchBucket()
            }
            else if (value === 3) {
                fetchEventBucket()
            }
            else if (value === 4) {
                fetchBucketGroup()
            }
            else if (value === 5) {
                fetchStory();
            }
            else if (value === 6) {
                fetchCollection();
            }
            else if (value === 7) {
                fetchEvents();
            }
            else if (value === 8) {
                fetchBanner();
            }
            else if (value === 9) {
                fetchCategories();
            }
            else if (value === 10) {
                fetchBannerList();
            }
        }



    }, [searched])

    // useEffect(() => {
    //     if (startFetch === true) {
    //         fetchBucket()
    //     }
    // }, [startFetch])

    const fetchCountry = async () => {
        // ${process.env.REACT_APP_CMS_CONTENT_IP}/v1/mapping/data/${props.match.params.id}
        let data = localStorage.getItem("storeId");
        if (data) {
            setStoreId(localStorage.getItem("storeId"));
            await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/country?storeId=${localStorage.getItem("storeId")}`)
                .then(
                    res => {
                        console.log('country',res.data.result[0].country);
                            setCountry(res.data.result[0].country)
                    }
                ).catch(
                    err => {
                        console.log(err)
                    }
                )
        }
        else {
            navigate.push('/login');
        }
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const PostMapping = (data) => {
        axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/mapping`, data)
            .then(res => {
                cogoToast.success("content Mapped Successfully")
            }).catch(err => {
                console.log(err)
            })
    }

    const onSubmit = () => {
        const bucketsLinkPage = left.map((ele, i) => {
            let obj = {
                id: ele._id,
                typeName: ele.typeName,
                displayName: ele.displayName ? ele.displayName : "",
                sequence: i,
                country:ele.country ? ele.country : ['ALL']
            }
            return obj;
        })
        console.log(bucketsLinkPage)
        const objects = {
            id: props.match.params.id,
            items: bucketsLinkPage,
            storeId: localStorage.getItem("storeId")
        }
        PostMapping(objects);

    }

    const handleDisplayNameInput = (e, id) => {
        // setDisplayNames(e.target.value);
        let data = [...left];
        data[id] = {
            ...data[id],
            displayName: e.target.value
        }
        setLeft(data)
    }

    const handleCountryInput = (e, id) => {
        setCountryData(e)
        let data = [...left];
        data[id] = {
            ...data[id],
            country: e
        }
        setLeft(data)
    }
    // useEffect(() => {
    //     if (reload === true) {
    //         fetchPage();

    //     }

    // }, [reload])

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(left);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setLeft(items)


    }
    const deleteLeftData = () => {
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    }

    const refreshList = () => {
        if (value === 0) {
            fetchTopNavCollection()
        }
        else if (value === 1) {
            fetchStatic()
        }
        else if (value === 2) {
            fetchBucket()
        }
        else if (value === 3) {
            fetchEventBucket()
        }
        else if (value === 4) {
            fetchBucketGroup()
        }
        else if (value === 5) {
            fetchStory();
        }
        else if (value === 6) {
            fetchCollection();
        }
        else if (value === 7) {
            fetchEvents();
        }
        else if (value === 8) {
            fetchBanner();
        }
        else if (value === 9) {
            fetchCategories();
        }
        else if (value === 10) {
            fetchBannerList();
        }
    }

    const datalist = (title, items, type) => (

        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="Refresh">
                        <IconButton aria-label="delete" onClick={refreshList}>
                            <RefreshOutlined />
                        </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            {type !== "static" ? <div className={clasesJs.contentSearch}>
                <div className={clasesJs.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: clasesJs.inputRoot,
                        input: clasesJs.inputInput,
                    }}
                    value={searched}
                    onChange={(e) => setSearched(e.target.value)}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={(e) => searchContent(e, type)}
                />
            </div> : null}
            <List className={classes.list} dense component="div" role="list" style={{ width: "100%" }}>
                {isLoading === true ? <CircularProgress /> : items.map((value, index) => {
                    const labelId = `${value._id ? value._id : null}`;

                    return (
                        <ListItem role="listitem" button onClick={handleToggle(value)} >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            {type === "collection" ?
                                <ListItemText id={labelId} >{value.name + " - " + value._id}</ListItemText> :
                                type === "bucket" ?
                                    <ListItemText id={labelId} primary={
                                        value.names ? value.names[0].en + " - " + value._id : null
                                    } />
                                    :
                                    type === "eventBucket" ?
                                        <ListItemText id={labelId} primary={
                                            value.names ? value.names[0].en + " - " + value._id : null
                                        } />
                                        :
                                        type === "stories" ?
                                            <ListItemText id={labelId} primary={
                                                value.name + " - " + value._id
                                            } />
                                            :
                                            type === "collection" ?
                                                <ListItemText id={labelId} primary={
                                                    value.mainTitle + " - " + value._id
                                                } />
                                                :
                                                type === "Banner" ?
                                                    <ListItemText id={labelId} primary={
                                                        value.name + " - " + value._id
                                                    } />
                                                    :
                                                    type === "BannerList" ?
                                                        <ListItemText id={labelId} primary={
                                                            value.name + " - " + value._id
                                                        } />
                                                        :
                                                        type === "static" ?
                                                            <ListItemText id={value._id} primary={
                                                                value.name
                                                            } />
                                                            :
                                                            type === "bucketGroup" ?
                                                                <ListItemText id={value.id} primary={
                                                                    (value.bucketGroupName ? value.bucketGroupName.en : null) + " - " + value._id
                                                                } />
                                                                :
                                                                <ListItemText id={labelId}  >{value.name + " - " + value._id} </ListItemText>}
                        </ListItem>

                    );
                })}
            </List>
        </Card>
    );

    const pageList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="delete">
                        <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                            <DeleteForeverOutlinedIcon className={leftChecked.length === 0 ? null : clasesJs.btndelete} />
                        </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef} style={{ width: "100%" }}>
                            {items.map((value, index) => {
                                const labelId = `${value._id ? value._id : null}`;

                                return (
                                    <Draggable key={value._id} draggableId={value._id} index={index}>
                                        {(provided) => (
                                            <div>
                                                <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={checked.indexOf(value) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    {value.typeName === "collection" ?
                                                        <ListItemText id={labelId}>
                                                            {value.name + "(" + (value.typeName) + ")"}
                                                        </ListItemText>
                                                        :
                                                        value.typeName === "banner" ?
                                                            <ListItemText id={labelId}>
                                                                {value.name + "(" + (value.typeName) + ")"}
                                                            </ListItemText>
                                                            :
                                                            value.typeName === "bannerList" ?
                                                                <ListItemText id={labelId}>
                                                                    {value.name + "(" + (value.typeName) + ")"}
                                                                </ListItemText>
                                                                :
                                                                value.typeName === "story" ?
                                                                    <ListItemText id={labelId} primary={
                                                                        value.name + "(" + (value.typeName) + ")"
                                                                    } /> :
                                                                    value.typeName === "static" ?
                                                                        <ListItemText id={value._id} primary={
                                                                            value.name ? value.name + "(" + (value.typeName) + ")" : value._id + "(" + (value.typeName) + ")"
                                                                        } /> :
                                                                        value.typeName === "bucket" ?
                                                                            <ListItemText id={labelId} primary={
                                                                                value.names ? value.names[0].en + "(" + (value.typeName) + ")" : null
                                                                            } /> :
                                                                            value.typeName === "eventBucket" ?
                                                                                <ListItemText id={labelId} primary={
                                                                                    value.names ? value.names[0].en + "(" + (value.typeName) + ")" : null
                                                                                } /> :
                                                                                value.typeName === "bucketGroup" ?
                                                                                    <ListItemText id={labelId} primary={
                                                                                        value.bucketGroupName ? value.bucketGroupName.en + "(" + (value.typeName) + ")" : value.name.en + "(" + (value.typeName) + ")"
                                                                                    } /> :
                                                                                    <ListItemText id={labelId}>
                                                                                        {value.name[0].en ? value.name[0].en + "(" + (value.typeName) + ")" :
                                                                                            value.name ? value.name + "(" + (value.typeName) + ")" :
                                                                                                null
                                                                                        }
                                                                                    </ListItemText>
                                                    }

                                                </ListItem>
                                                <div style={{ maxWidth: "350px", marginLeft: "70px", display: "flex" }}>

                                                    <TextField variant="outlined" value={value.displayName} placeholder="Display Name" onChange={(e) => handleDisplayNameInput(e, index)} ></TextField>
                                                    <Grid item md={6} sm={12}>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <InputLabel id="demo-mutiple-checkbox-label"></InputLabel>

                                                            <Select
                                                                labelId="demo-mutiple-checkbox-label"
                                                                id="demo-mutiple-checkbox"
                                                                multiple
                                                                label="Device"
                                                                name="Device"
                                                                value={left[index].country}
                                                                onChange={(e) => handleCountryInput(e.target.value, index)}
                                                                // input={<Input />}
                                                                renderValue={(selected) => selected.join(', ')}
                                                            >
                                                                {country.map((name, id) => {
                                                                    return (
                                                                        <MenuItem key={id} value={name.countryId}>
                                                                            <Checkbox checked={left[index].country.indexOf(name.countryId) > -1} />
                                                                            <ListItemText primary={name.countryId} />
                                                                        </MenuItem>)
                                                                })}
                                                            </Select>

                                                        </FormControl>

                                                    </Grid>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );



    return (
        <Fragment>
            <Paper spacing={3}>
                <Link to="/listPage"><p style={{ paddingTop: "30px", paddingLeft: "30px" }}>Back to Page List</p></Link>
                <h1 style={{ paddingBottom: "30px", paddingLeft: "30px" }}>{pageName}</h1>

            </Paper>
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>

                <Grid item md={5}>
                    <h4>Pages</h4>
                    {pageList('Choices', left)}</Grid>
                <Grid item md={1}>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={5}>
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={tabhandleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="on"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Top Nav" {...a11yProps(0)} />
                                <Tab label="Static" {...a11yProps(1)} />
                                <Tab label="Bucket" {...a11yProps(2)} />
                                <Tab label="EventBucket" {...a11yProps(3)} />
                                <Tab label="BucketGroup" {...a11yProps(4)} />
                                <Tab label="Stories" {...a11yProps(5)} />
                                <Tab label="collection" {...a11yProps(6)} />
                                <Tab label="Live Events" {...a11yProps(7)} />
                                <Tab label="Banner" {...a11yProps(8)} />
                                <Tab label="Categories" {...a11yProps(9)} />
                                <Tab label="BannerList" {...a11yProps(10)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            {datalist('Chosen', right, "TopNav")}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {datalist('Chosen', right, "static")}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {datalist('Chosen', right, "bucket")}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {datalist('Chosen', right, "eventBucket")}
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            {datalist('Chosen', right, "bucketGroup")}
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            {datalist('Chosen', right, "stories")}
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                            {datalist('Chosen', right, "collection")}
                        </TabPanel>
                        <TabPanel value={value} index={7}>
                            {datalist('Chosen', right, "Events")}
                        </TabPanel>
                        <TabPanel value={value} index={8}>
                            {datalist('Chosen', right, "Banner")}
                        </TabPanel>
                        <TabPanel value={value} index={9}>
                            {datalist('Chosen', right, "Categories")}
                        </TabPanel>
                        <TabPanel value={value} index={10}>
                            {datalist('Chosen', right, "BannerList")}
                        </TabPanel>
                    </div>



                </Grid>

                <Grid item md={12} style={{ marginLeft: "170px" }}>

                    <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>

                </Grid>
            </Grid>


        </Fragment>
    );
}

