import React,{Fragment,useState, useEffect} from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';


export default function EditApp(props) {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [retail, setRetail] = useState("");
    const [enable, setEnable] = useState("true");
    
    const listVarient = ["v1", "v2", "v3", "v4", "v5", "v6"];

    const fetchCategoryById = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
          .then(res => {
              console.log('res',res);
            setCode(res.data.result.result.code)
            setTitle(res.data.result.result.title)
            setDescription(res.data.result.result.description)
            setRetail(res.data.result.result.retail)
            setEnable(res.data.result.result.enabled.toString())
          }).catch(err => {
            console.log(err)
          })
      }
    
      useEffect(()=>{
        fetchCategoryById()
      },[])

    const enhandleChange = (event) => {
        setEnable(event.target.value);

    };
    const postGenre = async (data) => {
        const fd = {
        "code": data.code,
        "title": data.title,
        "description": data.description,
        "retail": data.retail,
        "enabled":data.enabled,
        "storeId":data.storeId
                   }
                   console.log('dd',fd);
       
        await axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}`, fd)
            .then(res => {
                cogoToast.success("success",res);
                props.history.push('/listApp')
            }).catch(err => {
                console.log(err.response)
                cogoToast.error("Somthing went wrong!");
            })
    }

    const onSubmit = () => {
        const obj = {
            "code": code,
            "title": title,
            "description": description,
            "retail":retail,
            "enabled":enable,
            "storeId":localStorage.getItem("storeId")
        }
        postGenre(obj)
        console.log('payload',obj);
    }

    const onClear = () => {
        setCode("")
        setTitle("")
        setDescription("")
        setRetail("")
    }

    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Edit App</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Code" name="Code" value={code} onChange={(e) => { setCode(e.target.value) }} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Title" name="Title" value={title} onChange={(e) => { setTitle(e.target.value) }} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Description" name="description" value={description} onChange={(e) => { setDescription(e.target.value) }} ></TextField>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={enable} onChange={enhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Retail ID" name="retail" value={retail} onChange={(e) => { setRetail(e.target.value) }}></TextField>
                        </Grid>

                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
