import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    MenuItem

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import {content_type} from '../bucket/objects'




export default function EditVarient(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [v0, setv0] = useState([]);
    const [v2, setv2] = useState([]);
    const [v3, setv3] = useState([]);
   
    const [deletev0, setDeletev0] = useState([]);
    const [deletev2, setDeletev2] = useState([]);
    const [deletev3, setDeletev3] = useState([]);
   
    const [contents, setContents] = useState("");
    const [contentId, setContentId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])
    const [lang, setLang] = useState("Default")

    const listVarient = ["v0","v1", "v2", "v3", "v4", "v5", "v6"];


    const handlev0 = (e) => {
        setv0([...v0, ...e.target.files])
    }
    const handlev2 = (e) => {
        setv2([...v2, ...e.target.files])
    }
    const handlev3 = (e) => {
        setv3([...v3, ...e.target.files])
    }
    
    const onHandleChange = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setName(e.target.value)
    }

    const fetchDataById = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {
                console.log('thumb',res.data.result.result.thumbnail)
 
                setName(res.data.result.result.name)
                setv0(res.data.result.result.v0)
                setv2(res.data.result.result.v2)
                setv3(res.data.result.result.v3)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchDataById()
    }, [])

    const postVariant = async (data) => {
        const fd = new FormData();
        fd.append("type", data.type);
        fd.append("name", data.name);
        fd.append("storeId", data.storeId);
        data.deletev0.map((ele, i) => {
            fd.append(`deletev0[${i}]`, ele)
        })
        data.deletev2.map((ele, i) => {
            fd.append(`deletev2[${i}]`, ele)
        })
        data.deletev3.map((ele, i) => {
            fd.append(`deletev3[${i}]`, ele)
        })
        for (let index = 0; index < data.v0.length; index++) {
            const element = data.v0[index];
            if (typeof (element) !== "string") {
                fd.append('v0', element)
            }
        }
        for (let index = 0; index < data.v2.length; index++) {
            const element = data.v2[index];
            if (typeof (element) !== "string") {
                fd.append('v2', element)
            }
        }
        for (let index = 0; index < data.v3.length; index++) {
            const element = data.v3[index];
            if (typeof (element) !== "string") {
                fd.append('v3', element)
            }
        }
        await axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/recomPlaylistContent/variant/${props.match.params.id}`, fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                cogoToast.success(res.data.result.message);
                props.history.push("/listrecomPlaylist")
            }).catch(err => {
                cogoToast.error(err.response.data.message)
            })
    }
    
    const onSubmit = () => {
        const obj = {
            "type": "playlistVariant",
            "v0": v0,
            "v2":v2,
            "v3":v3,
            name: name,
            deletev0: deletev0,
            deletev2:deletev2,
            deletev3:deletev3,
            "storeId":localStorage.getItem("storeId")       
        }
        postVariant(obj)
        setName("")
    }

    const onClear = () => {
        setName('')
        setv0([])
        setv2([])
        setv3([])
    }
    const delete_v0 = (e, i, ele) => {
        let newArray = v0.filter((ele, index) => {
            return index !== i;
        })

        setDeletev0([...deletev0, ele]);

        setv0(newArray)
    }
    const delete_v2 = (e, i, ele) => {
        
        let newArray = v2.filter((ele, index) => {
            return index !== i;
        })
        setDeletev2([...deletev2, ele])
        setv2(newArray)
    }
    const delete_v3 = (e, i, ele) => {
        
        let newArray = v3.filter((ele, index) => {
            return index !== i;
        })
        setDeletev3([...deletev3, ele])
        setv3(newArray)
    }
    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Edit RecomPlaylist</h1>
                    <Grid container >
                        <Grid item md={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Playlist Name" value={name} onChange={onHandleChange}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                v0
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev0}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v0.length > 0 ?
                                (v0.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/playlistVariant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v0(e, i, ele)} /></div>))
                                : null}
                                


                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v2
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev2}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v2.length > 0 ?
                                (v2.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/playlistVariant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v2(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v3
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev3}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v3.length > 0 ?
                                (v3.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/playlistVariant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v3(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
