import React, { useState, Fragment, useEffect } from 'react'
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles,
    Backdrop,
    CircularProgress,
    Tooltip,
    IconButton,
    ListItemText,
    Checkbox,
    Input,
    OutlinedInput

} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../../styles/style';
import axios from 'axios';
// import DateFnsUtils from "@date-io/date-fns";
// import {
//     DateTimePicker,
//     MuiPickersUtilsProvider
// } from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
// import ClearIcon from "@material-ui/icons/Clear";
import cogoToast from 'cogo-toast';
import { banner_content_type } from './../bucket/objects'
import Switch from "react-switch";
import InfoIcon from "@material-ui/icons/InfoRounded"
import MultiLanguageModal from '../Reusable/multiLanguageModal';


const useStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 3,
        color: '#fff',
    },
}));



export default function CreateBanner(props) {
    const classes = useStyles();
    const backDropClass = useStyle();
    const [title, setTitle] = useState("");
    const [date, setDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [country, setCountry] = useState("")
    const [newDate, setNewDate] = useState("")
    const [insiderEventId, setInsiderEventId] = useState("")
    const [langList, setLanglist] = useState([]);
    const [stencil, setStencil] = useState([])
    const [thumbnail, setThumbnail] = useState([])
    const [coms_image, setComs_image] = useState([])
    const [coms_media, setComs_media] = useState([])

    const [eventName,setEventName] = useState("");
    const [tag,setTag] = useState("");
    const [venue,setVenue] = useState("");
    const [map,setMap] = useState("");
    const [lowprice,setLowPrice] = useState("");
    const [urlandroid,setUrlAndroid] = useState("");
    const [urlios,setUrlIos] = useState("");
    const [duration,setDuration] = useState("");
    const [age,setAge] = useState("");
    const [artists,setArtists] = useState("");
    const [description,setDescription] = useState("");
    const [flag,setFlag] = useState(false);
    const [flag1,setFlag1] = useState(false);
    const [flag2,setFlag2] = useState(false);
    const [flag3,setFlag3] = useState(false);
    const [flag4,setFlag4] = useState(false);
    const [flag5,setFlag5] = useState(false);
    const [flag6,setFlag6] = useState(false);
    const [flag7,setFlag7] = useState(false);
    const [flag8,setFlag8] = useState(false);
    const [flag9,setFlag9] = useState(false);
    const [flag10,setFlag10] = useState(false);
    const [flag11,setFlag11] = useState(false);
    const [flag12,setFlag12] = useState(false);
    const [flag13,setFlag13] = useState(false);
    const [flag14,setFlag14] = useState(false);
    const [flag15,setFlag15] = useState(false);
    const [flag16,setFlag16] = useState(false);
    const [flag17,setFlag17] = useState(false);
    const [flag18,setFlag18] = useState(false);
    const [listMusicVideo, setListMusicVideo] = useState([{ id: "IN", title: "India" }, { id: "TH", title: "Thailand" }])
    const inputArr = [ { type: "price",value: ""}];
    const [arr, setArr] = useState(inputArr);
    const addInput = () => {
        setArr(s => {
          return [
            ...s,
            {
              type: "price",
              value: ""
            }
          ];
        });
      };

      const handleChange = e => {
        e.preventDefault();
    
        const index = e.target.id;
        setArr(s => {
          const newArr = s.slice();
          newArr[index].value = e.target.value;
    
          return newArr;
        });
        setFlag4(false)
      };
   
    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setLanglist(res.data.result.result)
        }).catch(
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        fetchLanguage()
    }, [])

    const postApi = async (data) => {
        const fd = new FormData();
        fd.append("eventName", data.eventName);
        fd.append("eventTag", data.eventTag);
        fd.append("venue", data.venue);
        fd.append("venueMapLink",data.venueMapLink);
        fd.append("language", data.language);
        fd.append("ticketPrice", JSON.stringify(data.ticketPrice));
        fd.append("lowestTicketPrice", data.lowestTicketPrice);
        fd.append("webviewUrlIos", data.webviewUrlIos);
        fd.append("webviewUrlAndroid", data.webviewUrlAndroid);
        fd.append('age',data.age)
        fd.append('artists',data.artists)
        fd.append('duration',data.duration)
        fd.append('aboutEventTitle',data.aboutEventTitle)
        fd.append('startDate',data.startDate)
        fd.append('endDate',data.endDate)
        fd.append('aboutEventDesc',data.aboutEventDesc)
        fd.append('country',data.country)
        fd.append('newDate',data.newDate)
        fd.append('insiderEventId',data.insiderEventId)
        fd.append('storeId',localStorage.getItem("storeId"))
        for (let index = 0; index < data.image.length; index++) {
            const element = data.image[index];
            fd.append('image', element)
        }

        for (let index = 0; index < data.stencil.length; index++) {
            const element = data.stencil[index];
            fd.append('stencil', element)
        }
        for (let index = 0; index < data.coms_image.length; index++) {
            const element = data.coms_image[index];
            fd.append('coms_image', element)
        }

        for (let index = 0; index < data.coms_media.length; index++) {
            const element = data.coms_media[index];
            fd.append('coms_media', element)
        }
        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/offlineevent`, fd)
            .then(
                res => {
                    setTitle("")
                    setDisplayName("");
                    setLanguage("")
                    setStencil([])
                    setThumbnail([])
                    setIsLoading(false)
                    cogoToast.success("success")
                }
            ).catch(err => {
                cogoToast.error(err.response.data.message);
                setIsLoading(false)

            })

    }


    const onSubmit = () => {
        if (eventName == "" || country == "" || venue == "" || map == "" || arr[0].value == ""|| 
        lowprice == "" || urlios == "" || artists == "" || urlandroid == "" || language == "" || newDate == "" || insiderEventId == "" || title == "" || coms_image.length < 1 || coms_media.length <1 || stencil.length <1 ||
        thumbnail.length < 1) {
            cogoToast.error("please fill required fields",{position :'top-center'})
            eventName == "" ? setFlag(true) : setFlag(false)
            country == "" ? setFlag1(true) : setFlag1(false)
            venue == "" ? setFlag2(true) : setFlag2(false)
            map == "" ? setFlag3(true) : setFlag3(false)
            arr[0].value == "" ? setFlag4(true) : setFlag4(false)
            lowprice == "" ? setFlag5(true) : setFlag5(false)
            urlandroid == "" ? setFlag6(true) : setFlag6(false)
            urlios == "" ? setFlag7(true) : setFlag7(false)
            language == "" ? setFlag8(true) : setFlag8(false)
            title == "" ? setFlag9(true) : setFlag9(false)
            artists == "" ? setFlag13(true) : setFlag13(false)
            newDate == "" ? setFlag14(true) : setFlag14(false)
            insiderEventId == "" ? setFlag15(true) : setFlag15(false)
            thumbnail.length < 1 ? setFlag10(true) : setFlag10(false)
            coms_image.length < 1 ? setFlag16(true) : setFlag16(false)
            coms_media.length < 1 ? setFlag17(true) : setFlag17(false)
            stencil.length < 1 ? setFlag18(true) : setFlag18(false)
            if (thumbnail.length < 1 ) cogoToast.error("please select image",{position :'top-center'})
        }
        else{
        let obj = {
            eventName:eventName,
            eventTag: tag,
            venue: venue,
            venueMapLink: map,
            ticketPrice: arr,
            lowestTicketPrice: lowprice,
            webviewUrlIos:urlios,
            webviewUrlAndroid:urlandroid,
            image:thumbnail,
            stencil:stencil,
            coms_image :coms_image,
            coms_media : coms_media,
            language:language,
            age : age,
            artists : artists,
            duration:duration,
            aboutEventTitle:title,
            startDate:startDate,
            endDate:endDate,
            aboutEventDesc:description,
            country:country,
            newDate : newDate,
            insiderEventId : insiderEventId
            }
        console.log('oobj',obj)
         postApi(obj)
        setIsLoading(true)
        }
    }
 
    const onClear = () => {
        setTitle("")
        setDisplayName("");
        setLanguage("")
        setStencil([])
        setThumbnail([])
    }

    const deleteBackground = (e, i) => {
        let newArray = thumbnail.filter((ele, index) => {
            return index !== i;
        })

        setThumbnail(newArray)
    }

    const deleteStencil = (e, i) => {
        let newArray = stencil.filter((ele, index) => {
            return index !== i;
        })

        setStencil(newArray)
    }

    const deleteComsImage = (e, i) => {
        let newArray = coms_image.filter((ele, index) => {
            return index !== i;
        })

        setComs_image(newArray)
    }

    const deleteComsMedia = (e, i) => {
        let newArray = coms_media.filter((ele, index) => {
            return index !== i;
        })

        setComs_media(newArray)
    }


    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <Backdrop className={backDropClass.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div>
                    <h1 className="text-center mb-5" >Add Offline Live Event</h1>
                    <Grid container style={{ marginLeft: "20px" }}>
                    <Grid item md={6} sm={12}>
                            <TextField error={flag15 == true} helperText={flag15 == true ? "Field can not be empty" : "Insider event ID"} variant="outlined"  className={classes.inputField} width="25" required label="Insider Event Id" value={insiderEventId} onChange={(e) => {setInsiderEventId(e.target.value);setFlag15(false)}}>
                            </TextField>
                            {/* <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button> */}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField error={flag14 == true} helperText={flag14 == true ? "Field can not be empty" : "Format DD Mon, YYYY Eg 19 Oct, 2023"} variant="outlined" className={classes.inputField} width="25" required label="Event Date" value={newDate} onChange={(e) => {setNewDate(e.target.value);setFlag14(false)}}>
                            </TextField>
                            {/* <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button> */}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField error={flag == true} helperText={flag == true ? "Field can not be empty" : "Name of the Event"} variant="outlined" className={classes.inputField} width="25" required label="Event Name" value={eventName} onChange={(e) => {setEventName(e.target.value);setFlag(false)}}>
                            </TextField>
                            {/* <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button> */}
                        </Grid>
                        <Grid item md={6}>
                            <FormControl  error={flag1 == true} helperText={flag1 == true ? "Field can not be empty" : ""} variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={country}

                                    onChange={(e) => {setCountry(e.target.value);setFlag1(false)}}
                                    label="Language"
                                >
                                    {
                                        listMusicVideo && listMusicVideo.length > 0 ?
                                            listMusicVideo.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.id}>{element.title}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No data</MenuItem>

                                    }

                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" helperText="Eg. Music Concert, Comedy, Music Festival" className={classes.inputField} width="25" label="Tag" value={tag} onChange={(e) => setTag(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField error={flag2 == true} helperText={flag2 == true ? "Field can not be empty" : "Location of the event"}  variant="outlined" className={classes.inputField} width="25" required label="Venue" value={venue} onChange={(e) => {setVenue(e.target.value);setFlag2(false)}}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField error={flag3 == true} helperText={flag3 == true ? "Field can not be empty" : ""}  variant="outlined" className={classes.inputField} width="25" required label="Venue Map Link" value={map} onChange={(e) => {setMap(e.target.value);setFlag3(false)}}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                        <IconButton onClick={addInput}><AddCircleOutlineIcon/></IconButton>
                        {/* <IconButton onClick={addInput}><RemoveIcon/></IconButton> */}
      {arr.map((item, i) => {
        return (
            <Grid item md={8} sm={12}>
            <TextField error={flag4 == true} helperText={flag4 == true ? "Field can not be empty" : "Eg: ₹1499, 1 Months"}  variant="outlined" className={classes.inputField} id={i} type={item.type} width="25" required label="Pricing & Subscription" onChange={handleChange} value={item.value}>
                            </TextField>
                            {/* {arr.length > 1 ?<CancelIcon onClick={(e) => deleteBackground(e, i)} /> : null} */}
                            </Grid>
        );
      })}
      
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField error={flag5 == true} helperText={flag5 == true ? "Field can not be empty" : ""}  variant="outlined" className={classes.inputField} width="25" required label="Low Price" value={lowprice} onChange={(e) => {setLowPrice(e.target.value);setFlag5(false)}}>
                            </TextField>
                        </Grid>
                <Grid item md={6}>
                            <TextField error={flag6 == true} helperText={flag6 == true ? "Field can not be empty" : ""}  variant="outlined" className={classes.inputField} width="25" required label="web view url for android" value={urlandroid} onChange={(e) => {setUrlAndroid(e.target.value);setFlag6(false)}} >
                            </TextField>
                        </Grid>
                        <Grid item md={6}>
                            <TextField error={flag7 == true} helperText={flag7 == true ? "Field can not be empty" : ""}  variant="outlined" className={classes.inputField} width="25" required label="web view url for ios" value={urlios} onChange={(e) => {setUrlIos(e.target.value);setFlag7(false)}}>
                            </TextField>
                        </Grid>
                        <Grid item md={6}>
                            <TextField variant="outlined" helperText="Format XXhrs XXminsEg 2hrs 50mins" className={classes.inputField} width="25" label="total duration" value={duration} onChange={(e) => setDuration(e.target.value)}>
                            </TextField>
                        </Grid>
                <Grid item md={6} sm={12}>
                <TextField variant="outlined" helperText="Format XXyrs+A Eg 14yrs+" className={classes.inputField} width="25" label="age" value={age} onChange={(e) => setAge(e.target.value)} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <TextField error={flag13 == true} helperText={flag13 == true ? "Field can not be empty" : "Format XXX123,XXX456"} variant="outlined" required className={classes.inputField} width="25" label="artist IDs" value={artists} onChange={(e) => setArtists(e.target.value)} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <TextField error={flag8 == true} helperText={flag8 == true ? "Field can not be empty" : "Language of the event"}  variant="outlined" className={classes.inputField} width="25" required label="language"  value={language} onChange={(e) => {setLanguage(e.target.value);setFlag8(false)}} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <TextField error={flag9 == true} helperText={flag9 == true ? "Field can not be empty" : "Enter Event title"}  variant="outlined" className={classes.inputField} width="25" required label="title"  value={title} onChange={(e) => {setTitle(e.target.value);setFlag9(false)}} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <TextField variant="outlined" helperText="Section for about the event, Artist trivia, Fun fact etc" className={classes.inputField} width="25" label="description" value={description} onChange={(e) => setDescription(e.target.value)} >
                </TextField>

            </Grid>
                        
                        
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                                color={flag10 == true ? "secondary" : "primary" }
                            >
                                Featured Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {setThumbnail([...thumbnail, ...e.target.files]);setFlag10(false)}}
                                />
                            </Button>
                            {thumbnail.length > 0 ?
                                (thumbnail.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                                color={flag18 == true ? "secondary" : "primary" }
                            >
                                Detailed image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {setStencil([...stencil, ...e.target.files]);setFlag18(false)}}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                                color={flag16 == true ? "secondary" : "primary" }
                            >
                                Comm's Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {setComs_image([...coms_image, ...e.target.files]);setFlag15(false)}}
                                />
                            </Button>
                            {coms_image.length > 0 ?
                                (coms_image.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteComsImage(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                                color={flag17 == true ? "secondary" : "primary" }
                            >
                                Comm's Media
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {setComs_media([...coms_media, ...e.target.files]);setFlag17(false)}}
                                />
                            </Button>
                            {coms_media.length > 0 ?
                                (coms_media.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteComsMedia(e, i)} /></p>))
                                : null}
                        </Grid>
                       
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker error={flag11 == true} helperText={flag11 == true ? "Field can not be empty" : ""}  label="Event listing Start Date" className={classes.inputField} value={startDate} onChange={e => setStartDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!date}
                                    onClick={() => setDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker error={flag12 == true} helperText={flag12 == true ? "Field can not be empty" : ""}  label="Event Listing End Date" className={classes.inputField} value={endDate} onChange={e => setEndDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!endDate}
                                    onClick={() => setEndDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>

                
                    </Grid>



                    <Grid item md={12} className="mt-4">
                        <Button className="mr-2" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                        <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                    </Grid>
                </div>

            </Paper>
        </Fragment>
    )
}
