import React, { Fragment, useState,useEffect } from "react";
import {Link,useHistory} from 'react-router-dom';
import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
    useTheme,
    Tooltip,
    Box, FormControl, Grid, InputLabel, Paper, Select, TextField, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Chip, Checkbox, Input, ListItemText
} from "@material-ui/core";
import { useStyles } from "./../../styles/style";
import MenuIcon from '@material-ui/icons/Menu';
import Drawer_ from './Drawer';
import axios from 'axios';
import StoreModal from './../Reusable/stores.json';
import ExitToApp from "@material-ui/icons/ExitToApp";
export default function Sidebar(props) {
    const optionStyle = { "padding-top": "28px"}
    const optionStyle2 = { "color": "white"}
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [contents, setContents] = useState("");
    const [langList, setLanglist] = useState([]);
    const navigate = useHistory(); 

    const fetchLanguage = async () => {
       
        let data = localStorage.getItem("storeId");

       if(data){
        setContents(localStorage.getItem("storeId"));
       }
       else{
        navigate.push('/login');
       }
        // await axios.get(`http://35.194.185.126:8081/v1/store`).then(res => {
        //     setLanglist(res.data.result)
        //     console.log('total stores',res.data.result);
        // }).catch(
        //     err => {
        //         console.log(err)
        //     }
        // )
    }
 
    const onSubmit = () =>{
        localStorage.removeItem("storeId");
        navigate.push('/login');
    }
    useEffect(() => {
        fetchLanguage();
    }, [])
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    //['Inbox', 'Starred', 'Send email', 'Drafts']
    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <Drawer_ classes={classes}></Drawer_>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    //   console.log("list",PanelData)
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        CMS
                    </Typography>
                    <Grid container justify="flex-end">
                        <Grid item xs={3}>
                    <FormControl variant="outlined" className={classes.formControl} >
                            {/* <InputLabel id="demo-simple-select-outlined-label">Stores</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contents}
                                onChange={(e) => setContents(e.target.value)}
                                // label="Stores"
                                disabled
                            >
                                {
                                    StoreModal && StoreModal.length > 0 ?
                                        StoreModal.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.storeId}><b style={optionStyle2}>{element.description}</b>&nbsp;<img src={element.image}/></MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Stores Available</MenuItem>
                                }

                            </Select>

                        </FormControl>
                        </Grid>
                        <Grid>
                        <Grid style={optionStyle}>
                        <Tooltip title="Logout"> 
                  <ExitToApp style={optionStyle2} onClick={(e) => onSubmit()} className={classes.btnedit} />
                   </Tooltip>
                   </Grid>
                   </Grid>
                        </Grid>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>

        </div>
    );
}


