
import React, { Fragment, useState, useEffect } from "react";
import {
  TablePagination,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  Box,
  CircularProgress,
  Toolbar,
  InputBase,
  TableSortLabel,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import DeleteModal from './../Reusable/deleteModal';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyModal from './../Reusable/copyModal';

export default function ListVariant(props) {

  const classes = useStyles();
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [removeModal, setRemoveModal] = useState(false)
  const [deleteId, setDeleteId] = useState("");
  const [searched, setSearched] = useState("");

  const [langList, setLangList] = useState([])
  const [lang, setLang] = useState("");
  const [content, setContent] = useState("")
  const [contentType,setcontentType] = useState("");
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [copyModal, setCopyModal] = useState(false)
  const [copyId, setCopyId] = useState("")
  const toggle = () => setRemoveModal(!removeModal);
  const toggle1 = () => setCopyModal(!copyModal);
  const deleteGenre = async (id) => {
    await axios.delete(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/user/delete/${deleteId}/${localStorage.getItem('userId')}`).then(
      res => {
        console.log(res);
        let newData = data.filter(ele=>{
          return ele._id !== deleteId;
        });
        setData(newData);
        setDeleteId("");
        // window.location.reload(false);
      }
    ).catch(err => {
      console.log(err);
    })
  }
  const copyPage = async (id) => {
    // console.log('id',id,copyId);
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/page/id/${copyId}?storeId=${id}`).then(
      res=>{
        console.log(res);
        if(res.data.result.message == "success"){
        setCopyId("")
        fetchData()
        cogoToast.success(res.data.result.message);
        }
        else{
          cogoToast.error("Failed");
        }
        // window.location.reload(false);
      }
    ).catch(err=>{
      console.log(err);
    })
  }
  const fetchLanguage = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
        setLangList([...langList, ...res.data.result.result])
    }).catch(
        err => {
            console.log(err)
        }
    )
}

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/user/getall`)
      .then(res => {
        console.log(res)
        setData(res.data.result)
        setIsLoading(false);
      }).catch(err => {
        cogoToast.error("Bad Request")
      })
  }

  const searchTopNav = async (data) => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/variant/${data}`)
        .then(res => {
            let object  = res.data.result.result.filter(ele => {
                return !ele.parentId
            })
            setData(object)
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })
}
  // useEffect(()=>{
  //   if(!removeModal){
  //     console.log("updated")
  //   fetchData();

  //   }
    
  // },[removeModal]);

  useEffect(() => {
    fetchLanguage()
    fetchData()
  }, [])

  useEffect(() => {
    if (searched === "") {
      fetchData()
    }
}, [searched])

const searchContent = () => {
  searchBucket(id,content,name,lang)
  setIsLoading(true)
}
const onClear = () => {
  fetchData();
  setId("");
  setName("");
  setContent("");
  setcontentType("");
  setLang("")
}
const searchBucket = async (id,content,name,lang) => {
  await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/variant?_id=${id}&name=${name}&contentId=${content}&contentType=${contentType}&language=${lang}&storeId=${localStorage.getItem("storeId")}`)
    .then(
      res => {
        // let data = res.data.result.result.message !== "No Data found!" ?res.data.result.result :[];
        let object  = res.data.result.result.filter(ele => {
          return !ele.parentId
      })
        setData(object)
        setIsLoading(false)
        console.log(res.data.result.result.message);
        cogoToast.success(res.data.result.result.message)
      }
    ).catch(
      err => {
        console.log(err);
        setIsLoading(false)
      }
    )
}
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(0);
  };
  return (
    <Fragment>
      <Paper component={Box} p={4}>
        <h1>List Users</h1>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading === true ? <CircularProgress /> :
                data && data.length > 0 ? data
                  .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                  .map((emp, index) => (
                    <TableRow key={index}>
                      <TableCell>{emp._id}</TableCell>
                      <TableCell>{emp.name}</TableCell>
                      <TableCell>{emp.username}</TableCell>
                      <TableCell>{emp.adminUser == true ? 'TRUE' : 'FALSE'}</TableCell>
                      <TableCell>
                        <Link to={`/editUser/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                        <DeleteForeverOutlinedIcon
                          className={classes.btndelete}
                          onClick={() => { setDeleteId(emp._id); toggle() }}
                        />
                      </TableCell>
                    </TableRow>
                  )) : <p className="mt-2 text-center">no data</p>}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 50,100]}
            component="div"
            count={data ? data.length : null}
            rowsPerPage={rowsPerPage}
            page={pagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
        <DeleteModal
          toggle={toggle}
          deleteModal={removeModal}
          deleteContent={deleteGenre}
        />
         <CopyModal
          toggle1={toggle1}
          copyModal={copyModal}
          copyContent={copyPage}
        />
      </Paper>
    </Fragment>
  );

}
