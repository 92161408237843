import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    InputLabel,
    Select,
    MenuItem


} from '@material-ui/core';
import Switch from "react-switch";

import { useStyles } from '../../styles/style';
import axios from 'axios';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from "@material-ui/icons/Clear";
import cogoToast from 'cogo-toast';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MultiLanguageModal from '../Reusable/multiLanguageModal';


export default function EditStory(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [enable, setEnable] = useState("true");
    const [liveDate, setLiveDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [media, setMedia] = useState();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [continueToggle, setcontinueToggle] = useState(false)
    const [contentType, setContentType] = useState("");
    const [pages, setPages] = useState("");
    const [deepLink, setDeepLink] = useState("");
    const [internal, setInternal] = useState("false");

    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");
    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);


    const [responce, setResponce] = useState()
    const [subStoryMedia, setSubStoryMedia] = useState("");
    const [subStory, setSubStory] = useState("");
    const [linkPage, setLinkPage] = useState("")
    const [ds, setDs] = useState("none")
    const [addData, setAddData] = useState([])
    const [deleteMedia, setDeleteMedia] = useState([]);
    const [deleteSubMedia, setDeleteSubMedia] = useState([]);
    const [pole, setPole] = useState("");
    const [poll, setPoll] = useState("");
    const [poleList, setPoleList] = useState([]);
    const [editSub, setEditSub] = useState(false)





    const enhandleChange = (event) => {
        setEnable(event.target.value);

    };

    const fetchPolls= async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/poll?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    let newArray
                    let obj = res.data.data;
                    newArray = [{_id:" ", name:"None"},...obj]

                    setPoleList(newArray)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        fetchPolls()
        fetchData()
    }, [])

    const fetchData = async () => {
        let data = localStorage.getItem("storeId");
    
        if(data){
         await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/page?storeId=${data}`)
         .then(res => {
           console.log(res)
           let data =[];
           for(let i in res.data.result.result){
               if(res.data.result.result[i].isBrandHub && res.data.result.result[i].isBrandHub == "true"){
data.push(res.data.result.result[i])
               }
           }
           setPages(data)
         }).catch(err => {
           console.log(err)
         })
        }
    
      }

    const handleChangeToggle = (e) =>{
        console.log('ee',e)
        setcontinueToggle(e)
    }
    const internalhandleChange = (event) => {
        setInternal(event.target.value);
    };

    const fetchstoryByID = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {

                    let name = JSON.parse(res.data.result.result.name)

                   setEnglishLang(name[0].en ? name[0].en : '')
            setHindiLang(name[0].hi ? name[0].hi  : '')
            setMarathiiLang(name[0].mr ? name[0].mr  : '')
            setGujaratiLang(name[0].gu ? name[0].gu  : '')
            setKannadaLang(name[0].kn ? name[0].kn  : '')
            setTeluguLang(name[0].te ? name[0].te  : '')
            setTamilLang(name[0].ta ? name[0].ta  : '')
            setPunjabiLang(name[0].pa ? name[0].pa  : '')
            setMalayalamLang(name[0].ml ? name[0].ml : '');
                setOdisaLang(name[0].or ? name[0].or : '');
                setUrduLang(name[0].ur ? name[0].ur :'' );
                setBengaliLang(name[0].bn ? name[0].bn :'' );
                setKhmerLang(name[0].khm ? name[0].khm : '')

                    setLiveDate(res.data.result.result.live_date_time?res.data.result.result.live_date_time:null)
                    setExpiryDate(res.data.result.result.expiry?res.data.result.result.expiry:null)
                    setEnable(res.data.result.result.enabled.toString());
                    setMedia(res.data.result.result.media)
                    setResponce(res.data.result.result.child[0])
                    setSubStory(res.data.result.result.child)
                    setInternal(res.data.result.result.internal ? res.data.result.result.internal : "false")
                    setcontinueToggle(str2bool(res.data.result.result.isBrandHub ? res.data.result.result.isBrandHub : false))
                    setContentType(res.data.result.result.pageId ? res.data.result.result.pageId : "")
                    setDeepLink(res.data.result.result.deepLink ? res.data.result.result.deepLink : "")
                    console.log(res.data.result.result.child)
                    // setEditSub(true)
                }
            ).catch(
                err => {
                    console.log(err)
                }
            )

    }

    useEffect(() => {
        fetchstoryByID()

    }, [])



    const postApi = async (data) => {
        const fd = new FormData();
        fd.append("type", "story");
        fd.append("name", JSON.stringify(data.name));
        fd.append("enabled", data.enabled);
        fd.append("live_date_time", data.live_date_time);
        fd.append("expiry", data.expiry);
        fd.append("storeId",data.storeId);
        fd.append("isBrandHub",data.isBrandHub);
        fd.append("deepLink",data.deepLink);
        fd.append("pageId",data.pageId);
        fd.append("internal",data.internal);
        data.deleteMedia.map((ele, i) => {
            fd.append(`deleteMedia[${i}]`, ele)
        })
        for (let index = 0; index < data.media.length; index++) {
            const element = data.media[index];
            if (typeof (element) !== "string") {
                fd.append('file', element)
            }

        }
        await axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}`, fd)
            .then(
                res => {
                    if (res.status === 200) {
                        cogoToast.success("content updated successfully")
                    }
                }
            ).catch(err => {
                console.log(error);
            })

    }

    // convert string to boolean
    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }


    const onSubmit = () => {
        const obj = {
            "type": "story",
            "name": [{
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            }],
            "media": media,
            "enabled": str2bool(enable),
            "live_date_time": liveDate === null ? '' : liveDate,
            "expiry": expiryDate === null ? '' : expiryDate,
            "deleteMedia": deleteMedia,
            "storeId":localStorage.getItem("storeId"),
            "isBrandHub":str2bool(continueToggle),
            "deepLink": deepLink,
            "pageId":contentType,
            "internal":internal
        }

        postApi(obj)
        setDs("flex")
    }

    const deleteImg = (e, i, ele) => {
        let newArray = media.filter((ele, index) => {
            return index !== i;
        })
        setDeleteMedia([...deleteMedia, ele]);

        setMedia(newArray)
    }

    const onAdd = async () => {
        let object = new FormData()
        object.append("type", "story")
        object.append("parentId", props.match.params.id)
        object.append('file', subStoryMedia)
        object.append("storeId",localStorage.getItem("storeId"))
        if(pole._id !== " "){
            object.append("poll",pole._id)
            object.append("pollName",pole.name)
        }else{
            object.append("poll","")
            object.append("pollName","none")
        }


        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, object,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(
                res => {
                    if (res.status === 200) {
                        cogoToast.success(res.data.result.message);
                        // window.location.reload()
                        setSubStoryMedia("")
                        setPole("")
                        setEditSub(true)

                    }
                }
            ).catch(err => {
                console.log(error);
            })



    }

    const onClear = () => {
        setName("");
        setHindiLang("")
        setMarathiiLang("")
        setGujaratiLang("")
        setKannadaLang("")
        setTeluguLang("")
        setTamilLang("")
        setPunjabiLang("")
        setMedia("")
    }

    const deleteSubStory = async (id) => {
        await axios.delete(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${id}`,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(
                res => {
                    cogoToast.warn("deleted");
                }
            ).catch(err => {
                console.log(error);
            })
    }

    const onSubStoryImgDelete = (e, index, element) => {
        let newArray = subStory.filter((ele, i) => {
            return i !== index;
        })

        deleteSubStory(element._id)
        setSubStory(newArray)
    }

    const handlePollChange = (e,id,index)=>{
        let data = subStory
        data[index]['poll'] = e.target.value
        setSubStory(data)
        let fd = new FormData()
        fd.append("poll",e.target.value)
        fd.append("storeId",localStorage.getItem("storeId"))
        axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${id}`,fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(
                res => {
                    cogoToast.success(res.data.result.message);
                    // window.location.reload();
                    setEditSub(true)
                }
            ).catch(err => {
                console.log(error);
            })
    }

    useEffect(()=>{
        if(editSub === true){
            fetchstoryByID()
            setEditSub(false)
        }
    },[editSub])

    


    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div >
                    <h1 className="text-center mb-4">Edit Story</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputFieldBucket} width="25" required label="Name" name="name" value={englishLang} onChange={e => setEnglishLang(e.target.value)} >
                            </TextField>
                            <Button style={{ height: "56px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Live Date time" className={classes.inputField} value={liveDate} onChange={e => setLiveDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!liveDate}
                                    onClick={() => setLiveDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Expiry Date time" className={classes.inputField} value={expiryDate} onChange={e => setExpiryDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!expiryDate}
                                    onClick={() => setExpiryDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={enable} onChange={enhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>

                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Media
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={e => setMedia([...media, ...e.target.files])}
                                />
                            </Button>
                            {media && media.length > 0 ?
                                (media.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/story/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele} </p><CancelIcon style={{ cursor: "pointer" }} onClick={(e) => deleteImg(e, i, ele)} /></div>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
            Is BrandHub :&nbsp;<Switch 
            onChange={handleChangeToggle} value={continueToggle} checked={continueToggle}
             />
                </Grid>
                <Grid item md={6} sm={12}>
                            <FormControl style={continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Internal</FormLabel>
                                <RadioGroup aria-label="internal" name="internal" value={internal} onChange={internalhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid style={internal === "true" && continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <InputLabel id="demo-simple-select-outlined-label">Pages</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={contentType}
                                    onChange={(e) => setContentType(e.target.value)}
                                    label="Content type"
                                >
                                    {
                                        pages && pages.length > 0 ?
                                            pages.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.pageCode}>{element.iname}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No Content Type</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid style={internal === "false" && continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Deep Link" name="DeepLink" value={deepLink} onChange={e => setDeepLink(e.target.value)} ></TextField>
                        </Grid>
                        <Grid item md={12}>
                            <Button className="mt-4 mr-4" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button className="mt-4 mr-4" variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                        <div>
                            <h3 className="mt-5">SubStory</h3>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Story Name</TableCell>
                                            {/* <TableCell>Enabled</TableCell> */}
                                        </TableRow>
                                    </TableHead>


                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{name}</TableCell>
                                            {/* <TableCell>{responce.enabled ? responce.enabled.toString() : null}</TableCell> */}
                                        </TableRow>
                                    </TableBody>


                                </Table>
                            </TableContainer>
                            <Grid className="mt-5" container >
                                <Grid item md={6} sm={12}>
                                    <Button
                                        className={classes.upload}
                                        variant="outlined"
                                        component="label"

                                    >
                                        Media
                                        <input
                                            multiple
                                            type="file"
                                            hidden
                                            onChange={e => setSubStoryMedia(e.target.files[0])}
                                        />
                                    </Button>
                                    {subStoryMedia ?
                                        <p className="mt-2 mr-4">{subStoryMedia.name} <CancelIcon onClick={() => setSubStoryMedia("")} /></p>
                                        : null}
                                </Grid>

                                <Grid item md={4}>
                                    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: "60%",marginLeft:"-100px" }}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Activity</InputLabel>

                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={pole}
                                                        onChange={(e) => setPole(e.target.value)}
                                                        label="Polls"
                                                    >
                                                        {
                                                            poleList && poleList.length > 0 ?
                                                            poleList.map((element, id) => {

                                                                    return ( 
                                                                        <MenuItem  value={element}>{element.name}</MenuItem>
                                                                    )
                                                                }) :
                                                                <MenuItem disabled value="no pages">No app</MenuItem>

                                                        }

                                                    </Select>

                                                </FormControl>
                                                <Button className="mt-4 ml-3" variant="contained" color="primary" onClick={onAdd}>Add</Button>

                                        
                                   


                                </Grid>

                            </Grid>
                            <List className={classes.liststyle}>
                                {subStory && subStory.length>0?
                                    subStory.map((element, index) => (

                                        <ListItem key={index}>

                                            <ListItemText onClick={() => window.open(element.name ? null : `https://storage.googleapis.com/media-content-hungama-com/story/${element.media[0]}`, "_blank")} style={{ cursor: "pointer" ,maxWidth:"100%"}}>{element.media[0]} </ListItemText>
                                            <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: "30%",marginLeft:"40px" }}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Activiy</InputLabel>

                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={element.poll}
                                                        onChange={(e) => handlePollChange(e,element._id,index)}
                                                        label="Activity"
                                                    >
                                                        {
                                                            poleList && poleList.length > 0 ?
                                                            poleList.map((element, id) => {

                                                                    return ( 
                                                                        <MenuItem  value={element._id}>{element.name}</MenuItem>
                                                                    )
                                                                }) :
                                                                <MenuItem disabled value="no pages">No app</MenuItem>

                                                        } 

                                                    </Select>

                                                </FormControl>

                                            <ListItemSecondaryAction>
                                                
                                                <IconButton edge="end" aria-label="delete" onClick={(e) => onSubStoryImgDelete(e, index, element)}>

                                                    <DeleteForeverOutlinedIcon
                                                        className={classes.btndelete}

                                                    />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )) : null}
                            </List>
                        </div>


                    {/* Modal */}
                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                </div>
            </Paper>
        </Fragment>
    )
}
