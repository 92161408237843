import React, { Fragment, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Divider,
    Toolbar,
    InputBase
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useStyles } from '../../../styles/style';
import './../../../styles/collectionStyle.css';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import cogoToast from 'cogo-toast';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function AddCollection(props) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [stencil, setStencil] = useState("");
    const [background, setBackground] = useState("");
    const [logo, setLogo] = useState("");
    const [detailTitle, setDetailTitle] = useState("")
    const [detailSubTitle, setDetailSubTitle] = useState("")
    const [detailStencil, setDetailStencil] = useState("");
    const [detailBackground, setDetailBackground] = useState("");
    const [checked, setChecked] = React.useState([]);
    const [searched, setSearched] = useState("")

    const postCollection = (data) =>{
        const fd = new FormData();
        fd.append("mainTitle", data.mainTitle);
        fd.append("mainSubtitle", data.mainSubtitle);
        fd.append("detailTitle", data.detailTitle);
        fd.append("detailSubtitle", data.detailSubtitle);
        fd.append("storeId", data.storeId);
        for (let index = 0; index < data.mainStencil.length; index++) {
            const element = data.mainStencil[index];
            fd.append('mainStencil', element)
        }
        for (let index = 0; index < data.mainBackground.length; index++) {
            const element = data.mainBackground[index];
            fd.append('mainBackground', element)
        }
        for (let index = 0; index < data.mainLogo.length; index++) {
            const element = data.mainLogo[index];
            fd.append('mainLogo', element)
        }
        for (let index = 0; index < data.detailBackground.length; index++) {
            const element = data.detailBackground[index];
            fd.append('detailBackground', element)
        }
        for (let index = 0; index < data.detailStencil.length; index++) {
            const element = data.detailStencil[index];
            fd.append('detailStencil', element)
        }
        data.bucketIds.map((ele, i) => {
            fd.append(`bucketIds[${i}]`, ele)
        })

        axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/collection`, fd)
        .then(
            res => {
                console.log(res)
                cogoToast.success(res.data.message)
            }
        ).catch(err => {
            console.log(err);
        })

}
    

    const onSubmit = () => {
        let bucketIds = left.map(ele=>{
            return ele._id
        })
        let obj = {
            mainTitle:title,
            mainSubtitle:subTitle,
            mainBackground:background,
            mainStencil:stencil,
            mainLogo:logo,
            detailTitle:detailTitle,
            detailSubtitle:detailSubTitle,
            detailBackground:detailBackground,
            detailStencil:detailStencil,
            bucketIds:bucketIds,
            "storeId":localStorage.getItem("storeId")
        }
        setTitle("");
        setSubTitle("");
        setSubTitle("");
        setStencil("")
        setBackground("")
        setLogo("")
        setDetailTitle("")
        setDetailSubTitle("")
        setDetailStencil("")
        setDetailBackground("")
        setLeft([])
        setRight([])

        postCollection(obj)
    }

    const clearData = () =>{
        setTitle("");
        setSubTitle("");
        setSubTitle("");
        setStencil("")
        setBackground("")
        setLogo("")
        setDetailTitle("")
        setDetailSubTitle("")
        setDetailStencil("")
        setDetailBackground("")
        setLeft([])
        setRight([])
    }

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(left);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setLeft(items)
    }


    const bucketList = (title, items) => (

        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list" >
                {items.map((value, index) => {
                    const labelId = `${value._id}`;

                    return (
                        <ListItem role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                            <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.names[0].en} />

                        </ListItem>

                    );
                })}
            </List>
        </Card>
    );

    const Genre = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext >
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value._id}`;

                                return (
                                    <Draggable key={value._id} draggableId={value._id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button  onClick={handleToggle(value)}  {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.names[0].en} />

                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );

    const deleteStencil = (e, i) => {
        let newArray = stencil.filter((ele, index) => {
            return index !== i;
        })

        setStencil(newArray)
    }

    const deleteBackground = (e, i) => {
        let newArray = background.filter((ele, index) => {
            return index !== i;
        })

        setBackground(newArray)
    }

    const deleteLogo = (e, i) => {
        let newArray = logo.filter((ele, index) => {
            return index !== i;
        })

        setLogo(newArray)
    }

    const deleteDetailBackground = (e, i) => {
        let newArray = detailBackground.filter((ele, index) => {
            return index !== i;
        })

        setDetailBackground(newArray)
    }

    const deleteDetailStencil = (e, i) => {
        let newArray = detailStencil.filter((ele, index) => {
            return index !== i;
        })

        setDetailStencil(newArray)
    }

    const onBucketSearch = ()=>{
        axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/bucket?name=${searched}?storeId=${localStorage.getItem("storeId")}`)
        .then(
            res => {
                // console.log(res.data.result.result)
                let object = res.data.result.result.filter(ele => {
                    ele.names = JSON.parse(ele.names)
                    return ele
                })
                setRight(object)
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }


    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div>
                    <h1 className="text-center">Add Collection</h1>

                    <Grid container className="text-center mt-5">
                        <Grid item md={6} style={{ border: "1px solid", marginLeft: "25px", marginTop: "10px", padding: "10px" }}>
                            <h1 className="borderTitle"><span>Bucket Rail</span></h1>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Title" value={title} onChange={(e) => setTitle(e.target.value)}>
                            </TextField>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Sub-Title" value={subTitle} onChange={(e) => setSubTitle(e.target.value)}>
                            </TextField>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Stencil
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setStencil([...stencil, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}

                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                : null}
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Background
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setBackground([...background, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {background.length > 0 ?
                                (background.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                : null}
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Logo
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setLogo([...logo, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {logo.length > 0 ?
                                (logo.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteLogo(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={5} style={{ border: "1px solid", marginLeft: "15px", marginTop: "10px", padding: "10px", }}>
                            <h1 className="borderTitle"><span>Detail Screen</span></h1>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Title" value={detailTitle} onChange={(e) => setDetailTitle(e.target.value)}>
                            </TextField>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Sub-Title" value={detailSubTitle} onChange={(e) => setDetailSubTitle(e.target.value)}>
                            </TextField>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Stencil
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setDetailStencil([...detailStencil, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {detailStencil.length > 0 ?
                                (detailStencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteDetailStencil(e, i)} /></p>))
                                : null}
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Background
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setDetailBackground([...detailBackground, ...e.target.files])}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {detailBackground.length > 0 ?
                                (detailBackground.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteDetailBackground(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root} style={{ marginTop: "25px", border: "1px solid", margin: "20px" }}>
                            <h1 className="borderTitle2"><span>Buckets</span></h1>
                            <Grid item md={12} sm={12}>
                                <Toolbar>
                                    <div className={classes.Collectionsearch}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.CollectioninputInput,
                                            }}
                                            value={searched}
                                            onChange={(e) => setSearched(e.target.value)}
                                            onKeyDown={onBucketSearch}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                </Toolbar>

                            </Grid>
                            <Grid item md={5}>

                                {Genre('selected Buckets', left)}
                            </Grid>

                            <Grid item md={2}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item md={5}>
                                {bucketList('Buckets', right)}


                            </Grid>
                        </Grid>

                    </Grid>





                    <Grid container className="text-center">
                        <Grid item md={12} className="mt-4">
                            <Button className="mr-2" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={clearData}>Clear</Button>

                        </Grid>

                    </Grid>
                </div>

            </Paper>
        </Fragment>
    )
}
