import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    makeStyles,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputBase,
    Tooltip,
    IconButton

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import {RefreshOutlined} from '@material-ui/icons';
import MultiLanguageModal from '../Reusable/multiLanguageModal';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));


export default function AddBannerCollection(props) {
    const classes1 = useStyles();
    const classes = useStyle();
    const [name, setName] = useState("");
    const [checked, setChecked] = useState([]);
    const [selected, setselected] = React.useState([]);
    const [stories, setStories] = React.useState([]);
    const [searched, setSearched] = useState("")
    const [fixed, setFixed] = useState(false)

    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");
    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);

    const fetchBanner = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    console.log(res.data.result)
                    setStories(res.data.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const searchStory = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {

                    setStories(res.data.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        fetchBanner()
    }, [])

    const leftChecked = intersection(checked, selected);
    const rightChecked = intersection(checked, stories);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setStories(stories.concat(leftChecked));
        setselected(not(selected, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setselected(selected.concat(rightChecked));
        setStories(not(stories, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(selected);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setselected(items)
    }


    const searchContent = (e) => {
        if(e.key === "Enter")searchStory(searched)
    }

    const deleteLeftData =() =>{
        setselected(not(selected, leftChecked));
        setChecked(not(checked, leftChecked));
    }

    const refreshList = () =>{
        fetchBanner()
    }

    const storyList = (title, items) => (

        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="Refresh">
                    <IconButton aria-label="delete" onClick={refreshList}>
                        <RefreshOutlined />
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <div className={classes1.contentSearch}>
                <div className={classes1.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes1.inputRoot,
                        input: classes1.inputInput,
                    }}
                    value={searched}
                    onChange={(e) => setSearched(e.target.value)}
                    onKeyDown={(e) => searchContent(e)}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>
            <List className={classes.list} dense component="div" role="list" >
                {items.map((value, index) => {
                    const labelId = `${value._id}`;

                    return (
                        <ListItem role="listitem" button onClick={handleToggle(value)} >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} >{value.title?value.title:null}</ListItemText>

                        </ListItem>

                    );
                })}
            </List>
        </Card>
    );

    const selectedStoryList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="delete">
                    <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                        <DeleteForeverOutlinedIcon className={leftChecked.length === 0?null:classes1.btndelete}/>
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value._id}`;

                                return (
                                    <Draggable key={value._id} draggableId={value._id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.title?value.title:null} />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );

    const postCategoryBucket = async (data) => {
        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/bannerList`, data).then(res => {
            cogoToast.success("Success")
        }).catch(err => {
            console.log(err)
            cogoToast.error("Something Went Wrong!")
        })
    }

    const onSubmit = () => {
        let categoryItems = selected.map((ele, i) => {
            let obj = {
                "sequence": i,
                "banner": ele._id
            }
            return obj
        })

        let object = {
            // name: JSON.stringify({
            //     hi: hindiLang,
            //     mr: marathiLang,
            //     en: englishLang,
            //     kn: kannadaLang,
            //     te: teluguLang,
            //     pa: punjabiLang,
            //     ta: tamilLang,
            //     gu: gujaratiLang,
            //     or:odisaLang,
            //     ur:urduLang,
            //     bn:bengaliLang,
            //     ml:malayalamLang,
            //     si:sinhalaLang,
            //     ar:arabicLang,
            //         af:afganiLang,
            //         bur:burmeseLang,
            //         khm:khmerLang
            // }),
            name:englishLang,
            detail: categoryItems,
            storeId : localStorage.getItem("storeId")
        }
        // console.log(object)
        postCategoryBucket(object)
        setselected([])
        setName("")
        fetchBanner()
    }


    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add Banner Collection</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes1.inputField} width="25" required label="Name" name="Name" value={englishLang} onChange={(e) => { setEnglishLang(e.target.value) }} ></TextField>
                            {/* <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button> */}

                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        <Grid item>
                            <h4>Selected</h4>
                            {selectedStoryList('Choices', selected)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item><h4>Banner</h4>{storyList('Chosen', stories)}


                        </Grid>

                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" >Clear</Button>

                        </Grid>

                    </Grid>
                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                </div>

            </Paper>


        </Fragment>
    )
}
