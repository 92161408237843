import React, { Fragment, useState,useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    MenuItem

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import {content_type} from './../bucket/objects'



export default function CreateVarient(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [v0, setv0] = useState([]);
    const [v1, setv1] = useState([]);
    const [v2, setv2] = useState([]);
    const [v3, setv3] = useState([]);
    const [v4, setv4] = useState([]);
    const [v5, setv5] = useState([]);
    const [v6, setv6] = useState([]);
    const [v7, setv7] = useState([]);
    const [v8, setv8] = useState([]);
    const [v9, setv9] = useState([]);
    const [v10, setv10] = useState([]);
    const [contents, setContents] = useState("");
    const [contentId, setContentId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [lang, setLang] = useState("Default")
    const listVarient = ["v0","v1", "v2", "v3", "v4", "v5", "v6"];
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])

    const initialvalues = {
        contentId: "",
    }
    const [state, setState] = useState(initialvalues)

    const handlev0 = (e) => {
        setv0([...v0, ...e.target.files])
    }
    const handlev1 = (e) => {
        setv1([...v1, ...e.target.files])
    }
    const handlev2 = (e) => {
        setv2([...v2, ...e.target.files])
    }
    const handlev3 = (e) => {
        setv3([...v3, ...e.target.files])
    }
    const handlev4 = (e) => {
        setv4([...v4, ...e.target.files])
    }
    const handlev5 = (e) => {
        setv5([...v5, ...e.target.files])
    }
    const handlev6 = (e) => {
        setv6([...v6, ...e.target.files])
    }
    const handlev7 = (e) => {
        setv7([...v7, ...e.target.files])
    }
    const handlev8 = (e) => {
        setv8([...v8, ...e.target.files])
    }
    const handlev9 = (e) => {
        setv9([...v9, ...e.target.files])
    }
    const handlev10 = (e) => {
        setv10([...v10, ...e.target.files])
    }
   

    useEffect(() => {
        fetchLanguage();
    }, [])

    const onHandleChange = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setName(e.target.value)
        setState({
            ...state,
            [e.target.name]: value
        })

    }
    const onEnterContentId = (e) => {
        if (contentId !== "" && e.key === "Enter" || e.key === "Tab") {
            axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/solr/${contentId}`).then(res => {
                console.log(res.data)
                setDisplayName(res.data.data.response.docs[0].name)
            }).catch(err => {
                console.log(err)
                cogoToast.error("Wrong ContentId")
            })
        }


    }
    const postVariant = async (data) => {
        const fd = new FormData();
        fd.append("type", data.type);
        fd.append("name", data.name);
        fd.append("storeId", data.storeId);
        for (let index = 0; index < data.v0.length; index++) {
            const element = data.v0[index];
            fd.append('v0', element)
        }
        for (let index = 0; index < data.v2.length; index++) {
            const element = data.v2[index];
            fd.append('v2', element)
        }
        for (let index = 0; index < data.v3.length; index++) {
            const element = data.v3[index];
            fd.append('v3', element)
        }
        
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/recomPlaylistContent`, fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                if(res.data.result.message == "Content Id Exists"){
                    cogoToast.error(res.data.result.message);
                   }
                   else{
                    cogoToast.success(res.data.result.message);
                   }
            }).catch(err => {
                cogoToast.error(err.response.data.message)
            })
    }


    const onSubmit = () => {
        const obj = {
            "type": "playlistVariant",
            "v0": v0,
            "v2": v2,
            "v3": v3,
             name: name,
            "storeId":localStorage.getItem("storeId")   
        }
        postVariant(obj)
        console.log(obj)
        setState(initialvalues)
        setv0([])
        setv2([])
        setv3([])
        setName('')
    }

    const onClear = () => {
        setState(initialvalues)
        setv0([])
        setv2([])
        setv3([])
        setName('')
    }
    const deletev0 = (e, i) => {
        let newArray = v0.filter((ele, index) => {
            return index !== i;
        })

        setv0(newArray)
    }
    const deletev2 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv2(newArray)
    }
    const deletev3 = (e,i) => {

        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })

        setv3(newArray)
    }
   
    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setLangList([...langList, ...res.data.result.result])
            console.log('lang',res.data.result.result);
        }).catch(
            err => {
                console.log(err)
            }
        )
    }


    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add RecomplayList</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Playlist Name" name="playlistName" value={name} onChange={onHandleChange}></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                v0
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev0}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v0.length > 0 ?
                                (v0.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev0(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v2
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev2}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v2.length > 0 ?
                                (v2.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev2(e, i)} /></p>))
                                : null}

                        </Grid>
                         <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v3
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev3}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v3.length > 0 ?
                                (v3.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev3(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
