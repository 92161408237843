import React from 'react'
import {
    Grid,
    TextField,
    FormControl,
    Button,
    MenuItem,
    InputLabel,
    Select,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    TableBody,

} from '@material-ui/core';

import { useStyles } from '../../styles/style';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";


export default function BucketQuery(props) {
    const classes = useStyles();

    const tempLogic = (element) => {
        return element.replace('AND', '')
    }

    props.query.map((ele,index)=>{
        console.log(props.andOr)
    })

    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid item md={2} sm={3} >
                        <FormControl className={classes.formModal} style={{ maxWidth: "180px" }}>
                            <InputLabel id="demo-simple-select-label">Fields</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.option1}
                                onChange={props.optionOneHandleChange
                                }

                            >{props.fields.map(ele => (
                                <MenuItem value={ele}>{ele.Description}</MenuItem>

                            ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} sm={2}>

                        <FormControl className={classes.formModal}>
                            <InputLabel id="demo-simple-select-label">Operators</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"


                                value={props.option2}
                                onChange={props.optionTwoHandleChange}
                            >{props.operators.map(ele => (
                                <MenuItem value={ele.value}>{ele.Description}</MenuItem>

                            ))}

                            </Select></FormControl>
                    </Grid>
                    {props.option1.value === "artist" || props.option1.value === "contentId" || props.option1.value === "contentName" || props.option1.value === "lang" || props.option1.value === "genre" ?
                        <Grid item md={4}>

                            <TextField
                                style={{ width: "100%", marginTop: "20px" }}
                                margin="dense"
                                id="standard-basic"
                                name="textRef"
                                variant="outlined"
                                value={props.text.Description ? props.text.Description : props.text}
                                inputRef={props.textRef}
                                onChange={props.onTextChange}

                            />
                        </Grid>
                        : props.option1.Description === "Content Type" ?
                            <Grid item md={4}>
                                <FormControl className={classes.formModal} style={{ width: "100%", marginTop: "26px" }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={props.optionThreeHandleChange}
                                    >{props.queryContentType.map(ele => (
                                        <MenuItem value={ele}>{ele.Description}</MenuItem>

                                    ))
                                        }
                                    </Select></FormControl></Grid> :
                            props.option1.Description === "Tags" ?
                                <Grid item md={4}>
                                    <FormControl className={classes.formModal} style={{ width: "100%", marginTop: "26px" }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={props.optionThreeHandleChange}
                                        >{props.tags.map(ele => (
                                            <MenuItem value={ele}>{ele.Description}</MenuItem>

                                        ))
                                            }
                                        </Select></FormControl></Grid>
                                : null}

                    <Grid item md={4} alignItems="flex-end" alignContent="flex-end" >
                        <Button className={classes.modalAdd} style={{ marginLeft: "43px", marginTop: "23px" }} variant="contained" color="primary" onClick={props.onSubmitquery}>
                            Add
                        </Button>
                        <Button className={classes.modalAdd} style={{ marginTop: "23px" }} variant="contained" color="primary" onClick={props.onSerachQuery}>
                            search
                        </Button>
                    </Grid>
                    <Grid item md={12} sm={12} >
                        {/* <List className={classes.liststyle}> */}
                        {props.query && props.query.length > 0 ?
                        <TableContainer>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Query</TableCell>
                                    <TableCell>AndOR</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                    {props.query.map((element, index) => (
                                        

                                        <TableRow key={index}>
                                            <TableCell>{element.field.Description} {element.operators} {element.text.Description ? element.text.Description : element.text}</TableCell>
                                            <TableCell>
                                                <FormControl className={classes.formModal} style={{ marginTop: "6px", marginLeft: "10px" }}>
                                                    {props.andOr && props.andOr[index] &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={props.andOr[index].AND_OR}
                                                        onChange={(e) => props.onAndOrChange(e, index)}
                                                    >
                                                        <MenuItem value="AND">AND</MenuItem>
                                                        <MenuItem value="OR">OR</MenuItem>
                                                    </Select>
                                                    }
                                                    </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <DeleteForeverOutlinedIcon
                                                    className={classes.btndelete}
                                                    onClick={() => props.ondeleteQuery(index)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                   
                                {/* </List> */}
                            </TableBody>
                        </TableContainer>
                        : null}


                    </Grid>
                </Grid>
            </div>
        </>
    )
}
