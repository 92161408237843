
import React, { Fragment, useState, useEffect } from "react";
import {
  TablePagination,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  Box,
  CircularProgress,
  Toolbar,
  InputBase,
  TableSortLabel,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useStyles } from "../../styles/style";
import { Link } from 'react-router-dom';
import axios, { AxiosRequestConfig } from 'axios';
import cogoToast from 'cogo-toast';
import DeleteModal from '../Reusable/deleteModal';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyModal from '../Reusable/copyModal';

export default function ListVariant(props) {

  const classes = useStyles();
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [removeModal, setRemoveModal] = useState(false)
  const [deleteId, setDeleteId] = useState("");
  const [searched, setSearched] = useState("");

  const [langList, setLangList] = useState([])
  const [lang, setLang] = useState("");
  const [content, setContent] = useState("")
  const [contentType,setcontentType] = useState("");
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [copyModal, setCopyModal] = useState(false)
  const [copyId, setCopyId] = useState("")
  const toggle = () => setRemoveModal(!removeModal);
  const toggle1 = () => setCopyModal(!copyModal);
  const deleteGenre = async (id) => {
    await axios.delete(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/contents/deleteCsvData/${deleteId}`).then(
      res => {
        console.log(res);
        let newData = data.filter(ele=>{
          return ele._id !== deleteId;
        });
        setData(newData);
        setDeleteId("");
        // window.location.reload(false);
      }
    ).catch(err => {
      console.log(err);
    })
  }
  const copyPage = async (id) => {
    // console.log('id',id,copyId);
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/page/id/${copyId}?storeId=${id}`).then(
      res=>{
        console.log(res);
        if(res.data.result.message == "success"){
        setCopyId("")
        fetchData()
        cogoToast.success(res.data.result.message);
        }
        else{
          cogoToast.error("Failed");
        }
        // window.location.reload(false);
      }
    ).catch(err=>{
      console.log(err);
    })
  }
  const fetchLanguage = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
        setLangList([...langList, ...res.data.result.result])
    }).catch(
        err => {
            console.log(err)
        }
    )
}

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/contents/getCsvData?storeId=${localStorage.getItem("storeId")}`)
      .then(res => {
        console.log(res)
        setData(res.data.result)
        setIsLoading(false);

      }).catch(err => {
        cogoToast.error("Bad Request")
      })
  }

  const searchTopNav = async (data) => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/variant/${data}`)
        .then(res => {
            let object  = res.data.result.result.filter(ele => {
                return !ele.parentId
            })
            setData(object)
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })
}
  // useEffect(()=>{
  //   if(!removeModal){
  //     console.log("updated")
  //   fetchData();

  //   }
    
  // },[removeModal]);

  useEffect(() => {
    // fetchLanguage()
    fetchData()
  }, [])

  useEffect(() => {
    if (searched === "") {
      fetchData()
    }
}, [searched])

const searchContent = () => {
  searchBucket(id,content,name,lang)
  setIsLoading(true)
}
const exportData = async () =>{
  const headers = {'Content-Type': 'blob', responseType: 'arraybuffer'};
  // const config: AxiosRequestConfig = {method: 'GET', url:`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/contents/downloadExcel?storeId=${localStorage.getItem("storeId")}`, responseType: 'arraybuffer', headers};
  try {
      // const response = await axios(config);
    const response =await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/contents/downloadExcel?language=${lang}&storeId=${localStorage.getItem("storeId")}`, headers)

      const outputFilename = `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
  } catch (error) {
      throw Error(error);
  } 
   // await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/contents/downloadExcel?language=${lang}&storeId=${localStorage.getItem("storeId")}`)
  //   .then(
  //     res => {
  //       console.log(res.data.result);
  //       // setData(res.data.result)
  //       setIsLoading(false)
  //       // console.log(res.data.result.result.message);
  //       // cogoToast.success(res.data.result.result.message)
  //     }
  //   ).catch(
  //     err => {
  //       console.log(err);
  //       setIsLoading(false)
  //     }
  //   )
}
const onClear = () => {
  fetchData();
  setId("");
  setName("");
  setContent("");
  setcontentType("");
  setLang("")
}
const searchBucket = async (id,content,name,lang) => {
  await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/contents/searchCsvData?keyword=${content}&language=${lang}&storeId=${localStorage.getItem("storeId")}`)
    .then(
      res => {
        // let data = res.data.result.result.message !== "No Data found!" ?res.data.result.result :[];
        console.log(res.data.result);
      //   let object  = res.data.result.filter(ele => {
      //     return !ele.id
      // })
        setData(res.data.result)
        setIsLoading(false)
        // console.log(res.data.result.result.message);
        cogoToast.success('success')
      }
    ).catch(
      err => {
        console.log(err);
        setIsLoading(false)
      }
    )
}
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(0);
  };
  return (
    <Fragment>
      <Paper component={Box} p={4}>
        <h1>List Keywords</h1>
        <Grid container className="mt-3 mb-3"></Grid>
        <Grid item md={3}>
            <TextField variant="outlined" value={content} onChange={(e)=>setContent(e.target.value)} width="25"  label="Keyword" name="name"></TextField>
          </Grid >
          <Grid item md={5}>
          <Button color="primary" variant="contained" onClick={searchContent} className="mt-2">Search</Button>{" "}
          <Button color="secondary" variant="contained" className="mt-2" onClick={onClear} >Clear</Button>{" "}
          <Button color="primary" variant="contained" className="mt-2" onClick={exportData} >Export</Button>
          </Grid>
        {/* <Grid container className="mt-3 mb-3">
          <Grid item md={3}>
            <TextField variant="outlined" value={id} onChange={(e)=>setId(e.target.value)} width="25"  label="Id" name="name"></TextField>
          </Grid >
          <Grid item md={3}>
            <TextField variant="outlined" value={content} onChange={(e)=>setContent(e.target.value)} width="25"  label="Content Id" name="name"></TextField>
          </Grid >
          <Grid item md={3}>
          <TextField variant="outlined" value={name} onChange={(e)=>setName(e.target.value)} width="25"  label="Content Name" name="name"></TextField>
          </Grid>

          <Grid item md={3}>
            <FormControl variant="outlined"  className={classes.formControl} style={{top:-9, marginLeft:"0px"}}>
              <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={lang}
                onChange={(e)=>setLang(e.target.value)}
                label="Language"
              ><MenuItem value="Default">Default</MenuItem>
                {
                  langList && langList.length > 0 ?
                    langList.map((element, id) => {

                      return (
                        <MenuItem key={id} value={element.name}>{element.name}</MenuItem>
                      )
                    }) :
                    <MenuItem disabled value="no pages">No Pages</MenuItem>

                }

              </Select>

            </FormControl>

          </Grid>
          <Grid item md={3}>
            <FormControl variant="outlined"  className={classes.formControl} style={{top:-9, marginLeft:"0px"}}>
              <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={contentType}
                onChange={(e)=>setcontentType(e.target.value)}
                label="Language"
              > 
              <MenuItem key={"55555"} value={"55555"}>Playlist</MenuItem>
              <MenuItem key={"4"} value={"4"}>Movie</MenuItem>     

                

              </Select>

            </FormControl>

          </Grid>
          <Grid item md={3}>
          <Button color="primary" variant="contained" onClick={searchContent} className="mt-2">Search</Button>{" "}
          <Button color="secondary" variant="contained" className="mt-2" onClick={onClear} >Clear</Button>
          </Grid>
        </Grid> */}
        {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={searched}
                            onChange={(e) => setSearched(e.target.value)}
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={(e) => searchContent(e)}
                        />
                    </div> */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                {/* <TableCell>Content Id</TableCell>
                <TableCell>Content Name</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading === true ? <CircularProgress /> :
                data && data.length > 0 ? data
                  .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                  .map((emp, index) => (
                    <TableRow key={index}>
                      <TableCell>{emp._id}</TableCell>
                      <TableCell>{emp.keyword}</TableCell>
                      <TableCell>
                        <DeleteForeverOutlinedIcon
                          className={classes.btndelete}
                          onClick={() => { setDeleteId(emp._id); toggle() }}
                        />
                      </TableCell>
                      {/* <TableCell>{emp.contentId}</TableCell>
                      <TableCell>{emp.contentName}</TableCell> */}
                      {/* <TableCell>
                        <Link to={`/editVarient/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                        <DeleteForeverOutlinedIcon
                          className={classes.btndelete}
                          onClick={() => { setDeleteId(emp._id); toggle() }}
                        />
                      </TableCell>
                      <TableCell>
                      <Tooltip title="Copy Variant"> 
                  <FileCopyIcon style={{ color: "green" }} className={classes.btncopy} 
                  onClick={()=>{setCopyId(emp._id);toggle1()}}
                  />
                   </Tooltip>
                      </TableCell> */}

                    </TableRow>
                  )) : <p className="mt-2 text-center">no data</p>}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 50,100]}
            component="div"
            count={data ? data.length : null}
            rowsPerPage={rowsPerPage}
            page={pagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
        <DeleteModal
          toggle={toggle}
          deleteModal={removeModal}
          deleteContent={deleteGenre}
        />
         <CopyModal
          toggle1={toggle1}
          copyModal={copyModal}
          copyContent={copyPage}
        />
      </Paper>
    </Fragment>
  );

}
