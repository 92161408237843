export const content_type =  [
    // {
    //     "typeid": "0",
    //     "Description": "Artist"
    // },
    // {
    //     "typeid": "1",
    //     "Description": "Audio Album"
    // },
    // {
    //     "typeid": "2",
    //     "Description": "Music Video Album"
    // },
    // {
    //     "typeid": "3",
    //     "Description": "Image Album"
    // },
    // {
    //     "typeid": "4",
    //     "Description": "Movie"
    // },
    // {
    //     "typeid": "6",
    //     "Description": "Games"
    // },
    // {
    //     "typeid": "10",
    //     "Description": "Animation"
    // },
    // {
    //     "typeid": "12",
    //     "Description": "Dramas/Plays"
    // },
    // {
    //     "typeid": "21",
    //     "Description": "Audio Track"
    // },
    // {
    //     "typeid": "22",
    //     "Description": "Music Video Track"
    // },
    // {
    //     "typeid": "26",
    //     "Description": "Awards"
    // },
    // {
    //     "typeid": "39",
    //     "Description": "News"
    // },
    // {
    //     "typeid": "40",
    //     "Description": "Image"
    // },
    // {
    //     "typeid": "47",
    //     "Description": "Slide Show"
    // },
    // {
    //     "typeid": "48",
    //     "Description": "Movie Photo"
    // },
    // {
    //     "typeid": "50",
    //     "Description": "Awards Album"
    // },
    // {
    //     "typeid": "51",
    //     "Description": "Movie Videos"
    // },
    // {
    //     "typeid": "52",
    //     "Description": "Events and Broadcasts Album"
    // },
    // {
    //     "typeid": "53",
    //     "Description": "Events and Broadcasts Video"
    // },
    // {
    //     "typeid": "54",
    //     "Description": "Television"
    // },
    // {
    //     "typeid": "56",
    //     "Description": "Audio Cut"
    // },
    // {
    //     "typeid": "59",
    //     "Description": "Color Logo"
    // },
    // {
    //     "typeid": "60",
    //     "Description": "Mobile Game"
    // },
    // {
    //     "typeid": "61",
    //     "Description": "Game Build"
    // },
    // {
    //     "typeid": "62",
    //     "Description": "Recipe Album"
    // },
    // {
    //     "typeid": "63",
    //     "Description": "Recipe"
    // },
    // {
    //     "typeid": "64",
    //     "Description": "Working Stills"
    // },
    // {
    //     "typeid": "65",
    //     "Description": "HD Movie"
    // },
    // {
    //     "typeid": "66",
    //     "Description": "SD Movie"
    // },
    // {
    //     "typeid": "67",
    //     "Description": "Review"
    // },
    // {
    //     "typeid": "68",
    //     "Description": "Screensaver"
    // },
    // {
    //     "typeid": "69",
    //     "Description": "Content Text"
    // },
    // {
    //     "typeid": "79",
    //     "Description": "Theme"
    // },
    // {
    //     "typeid": "93",
    //     "Description": "Short Films"
    // },
    // {
    //     "typeid": "94",
    //     "Description": "Clips"
    // },
    // {
    //     "typeid": "95",
    //     "Description": "Content Package"
    // },
    // {
    //     "typeid": "96",
    //     "Description": "TV Series"
    // },
    // {
    //     "typeid": "97",
    //     "Description": "TV Series Season"
    // },
    // {
    //     "typeid": "98",
    //     "Description": "TV Series Episode"
    // },
    // {
    //     "typeid": "99",
    //     "Description": "Dialogue"
    // },
    // {
    //     "typeid": "100",
    //     "Description": "Audio Cinema"
    // },
    // {
    //     "typeid": "101",
    //     "Description": "Caricature"
    // },
    // {
    //     "typeid": "106",
    //     "Description": "Audio Clips"
    // },
    // {
    //     "typeid": "107",
    //     "Description": "Linear TV Channel Album"
    // },
    // {
    //     "typeid": "108",
    //     "Description": "Linear TV Channel"
    // },
    // {
    //     "typeid": "109",
    //     "Description": "Podcast Audio Album"
    // },
    // {
    //     "typeid": "110",
    //     "Description": "Podcast Audio Track"
    // },
    // {
    //     "typeid": "12345",
    //     "Description": "seoplaylist"
    // },
    // {
    //     "typeid": "33333",
    //     "Description": "splaylist"
    // },
    // {
    //     "typeid": "44444",
    //     "Description": "theme"
    // },
    // {
    //     "typeid": "55555",
    //     "Description": "playlist"
    // },
    // {
    //     "typeid": "66666",
    //     "Description": "collection"
    // },
    // {
    //     "typeid": "77777",
    //     "Description": "radio"
    // },
    // {
    //     "typeid": "88888",
    //     "Description": "vplaylist"
    // },
    // {
    //     "typeid": "99999",
    //     "Description": "uplaylist"
    // }
    {
        "typeid": "0",
        "Description": "Artist",
        "Used": "Yes"
    },
    {
        "typeid": "112",
        "Description": "Audio Book Album",
        "Used": "Yes"
    },
    {
        "typeid": "113",
        "Description": "Audio Book Season",
        "Used": "Yes"
    },
    {
        "typeid": "114",
        "Description": "Audio Book Episode",
        "Used": "Yes"
    },
    {
        "typeid": "115",
        "Description": "Audio Stories Album",
        "Used": "Yes"
    },
    {
        "typeid": "116",
        "Description": "Audio Stories Season",
        "Used": "Yes"
    },
    {
        "typeid": "117",
        "Description": "Audio Stories Episode",
        "Used": "Yes"
    },
    {
        "typeid": "1",
        "Description": "Audio Album",
        "Used": "Yes"
    },
    {
        "typeid": "21",
        "Description": "Audio Track",
        "Used": "Yes"
    },
    {
        "typeid": "2",
        "Description": "Music Video Album",
        "Used": "Yes"
    },
    {
        "typeid": "22",
        "Description": "Music Video Track",
        "Used": "Yes"
    },
    {
        "typeid": "4",
        "Description": "Movie",
        "Used": "Yes"
    },
    {
        "typeid": "51",
        "Description": "Movie Videos",
        "Used": "Yes"
    },
    {
        "typeid": "52",
        "Description": "Events and Broadcasts Album",
        "Used": "Yes"
    },
    {
        "typeid": "53",
        "Description": "Events and Broadcasts Video",
        "Used": "Yes"
    },
    {
        "typeid": "93",
        "Description": "Short Films",
        "Used": "Yes"
    },
    {
        "typeid": "96",
        "Description": "TV Series",
        "Used": "Yes"
    },
    {
        "typeid": "97",
        "Description": "TV Series Season",
        "Used": "Yes"
    },
    {
        "typeid": "98",
        "Description": "TV Series Episode",
        "Used": "Yes"
    },
    {
        "typeid": "109",
        "Description": "Podcast Audio Album",
        "Used": "Yes"
    },
    {
        "typeid": "110",
        "Description": "Podcast Audio Track",
        "Used": "Yes"
    },
    {
        "typeid": "44444",
        "Description": "theme",
        "Used": "Yes"
    },
    {
        "typeid": "55555",
        "Description": "playlist",
        "Used": "Yes"
    },
    {
        "typeid": "66666",
        "Description": "collection",
        "Used": "Yes"
    },
    {
        "typeid": "77777",
        "Description": "radio",
        "Used": "Yes"
    },
    {
        "typeid": "88888",
        "Description": "vplaylist",
        "Used": "Yes"
    },
    {
        "typeid": "33",
        "Description": "Mood Radio",
        "Used": "Yes"
    },
    {
        "typeid": "15",
        "Description": "Live Radio",
        "Used": "yes"
    },
    {
        "typeid": "35",
        "Description": "On Demand Radio",
        "Used": "yes"
    },
    {
        "typeid": "13",
        "Description": "stories",
        "Used": "Yes"
    },
    {
        "typeid": "14",
        "Description": "Brand",
        "Used": "Yes"
    },
    {
        "typeid": "36",
        "Description": "Artist Radio",
        "Used": "Yes"
    },
    {
        "typeid": "25",
        "Description": "Category",
        "Used": "Yes"
    },
    {
        "typeid": "19",
        "Description": "charts",
        "Used": "Yes"
    },
    {
        "typeid": "16",
        "Description": "collection of collection",
        "Used": "Yes"
    },
    {
        "typeid": "12345",
        "Description": "seoplaylist",
        "Used": "Yes"
    },
    {
        "typeid": "33333",
        "Description": "splaylist",
        "Used": "Yes"
    },
    {
        "typeid": "99999",
        "Description": "uplaylist",
        "Used": "Yes"
    },
    {
        "typeid": "10",
        "Description": "Animation",
        "Used": "Yes"
    },
    {
        "typeid": "105",
        "Description": "Live",
        "Used": "Yes"
    },
    {
        "typeid": "107",
        "Description": "Linear TV Channel Album",
        "Used": "Yes"
    },
    {
        "typeid": "108",
        "Description": "Linear TV Channel",
        "Used": "Yes"
    },
    {
        "typeid": "6",
        "Description": "Games",
        "Used": "Later Stage"
    },
    {
        "typeid": "39",
        "Description": "News",
        "Used": "Later Stage"
    },
    {
        "typeid": "60",
        "Description": "Mobile Game",
        "Used": "Later Stage"
    }
]

export const banner_content_type =  [
    {
        "typeid": "109",
        "Description": "Podcast Audio Album",
        "Used": "Yes"
    },
    {
        "typeid": "110",
        "Description": "Podcast Audio Track",
        "Used": "Yes"
    },
    {
        "typeid": "4",
        "Description": "Movie",
        "Used": "Yes"
    },
    {
        "typeid": "96",
        "Description": "TV Series",
        "Used": "Yes"
    },
    {
        "typeid": "98",
        "Description": "TV Series Episode",
        "Used": "Yes"
    },
    {
        "typeid": "1",
        "Description": "Audio Album",
        "Used": "Yes"
    },
    {
        "typeid": "2",
        "Description": "Music Video Album",
        "Used": "Yes"
    },
    {
        "typeid": "22",
        "Description": "Music Video Track",
        "Used": "Yes"
    },
    {
        "typeid": "93",
        "Description": "Short Films",
        "Used": "Yes"
    },
    {
        "typeid": "105",
        "Description": "Live Event",
        "Used": "Yes"
    },
    {
        "typeid": "55555",
        "Description": "playlist",
        "Used": "Yes"
    },
    {
        "typeid": "19",
        "Description": "charts",
        "Used": "Yes"
    },
    {
        "typeid": "0",
        "Description": "Artist",
        "Used": "Yes"
    },
    {
        "typeid": "35",
        "Description": "hungama gold",
        "Used": "yes"
    },
    {
        "typeid": "21",
        "Description": "Audio Track",
        "Used": "Yes"
    },
    {
        "typeid": "14",
        "Description": "BrandHub",
        "Used": "Yes"
    } 
]

export const game_content_type =  [{
    "typeid": "60",
    "Description": "Mobile Game",
    "Used": "Later Stage"
}]

export const genre = [{ id: 1, value: 'action',Description: "Action" }, { id: 3,  value: 'comedy',Description: "Comedy" }, { id: 3,  value: 'drama',Description: "Drama" }]
export const fields = [
    { id: 1, value: "contentId", Description: "Content Id" },
    { id: 1, value: "contentName", Description: "Content Name" },
    { id: 1, value: "typeid", Description: "Content Type" },
    { id: 2, value: "artist", Description: "Artist" },
    { id: 3, value: "genre", Description: "Genre" },
    { id: 4, value: "lang", Description: "Language" },
    { id: 4, value: "tags", Description: "Tags" },
]
export const operators = [
    { id: 1, value: "Equal", Description: "Equal" },
    { id: 2, value: "Not Equal", Description: "Not Equal" }
]

export const product_type = [
    { id: 1, value: "UN", Description: "UN" },
    { id: 2, value: "HMVi", Description: "HMVi" }
]
export const mode_type = [
    { id: 1, value: "landscape", Description: "landscape" },
    { id: 2, value: "portrait", Description: "portrait" }
]
export const name = [
    {id:1, value:"artist-radio", name:"Radio - Artist"},
    {id:2, value:"live-radio", name:"Radio - Live"},
    {id:3, value:"ondemand-radio", name:"Radio - OnDemand"},
]

export const tags = [{ id: 1, value: 'latest',Description: "Latest" }, { id: 3,  value: 'popular',Description: "Popular" }, { id: 3,  value: 'recent',Description: "Recent" }]