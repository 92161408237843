import React, { Fragment, useState, useEffect } from "react";

import {
  TablePagination,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  Box,
  CircularProgress,
  InputBase,
  Toolbar
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import axios from 'axios';
import DeleteModal from './../Reusable/deleteModal';
import SearchIcon from '@material-ui/icons/Search';

export default function ListNavigationCollection(props) {
    const classes = useStyles();
    const [pagination, setPagination] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [searched, setSearched] = useState("")
  
  
    const toggle = () => setDeleteModal(!deleteModal);
    const deleteGenre = async (id) => {
      await axios.delete(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${deleteId}`).then(
        res => {
          console.log(res);
          let newData = data.filter(ele=>{
            return ele._id !== deleteId;
          });
          setData(newData);
          setDeleteId("")
        }
      ).catch(err => {
        console.log(err);
      })
    }
  
  
    const fetchData = async () => {
      await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/topNavCollection?storeId=${localStorage.getItem("storeId")}`)
        .then(res => {
          setData(res.data.result.result)
          setIsLoading(false);
  
        }).catch(err => {
          console.log(err)
        })
    }
  
    useEffect(() => {
      if(searched===""){
        fetchData()
      }
    }, [searched])
  
    const handleChangePage = (event, newPage) => {
      setPagination(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPagination(0);
    };
    const searchStory = async (data) => {
      await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/topNavCollection/${data}?storeId=${localStorage.getItem("storeId")}`)
          .then(
              res => {
                  setData(res.data.result.result)
              }
          ).catch(
              err => {
                  console.log(err);
              }
          )
  }
    const searchContent = (e) =>{
      if(e.key === "Enter")searchStory(searched)
    }
    return (
      <Fragment>
        <Paper component={Box} p={4}>
          
          <Toolbar>
          <h1>List Navigation Collection</h1>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={searched}
                onChange={(e)=>setSearched(e.target.value)}
                inputProps={{ 'aria-label': 'search' }}
                onKeyDown={(e) => searchContent(e)}
              />
            </div>
            </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading == true ? <CircularProgress /> :
                  data && data.length > 0 ? data
                    .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                    .map((emp, index) => (
                      <TableRow key={index}>
                        <TableCell>{emp._id}</TableCell>
                        <TableCell>{emp.name}</TableCell>
                        <TableCell>
                          <Link to={`/editNavigationCollection/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                          <DeleteForeverOutlinedIcon
                            className={classes.btndelete}
                            onClick={() => { setDeleteId(emp._id); toggle() }}
                          />
  
                        </TableCell>
                      </TableRow>
                    )) : <p className="mt-2 text-center">no data</p>}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 50,100]}
              component="div"
              count={data ? data.length : null}
              rowsPerPage={rowsPerPage}
              page={pagination}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
          <DeleteModal
            toggle={toggle}
            deleteModal={deleteModal}
            deleteContent={deleteGenre}
          />
        </Paper>
      </Fragment>
    );
  
}
