
import React, { Fragment, useState, useEffect } from "react";

import {
  TablePagination,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  Box,
  CircularProgress,
  Toolbar,
  InputBase,
  TableSortLabel,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import axios from 'axios';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import cogoToast from 'cogo-toast';
import SearchIcon from '@material-ui/icons/Search';
import DeleteModal from './../Reusable/deleteModal';
import CopyModal from './../Reusable/copyModal';
import './css/bucket.css'
import { content_type } from './objects'
import { game_content_type } from './objects'


export default function ListBucket(props) {

  const classes = useStyles();
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searched, setSearched] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState("")
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [langList, setLangList] = useState([])
  const [lang, setLang] = useState("");
  const [content, setContent] = useState("")
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [copyModal, setCopyModal] = useState(false)
  const [copyId, setCopyId] = useState("")

  const toggle = () => setDeleteModal(!deleteModal);
  const toggle1 = () => setCopyModal(!copyModal);
  const deleteBucket = async (id) => {
    await axios.delete(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${deleteId}`).then(
      res => {
        console.log(res);
        let newData = data.filter(ele => {
          return ele._id !== deleteId;
        });
        setData(newData);
        setDeleteId("")
      }
    ).catch(err => {
      console.log(err);
    })
  }
  const copyPage = async (id) => {
    // console.log('id',id,copyId);
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/bucket/${copyId}/clone?storeId=${id}`).then(
      res=>{
        console.log(res);
        if(res.data.message == "success"){
          setCopyId("")
          fetchData()
          cogoToast.success(res.data.message);
        }
        else{
          cogoToast.error("Failed");
        }
        
        // window.location.reload(false);
      }
    ).catch(err=>{
      console.log(err);
    })
  }
  const fetchLanguage = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
      setLangList(res.data.result.result)
    }).catch(
      err => {
        console.log(err)
      }
    )
  }
  
  const searchBucket = async (id,name,lang, content) => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/bucket?name=${name}&contentType=${content}&language=${lang}&_id=${id}&storeId=${localStorage.getItem("storeId")}`)
      .then(
        res => {
          let data = res.data.result.result.message !== "No Data found!" ?res.data.result.result :[];
          let object = data.filter(ele => {
            if(typeof(ele.names) === "string"){
              ele.names = JSON.parse(ele.names)
              return ele
            }else{
              return ele
            }
            
          })
          setData(object)
          setIsLoading(false)
          console.log(res.data.result.result.message);
          cogoToast.success(res.data.result.result.message)
        }
      ).catch(
        err => {
          console.log(err);
          setIsLoading(false)
        }
      )
  }

  // useEffect(() => {
  //   if (searched === "") {
  //     fetchData()
  //   }
  // }, [searched])

  useEffect(() => {
    fetchLanguage();
    fetchData();
  }, [])

  const onClear = () => {
    fetchData();
    setId("");
    setName("");
    setContent("");
    setLang("")
  }

  const fetchData = async () => {
    if(localStorage.getItem("userRole") == "SuperAdmin"){
      await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/game/forbucket?storeId=${localStorage.getItem("storeId")}`)
      .then(res => {
        let object = res.data.result.result.filter(ele => {
          if (ele.names) {
            ele.names = JSON.parse(ele.names)
            return ele
          }
          return ele

        })

        setData(object)
        setIsLoading(false);

      }).catch(err => {
        console.log(err)
        setIsLoading(false)
      })
    }
    else{
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/eventBucket?storeId=${localStorage.getItem("storeId")}`)
      .then(res => {
        let object = res.data.result.result.filter(ele => {
          if (ele.names) {
            ele.names = JSON.parse(ele.names)
            return ele
          }
          return ele

        })

        setData(object)
        setIsLoading(false);

      }).catch(err => {
        console.log(err)
        setIsLoading(false)
      })
    }
  }

  const searchContent = () => {
    searchBucket(id,name,lang, content)
    setIsLoading(true)
  }


  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleSortRequest = (headerName) => {
    console.log(headerName)
    const isAsc = orderBy === headerName && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(headerName);
  }

  return (

    <Fragment>

      <Paper component={Box} p={4}>
        <Toolbar>
          <h1>List Bucket</h1>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searched}
              onChange={(e)=>setSearched(e.target.value)}
              inputProps={{ 'aria-label': 'search' }}
              onKeyDown={() => searchContent()}
            />
          </div> */}

        </Toolbar>
        <Grid container className="mt-3 mb-3">
          <Grid item md={3}>
            <TextField variant="outlined" value={id} onChange={(e)=>setId(e.target.value)} width="25"  label="Id" name="name"></TextField>
          </Grid >
          <Grid item md={3}>
            <TextField variant="outlined" value={name} onChange={(e)=>setName(e.target.value)} width="25"  label="name" name="name"></TextField>
          </Grid >
          <Grid item md={3}>
            <FormControl variant="outlined" className={classes.formControl} style={{top:-10 , maxWidth:"100px"}}>
              <InputLabel id="demo-simple-select-outlined-label">Content Type</InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={content}
                onChange={(e)=>setContent(e.target.value)}
                label="Content type"
              >
                
                {/* {
                  content_type && content_type.length > 0 ?
                    content_type.map((element, id) => {

                      return (
                        <MenuItem key={id} value={element.Description}>{element.Description}</MenuItem>
                      )
                    }) :
                    <MenuItem disabled value="no pages">No Content Type</MenuItem>

                }  */}
            
            {
                 localStorage.getItem("userRole") != "SuperAdmin" ?
                    content_type.map((element, id) => {

                      return (
                        <MenuItem key={id} value={element.Description}>{element.Description}</MenuItem>
                      )
                    }) :
                    game_content_type.map((element, id) => {

                      return (
                        <MenuItem key={id} value={element.Description}>{element.Description}</MenuItem>
                      )
                    })

                } 
              </Select>

            </FormControl>


          </Grid>

          <Grid item md={3}>
            <FormControl variant="outlined"  className={classes.formControl} style={{top:-10, marginLeft:"-20px"}}>
              <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={lang}
                onChange={(e)=>setLang(e.target.value)}
                label="Language"
              ><MenuItem value="Default">Default</MenuItem>
                {
                  langList && langList.length > 0 ?
                    langList.map((element, id) => {

                      return (
                        <MenuItem key={id} value={element.name}>{element.name}</MenuItem>
                      )
                    }) :
                    <MenuItem disabled value="no pages">No Pages</MenuItem>

                }

              </Select>

            </FormControl>

          </Grid>
          <Grid item md={3}>
          <Button color="primary" variant="contained" onClick={searchContent} className="mt-2">Search</Button>{" "}
          <Button color="secondary" variant="contained" onClick={onClear}  className="mt-2">Clear</Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell >Pro User</TableCell>
                <TableCell>Content Type</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "language"}
                    direction={orderBy === "language" ? order : "asc"}
                    onClick={() => { handleSortRequest("language") }}
                  >
                    Language
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>Live Date</TableCell>
                <TableCell>Expiry Date</TableCell> */}
                <TableCell align="center">Enable</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0 ?
                // data && data.length > 0 ?
                 stableSort(data, getComparator(order, orderBy))
                  .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                  .map((emp, index) => (
                   
                    <TableRow key={index}>
                      <TableCell>{emp._id}</TableCell>
                      <TableCell>{emp.names ? emp.names[0].en : null}</TableCell>
                      <TableCell>{emp.proUser == "2" ? "Both" : (emp.proUser == "1" ? "Pro User" : "Free User")}</TableCell>
                      <TableCell>{emp.contentType}</TableCell>
                      <TableCell>{emp.language}</TableCell>
                      {/* <TableCell>{emp.live_date_time}</TableCell>
                      <TableCell>{emp.expiry}</TableCell> */}
                      <TableCell align="center">{emp.enabled === true ? <span className="greendot"></span> : <span className="reddot"></span>}</TableCell>
                      <TableCell>
                        <Link to={`/editPaytmBucket/${emp._id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                        <DeleteForeverOutlinedIcon
                          className={classes.btndelete}
                          onClick={() => { setDeleteId(emp._id); toggle() }}
                        />
                         
                      </TableCell>
                      <TableCell>
                      <Tooltip title="Copy Bucket"> 
                  <FileCopyIcon style={{ color: "green" }} className={classes.btncopy} 
                  onClick={()=>{setCopyId(emp._id);toggle1()}}
                  />
                   </Tooltip>
                      </TableCell>
                    </TableRow>
                    
                  )) 
                  : null
                  }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 50, 100]}
            component="div"
            count={data ? data.length : null}
            rowsPerPage={rowsPerPage}
            page={pagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
        <DeleteModal
          toggle={toggle}
          deleteModal={deleteModal}
          deleteContent={deleteBucket}
        />
         <CopyModal
          toggle1={toggle1}
          copyModal={copyModal}
          copyContent={copyPage}
        />
        {isLoading===true?<CircularProgress style={{position: "absolute",margin: "-50px 500px 0",justifyContent: "center",alignItems:"center",display:"flex"}}/> :null}
      </Paper>
    </Fragment>
  );

}
