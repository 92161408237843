import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    makeStyles,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputBase,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    IconButton

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchIcon from '@material-ui/icons/Search';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';  
import MultiLanguageModal from '../Reusable/multiLanguageModal';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));



export default function EditCategoryBucket(props) {
    const classes_ = useStyles();
    const classes = useStyle();
    const classes1 = useStyles();
    const [name, setName] = useState("");
    const [checked, setChecked] = useState([]);
    const [selected, setselected] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [searched, setSearched] = useState("")
    const[call, setCall] = useState(false)
    const [displayName, setDisplayName] = useState("");
    const [audioVideo, setAudioVideo] = useState("")
    const [languageSpec, setLanguageSpec] = useState("Yes");

    const toggle = () => setModal(!modal);

    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);

    const fetchCategory = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category?storeId=${localStorage.getItem("storeId")}`)
          .then(res => {
            console.log(res)
            let filterObj = res.data.data.filter(qId => !selected.some(o => o.categories === qId._id))
            setCategories(filterObj)
    
          }).catch(err => {
              console.log(err)
            cogoToast.error("somthing went Worng!")
          })
      }

      const fetchCategoryById = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/categorylist/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
          .then(res => {
            console.log(res)
            let name = JSON.parse(res.data.data.displayName)
            setselected(res.data.data.detail)
            setName(res.data.data.name)
            setDisplayName(name.en)
            setCall(true)
            setAudioVideo(res.data.data.type?res.data.data.type:"")
            setLanguageSpec(res.data.data.languageSpec ? res.data.data.languageSpec : "Yes")    
            // setName(name.en)
            setEnglishLang(name.en ? name.en : '')
            setHindiLang(name.hi ? name.hi  : '')
            setMarathiiLang(name.mr ? name.mr  : '')
            setGujaratiLang(name.gu ? name.gu  : '')
            setKannadaLang(name.kn ? name.kn  : '')
            setTeluguLang(name.te ? name.te  : '')
            setTamilLang(name.ta ? name.ta  : '')
            setPunjabiLang(name.pa ? name.pa  : '')
            setMalayalamLang(name.ml ? name.ml : '');
                setOdisaLang(name.or ? name.or : '');
                setUrduLang(name.ur ? name.ur :'' );
                setBengaliLang(name.bn ? name.bn :'' );
                setKhmerLang(name.khm ? name.khm : '')
          }).catch(err => {
              console.log(err)
            cogoToast.error("somthing went Worng!")
          })
      }

      useEffect(() => {
        fetchCategoryById();
        fetchCategory()
      }, [])

      useEffect(() => {
          if(call===true && searched === ""){
            fetchCategory()
          }
        
      }, [call,searched])

      

    const leftChecked = intersection(checked, selected);
    const rightChecked = intersection(checked, categories);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const deleteLeftData =() =>{
        setselected(not(selected, leftChecked));
        setChecked(not(checked, leftChecked));
    }
    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setCategories(categories.concat(leftChecked));
        setselected(not(selected, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setselected(selected.concat(rightChecked));
        setCategories(not(categories, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(selected);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setselected(items)
    }

    const searchCategory = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    setCategories(res.data.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }
    const searchContent = (e) =>{

        if(e.key==="Enter")searchCategory(searched)
      }

    const categoryList = (title, items) => (

        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <div className={classes_.contentSearch}>
                <div className={classes_.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes_.inputRoot,
                        input: classes_.inputInput,
                    }}
                    value={searched}
                    onChange={(e) => setSearched(e.target.value)}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={(e) => searchContent(e)}
                />
            </div>
            <List className={classes.list} dense component="div" role="list" >
                {items.map((value, index) => {
                    const labelId = `${value._id}`;

                    return (
                        <ListItem key={labelId} role="listitem" button onClick={handleToggle(value)} >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} >{value.name}</ListItemText>

                        </ListItem>

                    );
                })}
            </List>
        </Card>
    );

    const selectedCategoryList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="delete">
                    <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                        <DeleteForeverOutlinedIcon className={leftChecked.length === 0?null:classes1.btndelete}/>
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value._id?value._id:value.categories}`;

                                return (
                                    <Draggable key={labelId} draggableId={labelId} index={index}>
                                        {(provided) => (
                                            <ListItem key={labelId} role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.name} />

                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );

    const postCategoryBucket = async (data) => {
        await axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/categorylist/${props.match.params.id}/detail`, data).then(res=>{
            cogoToast.success(res.data.message)
            props.history.push('/listCategoryBucket')
        }).catch(err=>{
            console.log(err)
            cogoToast.error("Something Went Wrong!")
        })
    }

     const onSubmit = () => {
         let categoryItems = selected.map((ele, i )=>{
             let obj = {
                "sequence": i,
                "categories": ele._id?ele._id:ele.categories
             }
             return obj
         })
         let displayName = JSON.stringify({
            hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
        })
         let object = {
             name:name,
             displayName:displayName,
             detail:categoryItems,
             type:audioVideo,
             languageSpec: languageSpec,
             storeId:localStorage.getItem("storeId")
         }
         postCategoryBucket(object)
         setAudioVideo("")
         setLanguageSpec("Yes")
         setselected([])
          setName("")
          setDisplayName("")
         setHindiLang("")
         setMarathiiLang("")
         setGujaratiLang("")
         setKannadaLang("")
         setTeluguLang("")
         setTamilLang("")
         setPunjabiLang("")
     }                       

     const onClear = () => {
         setAudioVideo("")
        setLanguageSpec("Yes")
        setselected([])
         setName("")
         setDisplayName("")
        setHindiLang("")
        setMarathiiLang("")
        setGujaratiLang("")
        setKannadaLang("")
        setTeluguLang("")
        setTamilLang("")
        setPunjabiLang("")
    }
    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Edit Category Bucket</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes_.inputField} width="25" required label="Name" name="Name" value={name} onChange={(e) => { setName(e.target.value) }} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                        <TextField variant="outlined" className={classes.inputField} style={{width:"40%"}} required label="Display Name" name="displayName" value={englishLang} onChange={e => setEnglishLang(e.target.value)} ></TextField>
                        <Button style={{ height: "56px",marginTop:"-2px",marginLeft:"0px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" style={{width:"60%"}}>
                                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={audioVideo}
                                    onChange={(e) => setAudioVideo(e.target.value)}
                                    label="Varient Name"
                                >
                                                <MenuItem value="audio">Audio</MenuItem>
                                                <MenuItem value="video">Video</MenuItem>
                                            

                                </Select>

                            </FormControl>


                        </Grid>
                        <Grid item md={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: "60%" }}>
                            <InputLabel id="demo-simple-select-outlined-label">Language Specific</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={languageSpec}
                                onChange={(e) => setLanguageSpec(e.target.value)}
                                label="Varient Name"
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>


                            </Select>

                        </FormControl>
                        </Grid>
                        
                       
                    </Grid>
                
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid item>
                    <h4>Selected</h4>
                    {selectedCategoryList('Choices', selected)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item><h4>Categories</h4>{categoryList('Chosen', categories)}


                </Grid>

                <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button variant="contained" color="secondary" >Clear</Button>

                        </Grid>

            </Grid>
            </div>
            <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
            </Paper>


        </Fragment>
    )
}
