import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import Switch from "react-switch";

import { useStyles } from '../../styles/style';
import axios from 'axios';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from "@material-ui/icons/Clear";
import cogoToast from 'cogo-toast';
import MultiLanguageModal from '../Reusable/multiLanguageModal';

export default function CreateStory(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [enable, setEnable] = useState("true");
    const [internal, setInternal] = useState("false");
    const [liveDate, setLiveDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [media, setMedia] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [continueToggle, setcontinueToggle] = useState(false)
    const [contentType, setContentType] = useState("");
    const [pages, setPages] = useState("");

    const toggle = () => setModal(!modal);
    const [deepLink, setDeepLink] = useState("");
    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");
    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [modal, setModal] = useState(false);


    const [responce, setResponce] = useState()
    const [substory, setSubstory] = useState([])
    const [subStoryMedia, setSubStoryMedia] = useState("");
    const [linkPage, setLinkPage] = useState("")
    const [ds, setDs] = useState("none")
    const [sequence, setSequence] = useState(1);
    const [addData, setAddData] = useState([])
    const [pole, setPole] = useState("");
    const [poleList, setPoleList] = useState([]);

    const enhandleChange = (event) => {
        setEnable(event.target.value);
    };

    const internalhandleChange = (event) => {
        setInternal(event.target.value);
    };

    const fetchData = async () => {
        let data = localStorage.getItem("storeId");
    
        if(data){
         await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/page?storeId=${data}`)
         .then(res => {
           console.log(res)
           let data =[];
           for(let i in res.data.result.result){
               if(res.data.result.result[i].isBrandHub && res.data.result.result[i].isBrandHub == "true"){
data.push(res.data.result.result[i])
               }
           }
           console.log('=data=',data);
           setPages(data)
         }).catch(err => {
           console.log(err)
         })
        }
    
      }

    const fetchPolls= async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/poll?storeId=${localStorage.getItem("storeId")}`)
            .then(

                res => {
                    let newArray
                    let obj = res.data.data;
                    newArray = [{_id:" ", name:"None"},...obj]

                    setPoleList(newArray)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    useEffect(() => {
        fetchPolls()
        fetchData()
    }, [])


    const handleChangeToggle = (e) =>{
        console.log('ee',e)
        setcontinueToggle(e)
    }
    const postApi = async (data) => {
        const fd = new FormData();
        fd.append("type", "story");
        fd.append("name", JSON.stringify(data.name));
        fd.append("enabled", data.enabled);
        fd.append("live_date_time", data.live_date_time);
        fd.append("expiry", data.expiry);
        fd.append("storeId",data.storeId); 
        fd.append("isBrandHub",data.isBrandHub);
        fd.append("deepLink",data.deepLink);
        fd.append("pageId",data.pageId);
        fd.append("internal",data.internal);
        for (let index = 0; index < data.media.length; index++) {
            const element = data.media[index];
            fd.append('file', element)
        }
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, fd)
            .then(
                res => {
                    if (res.status === 200) {

                        // resp.push(JSON.parse(res.data.result.result))
                        let object = [res.data.result.result].filter(ele => {
                            ele.name = JSON.parse(ele.name)
                            return ele
                        })
                        setResponce(object)
                        cogoToast.success("success")



                    }
                }
            ).catch(err => {
                console.log(error);
            })

    }

    // convert string to boolean
    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }


    const onSubmit = () => {
        const obj = {
            "type": "story",
            "name": [{
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or:odisaLang,
                ur:urduLang,
                bn:bengaliLang,
                ml:malayalamLang,
                si:sinhalaLang,
                ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang
            }],
            "media": media,
            "enabled": str2bool(enable),
            "live_date_time": liveDate === null?'':liveDate,
            "expiry": expiryDate === null? '':expiryDate,
            "storeId":localStorage.getItem("storeId"),
            "isBrandHub":str2bool(continueToggle),
            "deepLink": deepLink,
            "pageId":contentType,
            "internal":internal
        }
        // console.log(obj)
        postApi(obj)
        setDs("flex")
        setName("")
        setHindiLang("")
        setMarathiiLang("")
        setGujaratiLang("")
        setKannadaLang("")
        setTeluguLang("")
        setTamilLang("")
        setPunjabiLang("")
        setMedia("")
    }

    const deleteImg = (e, i) => {
        let newArray = media.filter((ele, index) => {
            return index !== i;
        })

        setMedia(newArray)
    }

    const onAdd = async () => {
        let object = new FormData()
        object.append("type", "story")
        object.append("parentId", responce[0]._id)
        object.append("file",subStoryMedia);
        object.append("storeId",localStorage.getItem("storeId"))
        if(pole._id !== " "){
            object.append("poll",pole._id)
            object.append("pollName",pole.name)
        }else{
            object.append("poll","")
            object.append("pollName","none")
        }
        
        
        
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, object)
            .then(
                res => {

                    if (res.status === 200) {
                        setSubstory([...substory,res.data.result.result])
                        setSubStoryMedia("")
                        setPole("")
                        cogoToast.success(res.data.result.message);
                    }
                }
            ).catch(err => {
                cogoToast.error("bad request")
            })



    }

    

    const onClear = () => {
        setName("");
        setHindiLang("")
        setMarathiiLang("")
        setGujaratiLang("")
        setKannadaLang("")
        setTeluguLang("")
        setTamilLang("")
        setPunjabiLang("")
        setMedia("")
        setDeepLink("")
        setPages("")
    }

    const onSubStoryImgDelete = (e, index) => {
        let newArray = subStoryMedia.filter((ele, i) => {
            return i !== index;
        })

        setSubStoryMedia(newArray)
    }

    const handleChangePole =(e)=>{
        

        setPole(e.target.value)
    }

    


    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div >
                    <h1 className="text-center mb-4">Add Story</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputFieldBucket} width="25" required label="Name" name="name" value={englishLang} onChange={e => setEnglishLang(e.target.value)} >
                            </TextField>
                            <Button style={{ height: "56px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Live Date time" className={classes.inputField} value={liveDate} onChange={e => console.log(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!liveDate}
                                    onClick={() => setLiveDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Expiry Date time" className={classes.inputField} value={expiryDate} onChange={e => setExpiryDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!expiryDate}
                                    onClick={() => setExpiryDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={enable} onChange={enhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>

                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                Media
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={e => setMedia([...media, ...e.target.files])}
                                />
                            </Button>
                            {media.length > 0 ?
                                (media.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteImg(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
            Is BrandHub :&nbsp;<Switch 
            onChange={handleChangeToggle} value={continueToggle} checked={continueToggle}
             />
                </Grid>
                <Grid item md={6} sm={12}>
                            <FormControl style={continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Internal</FormLabel>
                                <RadioGroup aria-label="internal" name="internal" value={internal} onChange={internalhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid style={internal === "true" && continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <InputLabel id="demo-simple-select-outlined-label">Pages</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={contentType}
                                    onChange={(e) => setContentType(e.target.value)}
                                    label="Content type"
                                >
                                    {
                                        pages && pages.length > 0 ?
                                            pages.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.pageCode}>{element.iname}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No Content Type</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid style={internal === "false" && continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Deep Link" name="DeepLink" value={deepLink} onChange={e => setDeepLink(e.target.value)} ></TextField>
                        </Grid>
                        <Grid item md={12}>
                            <Button className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                    {

                        responce ?
                            <div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Live Date</TableCell>
                                                <TableCell>Expiry Date</TableCell>
                                                <TableCell>Enabled</TableCell>
                                            </TableRow>
                                        </TableHead>


                                        {responce.map(value => (
                                            <TableBody>
                                                <TableRow key={value._id}>
                                                    <TableCell>{value.name[0].en}</TableCell>
                                                    <TableCell>{value.live_date_time}</TableCell>
                                                    <TableCell>{value.expiry}</TableCell>
                                                    <TableCell>{value.enabled.toString()}</TableCell>
                                                </TableRow>
                                            </TableBody>

                                        ))}


                                    </Table>
                                </TableContainer>
                                <Grid className="mt-5" container style={{ display: `${ds}` }}>
                                    <Grid item md={6} sm={12}>
                                        <Button
                                            className={classes.upload}
                                            variant="outlined"
                                            component="label"

                                        >
                                            Media
                                            <input
                                                type="file"
                                                hidden
                                                onChange={e => setSubStoryMedia(e.target.files[0])}
                                            />
                                        </Button>
                                        {subStoryMedia?
                                <p className="mt-2 mr-4">{subStoryMedia.name} <CancelIcon onClick={() => setSubStoryMedia("")} /></p>
                                : null}
                                        

                                    </Grid>

                                    <Grid item md={4}>
                                    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: "60%",marginLeft:"-100px" }}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Activity</InputLabel>

                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={pole}
                                                        onChange={(e) => handleChangePole(e)}
                                                        label="Polls"
                                                    >
                                                        {
                                                            poleList && poleList.length > 0 ?
                                                            poleList.map((element, id) => {

                                                                    return ( 
                                                                        <MenuItem  value={element}>{element.name}</MenuItem>
                                                                    )
                                                                }) :
                                                                <MenuItem disabled value="no pages">No app</MenuItem>

                                                        }

                                                    </Select>

                                                </FormControl>
                                                <Button className="mt-4 ml-3" variant="contained" color="primary" onClick={onAdd}>Add</Button>

                                        
                                    </Grid>

                                </Grid>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Image</TableCell>
                                                <TableCell>Activiy</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    {substory.length > 0 ?
                                        substory.map((element, index) => (
                                            <TableBody>
                                                <TableRow key={element._id}>
                                                    <TableCell style={{cursor:"pointer"}} onClick={() => window.open(element.name ? null : `https://storage.googleapis.com/media-content-hungama-com/story/${element.media}`, "_blank")}>{element.media}</TableCell>
                                                    <TableCell>{element.pollName}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        ))
                                        : null}
                                        
                                        </Table>
                                </TableContainer>
                            </div>
                            : null}


                    {/* Modal */}
                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                </div>
            </Paper>
        </Fragment>
    )
}
