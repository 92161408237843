import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    MenuItem

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import {content_type} from './../bucket/objects'




export default function EditVarient(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [v0, setv0] = useState([]);
    const [v1, setv1] = useState([]);
    const [v2, setv2] = useState([]);
    const [v3, setv3] = useState([]);
    const [v4, setv4] = useState([]);
    const [v5, setv5] = useState([]);
    const [v6, setv6] = useState([]);
    const [v7, setv7] = useState([]);
    const [v8, setv8] = useState([]);
    const [v9, setv9] = useState([]);
    const [v10, setv10] = useState([]);
    const [deletev0, setDeletev0] = useState([]);
    const [deletev1, setDeletev1] = useState([]);
    const [deletev2, setDeletev2] = useState([]);
    const [deletev3, setDeletev3] = useState([]);
    const [deletev4, setDeletev4] = useState([]);
    const [deletev5, setDeletev5] = useState([]);
    const [deletev6, setDeletev6] = useState([]);
    const [deletev7, setDeletev7] = useState([]);
    const [deletev8, setDeletev8] = useState([]);
    const [deletev9, setDeletev9] = useState([]);
    const [deletev10, setDeletev10] = useState([]);
    const [contents, setContents] = useState("");
    const [contentId, setContentId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])
    const [lang, setLang] = useState("Default")

    const listVarient = ["v0","v1", "v2", "v3", "v4", "v5", "v6"];


    const handlev0 = (e) => {
        setv0([...v0, ...e.target.files])
    }
    const handlev1 = (e) => {
        setv1([...v1, ...e.target.files])
    }
    const handlev2 = (e) => {
        setv2([...v2, ...e.target.files])
    }
    const handlev3 = (e) => {
        setv3([...v3, ...e.target.files])
    }
    const handlev4 = (e) => {
        setv4([...v4, ...e.target.files])
    }
    const handlev5 = (e) => {
        setv5([...v5, ...e.target.files])
    }
    const handlev6 = (e) => {
        setv6([...v6, ...e.target.files])
    }
    const handlev7 = (e) => {
        setv7([...v7, ...e.target.files])
    }
    const handlev8 = (e) => {
        setv8([...v8, ...e.target.files])
    }
    const handlev9 = (e) => {
        setv9([...v9, ...e.target.files])
    }
    const handlev10 = (e) => {
        setv10([...v10, ...e.target.files])
    }
    const onHandleChange = (e) => {
        const value = e.target.value;
        console.log('value',value);
        setContentId(e.target.value)
    }

    const onEnterContentId = (e) => {
        if (contentId !== "" && e.key === "Enter" || e.key === "Tab") {
            axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/solr/${contentId}?storeId=${localStorage.getItem("storeId")}`).then(res => {
                console.log(res.data)
                setDisplayName(res.data.data.response.docs[0].name)
            }).catch(err => {
                console.log(err)
                cogoToast.error("Wrong ContentId")
            })
        }


    }

    const fetchDataById = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {
                console.log('thumb',res.data.result.result.thumbnail)
                setContentId(res.data.result.result.contentId)
                setDisplayName(res.data.result.result.contentName)
                setName(res.data.result.result.name)
                setv0(res.data.result.result.v0)
                setv1(res.data.result.result.v1)
                setv2(res.data.result.result.v2)
                setv3(res.data.result.result.v3)
                setv4(res.data.result.result.v4)
                setv5(res.data.result.result.v5)
                setv6(res.data.result.result.v6)
                setv7(res.data.result.result.v7)
                setv8(res.data.result.result.v8)
                setv9(res.data.result.result.v9)
                setv10(res.data.result.result.v10)
                setContents(res.data.result.result.contentType?res.data.result.result.contentType:"")   
                setLang(res.data.result.result.language?res.data.result.result.language:"Default")
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchDataById()
        fetchLanguage();
    }, [])

    const postVariant = async (data) => {
        const fd = new FormData();
        fd.append("language", data.language);
        fd.append("type", data.type);
        fd.append("contentId", data.contentId);
        fd.append("name", data.name);
        fd.append("contentType", data.content_type);
        fd.append("contentName", data.displayName);
        fd.append("storeId", data.storeId);
        data.deletev0.map((ele, i) => {
            fd.append(`deletev0[${i}]`, ele)
        })
        data.deletev1.map((ele, i) => {
            fd.append(`deletev1[${i}]`, ele)
        })
        data.deletev2.map((ele, i) => {
            fd.append(`deletev2[${i}]`, ele)
        })
        data.deletev3.map((ele, i) => {
            fd.append(`deletev3[${i}]`, ele)
        })
        data.deletev4.map((ele, i) => {
            fd.append(`deletev4[${i}]`, ele)
        })
        data.deletev5.map((ele, i) => {
            fd.append(`deletev5[${i}]`, ele)
        })
        data.deletev6.map((ele, i) => {
            fd.append(`deletev6[${i}]`, ele)
        })
        data.deletev7.map((ele, i) => {
            fd.append(`deletev7[${i}]`, ele)
        })
        data.deletev8.map((ele, i) => {
            fd.append(`deletev8[${i}]`, ele)
        })
        data.deletev9.map((ele, i) => {
            fd.append(`deletev9[${i}]`, ele)
        })
        data.deletev10.map((ele, i) => {
            fd.append(`deletev10[${i}]`, ele)
        })
        for (let index = 0; index < data.v0.length; index++) {
            const element = data.v0[index];
            if (typeof (element) !== "string") {
                fd.append('v0', element)
            }
        }
        for (let index = 0; index < data.v1.length; index++) {
            const element = data.v1[index];
            if (typeof (element) !== "string") {
                fd.append('v1', element)
            }
        }
        for (let index = 0; index < data.v2.length; index++) {
            const element = data.v2[index];
            if (typeof (element) !== "string") {
                fd.append('v2', element)
            }
        }
        for (let index = 0; index < data.v3.length; index++) {
            const element = data.v3[index];
            if (typeof (element) !== "string") {
                fd.append('v3', element)
            }
        }
        for (let index = 0; index < data.v4.length; index++) {
            const element = data.v4[index];
            if (typeof (element) !== "string") {
                fd.append('v4', element)
            }
        }
        for (let index = 0; index < data.v5.length; index++) {
            const element = data.v5[index];
            if (typeof (element) !== "string") {
                fd.append('v5', element)
            }
        }
        for (let index = 0; index < data.v6.length; index++) {
            const element = data.v6[index];
            if (typeof (element) !== "string") {
                fd.append('v6', element)
            }
        }
        for (let index = 0; index < data.v7.length; index++) {
            const element = data.v7[index];
            if (typeof (element) !== "string") {
                fd.append('v7', element)
            }
        }
        for (let index = 0; index < data.v8.length; index++) {
            const element = data.v8[index];
            if (typeof (element) !== "string") {
                fd.append('v8', element)
            }
        }
        for (let index = 0; index < data.v9.length; index++) {
            const element = data.v9[index];
            if (typeof (element) !== "string") {
                fd.append('v9', element)
            }
        }
        for (let index = 0; index < data.v10.length; index++) {
            const element = data.v10[index];
            if (typeof (element) !== "string") {
                fd.append('v10', element)
            }
        }
        await axios.put(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/variant/${props.match.params.id}`, fd,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                cogoToast.success(res.data.result.message);
                props.history.push("/listVarient")
            }).catch(err => {
                cogoToast.error(err.response.data.message)
            })
    }

    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setLangList([...langList, ...res.data.result.result])
        }).catch(
            err => {
                console.log(err)
            }
        )
    }

    const onSubmit = () => {
        const obj = {
            "type": "variant",
            "v0": v0,
            "v1":v1,
            "v2":v2,
            "v3":v3,
            "v4":v4,
            "v5":v5,
            "v6":v6,
            "v7":v7,
            "v8":v8,
            "v9":v9,
            "v10":v10,
            name: name,
            contentId: contentId,
            deletev0: deletev0,
            deletev1:deletev1,
            deletev2:deletev2,
            deletev3:deletev3,
            deletev4:deletev4,
            deletev5:deletev5,
            deletev6:deletev6,
            deletev7:deletev7,
            deletev8:deletev8,
            deletev9:deletev9,
            deletev10:deletev10,
            content_type:contents,
            displayName:displayName,
            "language": lang,
            "storeId":localStorage.getItem("storeId")       
        }
        postVariant(obj)
        setDisplayName("")
        setContentId("")

    }

    const onClear = () => {
        setContentId("")
        setDisplayName("")
        setContentId("")
        setv0([])
        setv1([])
        setv2([])
        setv3([])
        setv4([])
        setv5([])
        setv6([])
        setv7([])
        setv8([])
        setv9([])
        setv10([])

    }
    const delete_v0 = (e, i, ele) => {
        let newArray = v0.filter((ele, index) => {
            return index !== i;
        })

        setDeletev0([...deletev0, ele]);

        setv0(newArray)
    }

    const delete_v1 = (e, i, ele) => {
        
        let newArray = v1.filter((ele, index) => {
            return index !== i;
        })
        setDeletev1([...deletev1, ele])
        setv1(newArray)
    }
    const delete_v2 = (e, i, ele) => {
        
        let newArray = v2.filter((ele, index) => {
            return index !== i;
        })
        setDeletev2([...deletev2, ele])
        setv2(newArray)
    }
    const delete_v3 = (e, i, ele) => {
        
        let newArray = v3.filter((ele, index) => {
            return index !== i;
        })
        setDeletev3([...deletev3, ele])
        setv3(newArray)
    }
    const delete_v4 = (e, i, ele) => {
        
        let newArray = v4.filter((ele, index) => {
            return index !== i;
        })
        setDeletev4([...deletev4, ele])
        setv4(newArray)
    }
    const delete_v5 = (e, i, ele) => {
        
        let newArray = v5.filter((ele, index) => {
            return index !== i;
        })
        setDeletev5([...deletev5, ele])
        setv5(newArray)
    }
    const delete_v6 = (e, i, ele) => {
        
        let newArray = v6.filter((ele, index) => {
            return index !== i;
        })
        setDeletev6([...deletev6, ele])
        setv6(newArray)
    }
    const delete_v7 = (e, i, ele) => {
        
        let newArray = v7.filter((ele, index) => {
            return index !== i;
        })
        setDeletev7([...deletev7, ele])
        setv7(newArray)
    }
    const delete_v8 = (e, i, ele) => {
        
        let newArray = v8.filter((ele, index) => {
            return index !== i;
        })
        setDeletev8([...deletev8, ele])
        setv8(newArray)
    }
    const delete_v9 = (e, i, ele) => {
        
        let newArray = v9.filter((ele, index) => {
            return index !== i;
        })
        setDeletev9([...deletev9, ele])
        setv9(newArray)
    }
    const delete_v10 = (e, i, ele) => {
        
        let newArray = v10.filter((ele, index) => {
            return index !== i;
        })
        setDeletev10([...deletev10, ele])
        setv10(newArray)
    }

    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Edit Variant</h1>
                    <Grid container >
                    <Grid item md={8} sm={12}>
                <FormControl style={{minWidth:"45%",marginLeft:"-150px"}} variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        label="Language"
                    >
                        {
                            langList.length > 0 ?
                            langList.map((element, id) => {

                                    return (
                                        <MenuItem key={id} value={element.name}>{element.name}</MenuItem>
                                    )
                                }) :
                                <MenuItem disabled value="no Lamguage">No Lamguage</MenuItem>

                        }

                    </Select>

                </FormControl>

            </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Content Id" name="contentId" value={contentId} onChange={(e) => setContentId(e.target.value)} onChange={onHandleChange} onKeyDown={onEnterContentId}></TextField>
                        </Grid>
                        <Grid item md={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" disabled required label="Content Name" value={displayName} >
                            </TextField>
                        </Grid>
                        {/* <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Variant Name</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    label="Varient Name"
                                >
                                    {
                                        listVarient.map((element, id) => {

                                            return (
                                                <MenuItem key={id} value={element}>{element}</MenuItem>
                                            )
                                        })

                                    }

                                </Select>

                            </FormControl>


                        </Grid> */}
                        <Grid item md={6}>
                        <FormControl variant="outlined" className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Content Type</InputLabel>

                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contents}
                                onChange={(e) => setContents(e.target.value)}
                                label="Content type"
                            >
                                {
                                    content_type && content_type.length > 0 ?
                                        content_type.map((element, id) => {

                                            return (
                                                <MenuItem key={id} value={element.typeid}>{element.Description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Content Type</MenuItem>

                                }

                            </Select>

                        </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                v0 Thumbnail
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev0}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v0.length > 0 ?
                                (v0.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v0(e, i, ele)} /></div>))
                                : null}
                                


                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v1
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev1}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v1.length > 0 ?
                                (v1.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v1(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v2
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev2}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v2.length > 0 ?
                                (v2.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v2(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v3
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev3}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v3.length > 0 ?
                                (v3.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v3(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v4
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev4}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v4.length > 0 ?
                                (v4.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v4(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v5
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev5}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v5.length > 0 ?
                                (v5.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v5(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v6
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev6}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v6.length > 0 ?
                                (v6.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v6(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v7
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev7}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v7.length > 0 ?
                                (v7.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v7(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v8
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev8}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v8.length > 0 ?
                                (v8.map((ele, i) =><div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v8(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v9
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev9}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v9.length > 0 ?
                                (v9.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v9(e, i, ele)} /></div>))
                                : null}

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                               v10
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev10}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v10.length > 0 ?
                                (v10.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/variant/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => delete_v10(e, i, ele)} /></div>))
                                : null}

                        </Grid>

                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Update</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>
            </Paper>


        </Fragment>
    )
}
