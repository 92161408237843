import React,{useState} from 'react'
import {Link,useHistory} from 'react-router-dom';
import { Grid, Paper, Avatar, TextField, Typography, makeStyles,FormControl,  InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Chip, Checkbox, Input, ListItemText } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import logo from './../../images/Hungama_logo.png'
import StoreModal from './../Reusable/stores.json';
import cogoToast from 'cogo-toast';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { object } from 'prop-types';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '25ch',
    },
    button:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2),
    }
  }));

  
function Login(props){

    const paperStyle = { padding: 20, height: '66vh', width: 300, margin: "70px auto" }
    const optionStyle = {  width: 200 }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const btnstyle = { margin: '8px 0' }
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [contents, setContents] = useState("1");
    const [langList, setLanglist] = useState([]);
    const classes = useStyles()
    const validationSchema = Yup.object().shape({
        username: Yup.string().email('please enter valid email').required("Required"),
        password: Yup.string().required("Required")
    });
    const navigate = useHistory(); 

    console.log(StoreModal);

     const onSubmit = async () => {
        let object ={
            "username":email,
            "password":password,
            "storeId":contents
        }
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/user/login`,object).then(
            res => {
                console.log('res',res.data.message);
                if(res && res.data.message === 'success'){
              localStorage.setItem('storeId',contents);
              localStorage.setItem('role',res.data.result.adminUser == true ? 'ADMIN' : 'ALL');
              localStorage.setItem('userRole',res.data.result.role);
              localStorage.setItem('userId',res.data.result._id)
              navigate.push('/'); 
                }
                else{
                    cogoToast.error(res.data.message)
                }
            }
          ).catch(err => {
            cogoToast.error('invalid storeId')
            console.log('errrrrrrrrr',err);
          })

        // if(email == "hungama" && password == "hungama@9870"){
        //     localStorage.setItem('storeId',contents);
        //     localStorage.setItem('role','ADMIN');
        //     localStorage.setItem('userId','634009c75cabcb487887a8b5')
        //     navigate.push('/'); 
        // }
        // console.log(data)

    }
    return (
        <Grid>
            <Paper style={paperStyle}>
                <Grid align='center'>
                    <img src={logo} width="200px"/>
                    {/* <Avatar style={avatarStyle}><LockOutlinedIcon /></Avatar> */}
                    <h5>Sign In</h5>
                </Grid>
                {/* <TextField className={classes.inputField} id="standard-basic" label="Email"
                name="email" 
                value={email} 
                onChange={(e) => setEmail(e.taget.value)}
                /> */}
                <Grid>
                <TextField  className={classes.inputField} width="25" label="UserName" value={email} onChange={(e) => setEmail(e.target.value)}>
                            </TextField>
                            </Grid>
                            <Grid>
                            <TextField type='password'  className={classes.inputField} width="25" label="Password" value={password} onChange={(e) => setPassword(e.target.value)}>
                            </TextField>
                            </Grid>
                            <br></br>
                            <Grid  item xs={3}>
                    <FormControl style={optionStyle} variant="outlined" className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Stores</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contents}
                                onChange={(e) => setContents(e.target.value)}
                                label="Stores"
                                maxMenuHeight={250}
                            >
                                {
                                    StoreModal && StoreModal.length > 0 ?
                                        StoreModal.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.storeId}>{element.description}&nbsp;<img src={element.image}/></MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Stores Available</MenuItem>
                                }

                            </Select>

                        </FormControl>
                        </Grid>
                        
                {/* <TextField className={classes.inputField} id="standard-basic" label="Password" name="password"
                onChange={(e) => setPassword(e.taget.value)} 
                value={password} /> */}
                
                
                            
        
                
                {/* <Typography >
                    <Link href="#" >
                        Forgot password ?
                </Link>
                </Typography> */}

                <Button className={classes.button} variant="contained" onClick={onSubmit} color="primary">Sign In</Button>
                {/* <Typography > Do you have an account ?
                     <Link href="#" onClick={() => handleChange("event", 1)} >
                        Sign Up
                </Link>
                </Typography> */}
            </Paper>
        </Grid>
    )
            
}

export default Login;
