import React, { Fragment, useState } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box
    
} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { array } from 'prop-types';


export default function CreateApp(props) {
    const classes = useStyles();
    const [enable, setEnable] = useState("true");

    const initialvalues = {
        code: "",
        title: "",
        description: "",
        retail:""
    }
    const [state, setState] = useState(initialvalues)

    const enhandleChange = (event) => {
        setEnable(event.target.value);

    };

    const onHandleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        })

    }


    // convert string to boolean
    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }

    const postApp  = async (data) => {
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, data)
            .then(res => {
                cogoToast.success(res.data.result.message);
            }).catch(err => {
                cogoToast.error(err.response.data.message);
            })
    }

    const onSubmit = () => {
        const obj = {
            "type": "app",
            "title": state.title,
            "enabled": str2bool(enable),
            "code": state.code,
            "description": state.description,
            "Retail":state.retail,
            "storeId":localStorage.getItem("storeId")
        }
        postApp(obj);
        setState(initialvalues);
    }

    const onClear = () => {
        setState(initialvalues)
    }


    return (

        <Fragment>
            <Paper component={Box} p={4}>

                <div className="text-center">
                    <h1>Add App</h1>
                    <Grid container >
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Code" name="code" value={state.code} onChange={onHandleChange} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Title" name="title" value={state.title} onChange={onHandleChange} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Description" name="description" value={state.description} onChange={onHandleChange} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={enable} onChange={enhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>
        
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Retail ID" name="retail" value={state.retail} onChange={onHandleChange} ></TextField>
                        </Grid>
                        <Grid item md={12}>
                            <Button type="submit" className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>
                </div>

            </Paper>


        </Fragment>
    )
}
