import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Button,
    Divider,
    Paper,
    FormControl,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    InputLabel,
    InputBase,
    CircularProgress,
    Tooltip,
    IconButton
} from '@material-ui/core'
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cogoToast from 'cogo-toast';
import { useStyles } from '../../styles/style';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import {RefreshOutlined} from '@material-ui/icons';



function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 400,
        height: 330,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export default function LinkCategoryWithBucket(props) {
    const classes = useStyle();
    const classes1 = useStyles()
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [mapData, setMapData] = useState([]);
    const [pageName, setPageName] = useState([]);
    const [reload, setReload] = useState(false)
    const [language, setLanguage] = useState("default")
    const [response, setResponse] = useState({})
    const [displayresponse, setDisplayResponse] = useState([])
    const [display, setsetDisplay] = useState("none")
    const [langList, setLangList] = useState([])
    const [items, setItems] = useState([])
    const [modal, setModal] = useState(false);
    const [buckets, setBuckets] = useState([]);
    const [showObj, setShowObj] = useState([]);
    const [call, setCall] = useState(false);
    const [searched, setSearched] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    const toggle = (value) => {
        setModal(!modal)
        setItems(value.bucket)
    };

    const toggleClose = () => {
        setModal(!modal)
    }


    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const fetchBucket = (value) => {
        axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/bucket?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {

                    let object = res.data.result.result.filter(ele => {
                        // if(typeof)
                        ele.names = JSON.parse(ele.names)
                        return ele
                    })

                    if (value) {
                        let filterObj = object.filter(qId => !value.some(o => o._id === qId._id))
                        setRight(filterObj);
                        setIsLoading(false)
                    }
                    else {
                        let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                        setRight(filterObj);
                        setIsLoading(false)
                    }
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const FetchMapData = () => {
        axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {

                    let obj = res.data.data[0].child.map(ele => {
                        ele.buckets = JSON.parse(ele.buckets)
                        return ele
                    })
                    for (let i in res.data.data[0].child[0].language[0]) {
                        setResponse({ ...response, [i]: res.data.data[0].child[0].language[0][i] })
                    }
                    // setResponse(res.data.data[0].child[0].language)
                    for (let i in obj[0].buckets) {
                        setShowObj([...showObj, obj[0].buckets[i]])
                    }
                    const displayArray = obj[0].buckets.map(ele => {
                        ele.bucket.map(e => {
                            if (typeof (e.names) === "string") {
                                e.names = JSON.parse(e.names)
                                return e
                            }
                            return e

                        })
                    })
                    setDisplayResponse(obj[0].buckets)
                    setMapData(obj)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            
            setLangList([{code:"default",name:"Default"},...res.data.result.result])

        }).catch(
            err => {
                cogoToast.error('language didnt found!')
            }
        )
    }


    useEffect(() => {
        fetchLanguage();
        fetchBucket();
        FetchMapData();
        axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category/${props.match.params.id}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    setPageName(res.data.data[0].name)
                }
            ).catch(
                err => {
                    console.log(err)
                }
            )
    }, [])




    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const PostMapping = async (data) => {
        // ${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category

        await axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/category/${props.match.params.id}/detail`, data)
            .then(res => {

                cogoToast.success("Success");
                props.history.push('/listCategory')

            }).catch(err => {
                console.log(err)
            })
    }

    const onSubmit = () => {
        const objects = {
            parentId: props.match.params.id,
            language: [response],
            buckets: JSON.stringify(displayresponse),
            storeId:localStorage.getItem("storeId")
        }
        PostMapping(objects);

    }
    const editLeft = (e, value) => {
        setLeft(value.bucket)
        setLanguage(value.language)
        let lang = langList.filter((ele) => {
            return ele.code === value.language;
        })
        lang = lang[0].name;
        filterBucketByLanguage(lang, value.bucket);

    }


    

    const filterBucketByLanguage = (lang, value) => {
        axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/bucket/${lang}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    if (res.data.result.result.message) {
                        setRight([])
                    } else {
                        let object = res.data.result.result.filter(ele => {
                            // if(typeof)
                            ele.names = JSON.parse(ele.names)
                            return ele
                        })

                        if (value) {
                            let filterObj = object.filter(qId => !value.some(o => o._id === qId._id))
                            setRight(filterObj);
                        }
                        else {
                            let filterObj = object.filter(qId => !left.some(o => o._id === qId._id))
                            setRight(filterObj);
                        }
                    }
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const handleLangugeChange = (e) => {
        setLanguage(e.target.value)
        let lang = langList.filter((ele) => {
            return ele.code === e.target.value;
        })

        lang = lang[0].name;
        filterBucketByLanguage(lang)

    }

    const onAdd = () => {
        setBuckets([...buckets, left])
        const bucketsLinkPage = left.map((ele, i) => {
            let obj = ele._id
            return obj;
        })
        const displayObject = {
            language: language,
            bucket: left
        }
        for (let i in displayresponse) {
            if (displayresponse[i].language === language) {
                displayresponse.splice(i, 1)
                break

            }
        }
        setDisplayResponse([...displayresponse, displayObject])

        for (let key in response) {
            if (key === language) {
                delete response[key]
                break
            }
        }


        const showObject = {
            language: language,
            bucket: left
        }
        setResponse({ ...response, [language]: bucketsLinkPage })
        // setShowObj([...showObject,showObj])

        setsetDisplay("flex")
        setLeft([])
        setReload(true)
        fetchBucket();
    }

    const setDeleteId = (index, e, value) => {
        let filterDisply = displayresponse.filter((ele, i) => {
            return i !== index
        })
        setDisplayResponse(filterDisply)
        // let  filterResponce = response.filter((ele,i)=>{
        Object.keys(response).map((key) => {
            console.log(key, value.language)
            if (key === value.language) {
                delete response[key]
            }
        })
        // }) 
        console.log("delete", response)
        // let filterResponce = response.filter((ele,i)=>{
        //     return i !== index
        // })
        // setDisplayResponse(filterDisply)
    }
    useEffect(() => {
        if (reload === true) {
            fetchBucket();
            setReload(false)

        }

    }, [reload])

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(left);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setLeft(items)
    }

    const searchBucket = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/search/bucket/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(
                res => {
                    let object = res.data.result.result.filter(ele => {
                        if (typeof (ele.names) === "string") {
                            ele.names = JSON.parse(ele.names)
                            return ele
                        }
                        return ele

                    })

                    setRight(object)


                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    

    const searchContent = (e) => {
        if (e.key === "Enter") {

            searchBucket(searched)
        }
    }

    const deleteLeftData =() =>{
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    }

    const getKeyByValue=(object, value)=> {
        return Object.keys(object).find(key => 
                object[key] === value);
    }

    const refreshList = () =>{
        let lang = langList.filter(ele=>{
            return ele.code === language
        })
        console.log(lang);
        filterBucketByLanguage(lang[0].name)
    }

    const bucketList = (title, items) => (

        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="Refresh">
                    <IconButton aria-label="delete" onClick={refreshList}>
                        <RefreshOutlined />
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <div className={classes1.contentSearch}>
                <div className={classes1.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes1.inputRoot,
                        input: classes1.inputInput,
                    }}
                    value={searched}
                    onChange={(e) => setSearched(e.target.value)}
                    // inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={(e) => searchContent(e)}

                />
            </div>
            <List className={classes.list} dense component="div" role="list" >
                {isLoading===true?<CircularProgress style={{textAlign:"center"}}/>:items.map((value, index) => {
                    const labelId = `${value._id}`;

                    return (
                        <ListItem role="listitem" button onClick={handleToggle(value)} >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} >{value.names[0].en} - ({value.language}) - ({value.contentType ? value.contentType : "No content type"})</ListItemText>

                        </ListItem>

                    );
                })}
            </List>
        </Card>
    );

    const Genre = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                action={
                    <Tooltip title="delete">
                    <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                        <DeleteForeverOutlinedIcon className={leftChecked.length === 0?null:classes1.btndelete}/>
                    </IconButton>
                    </Tooltip>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value._id}`;

                                return (
                                    <Draggable key={value._id} draggableId={value._id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} >{value.names[0].en} - ({value.language}) - ({value.contentType ? value.contentType : null})</ListItemText>


                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );



    return (
        <Fragment>
            <Paper spacing={3}>
                <h1 style={{ padding: "30px" }}>{pageName}</h1>

            </Paper>
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                <Grid item md={8}>
                    <FormControl variant="outlined" className={classes.formControl} style={{ marginLeft: "-40px", minWidth: "20%" }}>
                        <Select
                            id="demo-simple-select-outlined"
                            value={language}
                            onChange={(e) => handleLangugeChange(e)}
                        >{langList ? langList.map((ele, index) => (
                            <MenuItem key={index} value={ele.code}>{ele.name}</MenuItem>

                        ))
                            : <MenuItem >No Language</MenuItem>}


                        </Select>

                    </FormControl>

                </Grid>
                <Grid item>
                    <h4>Category</h4>
                    {Genre('Choices', left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item><h4>Bucket</h4>{bucketList('Chosen', right)}


                </Grid>
                <Grid item md={12} style={{ marginLeft: "170px" }}>
                    <Button variant="contained" disabled={left.length === 0} color="primary" onClick={onAdd}>Add</Button>

                </Grid>

            </Grid>
            {

                displayresponse ?
                    <Grid container>
                        <Grid md={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Language</TableCell>
                                            <TableCell>Bucket</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>


                                    {displayresponse.map((value, index) => (
                                        <TableBody>
                                            <TableRow key={value ? value._id : null}>
                                                <TableCell>{value.language ? value.language : null}</TableCell>
                                                <TableCell><Button variant="contained" color="primary" onClick={() => toggle(value ? value : null)}>View</Button></TableCell>
                                                <TableCell>
                                                    <EditOutlinedIcon onClick={(e) => editLeft(e, value)} className={classes1.btnedit} />
                                                    <DeleteForeverOutlinedIcon
                                                        className={classes1.btndelete}
                                                        onClick={(e) => { setDeleteId(index, e, value) }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>

                                    ))}


                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item md={9} style={{ marginLeft: "170px" }}>
                            <Button className="mt-3" variant="contained" color="primary" onClick={onSubmit}>submit</Button>

                        </Grid>
                    </Grid> : null
            }
            {/* Modal */}
            <Modal style={{ marginTop: "100px" }} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Bucket List</ModalHeader>
                <ModalBody>
                    {items ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>id</TableCell>
                                        <TableCell>name</TableCell>
                                    </TableRow>
                                </TableHead>


                                {items.map(value => (
                                    <TableBody>
                                        <TableRow key={value.id}>
                                            <TableCell>{value._id}</TableCell>
                                            <TableCell>{value.names[0].en}</TableCell>
                                        </TableRow>
                                    </TableBody>

                                ))

                                }


                            </Table>
                        </TableContainer>
                        : <p>nodata</p>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleClose}>Close</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}
