import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    InputLabel,
    Select,
    Story,
    MenuItem,
    Tooltip

} from '@material-ui/core';
import Switch from "react-switch";
import InfoIcon from "@material-ui/icons/InfoRounded"
import { useStyles } from '../../styles/style';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { onPostPage } from './../../Redux/Actions/pageAction';
import { StoreMallDirectory } from '@material-ui/icons';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import {Link,useHistory} from 'react-router-dom';
import store from '../../Redux/store';
import CancelIcon from '@material-ui/icons/Cancel';


export default function CreatePage(props) {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [deepLink, setDeepLink] = useState("");
    const [addId, setAddId] = useState("");
    const [iname, setName] = useState("");
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [enable, setEnable] = useState("true");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");
    const [modal, setModal] = useState(false);
    const [hindiSubTitle, setHindiSubTitle] = useState("");
    const [marathiSubTitle, setMarathiiSubTitle] = useState("");
    const [kannadaSubTitle, setKannadaSubTitle] = useState("");
    const [teluguSubTitle, setTeluguSubTitle] = useState("");
    const [punjabiSubTitle, setPunjabiSubTitle] = useState("");
    const [tamilSubTitle, setTamilSubTitle] = useState("");
    const [gujaratiSubTitle, setGujaratiSubTitle] = useState("");
    const [submodal, setSubModal] = useState();
    const [stories, setStories] = useState([]);
    const [app, setApp] = useState([]);
    const [appId, setAppId] = useState("");
    const [storeId,setStoreId] = useState("")
    const navigate = useHistory(); 
    const [v0, setv0] = useState([]);
    const [v2, setv2] = useState([]);
    const [continueToggle, setcontinueToggle] = useState(false)
    const toggle = () => setModal(!modal);
    let data = localStorage.getItem("storeId");

    const subToggle = () => {
        setSubModal(!submodal);
    }

    const handlev0 = (e) => {
        setv0([...v0, ...e.target.files])
    }

    const deletev0 = (e, i) => {
        let newArray = v0.filter((ele, index) => {
            return index !== i;
        })

        setv0(newArray)
    }

    const handlev2 = (e) => {
        setv2([...v2, ...e.target.files])
    }

    const deletev2 = (e, i) => {
        let newArray = v2.filter((ele, index) => {
            return index !== i;
        })

        setv2(newArray)
    }

    const fetchApp = (id) => {
    //     let data = localStorage.getItem("storeId");

    //    if(data){
        // setStoreId(localStorage.getItem("storeId"));
        axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/app?storeId=${id}`)
        .then(res => {
          setApp(res.data.result.result)
        }).catch(err => {
          console.log(err)
        })
    //    }
    //    else{
    //     navigate.push('/login');
    //    }

      }

    useEffect(() => {
        // let data = localStorage.getItem("storeId");
        // setStoreId(data);
        if(data){
        fetchApp(data)
        }
        else{
            navigate.push('/login');
           }
    }, [data])


    const enhandleChange = (event) => {
        setEnable(event.target.value);

    };

    const onPostpage = async (data) => {
        const fd = new FormData()
        fd.append("type", data.type);
        fd.append("pageCode",data.pageCode);
        fd.append("enabled", data.enabled);
        fd.append("title", JSON.stringify(data.title));
        fd.append("subTitle", JSON.stringify(data.subTitle));
        fd.append("appId", data.appId);
        fd.append("storeId", data.storeId);
        fd.append("iname", data.iname);
        fd.append("isBrandHub",data.isBrandHub);
        fd.append("deepLink", data.deepLink);
        fd.append("addId", data.addId);
        for (let index = 0; index < data.file.length; index++) {
            const element = data.file[index];
            fd.append('file', element)
        }
        for (let index = 0; index < data.thumbnail.length; index++) {
            const element = data.thumbnail[index];
            fd.append('thumbnail', element)
        }
            await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, fd)
                .then(
                    res => {
                            cogoToast.success(res.data.result.message);

                        }
                ).catch(err => {
                    cogoToast.error("somthing went wrong!");
                })
        }
    

        var str2bool = (value) => {
            if (value && typeof value === "string") {
                if (value.toLowerCase() === "true") return true;
                if (value.toLowerCase() === "false") return false;
            }
            return value;
        }    
    const onSubmit = () => {
        const obj = {
            "type": "page",
            "pageCode": code,
            "enabled":str2bool(enable),
            "title": {
                "hn": hindiLang,
                "mr": marathiLang,
                "en": title,
                "kn_in": kannadaLang,
                "te_in": teluguLang,
                "pa_in": punjabiLang,
                "ta_in": tamilLang,
                "gu_in": gujaratiLang
            },
            "subTitle": {
                "hn": hindiSubTitle,
                "mr": marathiSubTitle,
                "en": subTitle, 
                "kn_in": kannadaSubTitle,
                "te_in": teluguSubTitle,
                "pa_in": punjabiSubTitle,
                "ta_in": tamilSubTitle,
                "gu_in": gujaratiSubTitle
            },
            "appId":appId,
            "storeId":localStorage.getItem("storeId"),
            "iname":iname,
            "file": v0,
            "thumbnail":v2,
            "isBrandHub":str2bool(continueToggle),
            "deepLink": deepLink,
            "addId":addId
        }
        // console.log(obj)
        onPostpage(obj)
        setCode("");
        setName("");
        setTitle("");
        setSubTitle("");
        setAppId("");
        setStoreId("");
    }

    const handleChangeToggle = (e) =>{
        console.log('ee',e)
        setcontinueToggle(e)
    }

    const onClear = () => {
        setCode("");
        setName("");
        setTitle("");
        setSubTitle("");
        setAppId("");
        setStoreId("");
    }


    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div className="text-center">
                    <h1>Add Pages</h1>
                    <Grid container className="text-center">
                        <Grid item md={6}>
                        <FormControl variant="outlined" className={classes.formControl} style={{minWidth:"60%"}}>
                                <InputLabel id="demo-simple-select-outlined-label">Apps</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={appId}
                                    onChange={(e) => setAppId(e.target.value)}
                                    label="Pages"
                                >
                                    {
                                        app && app.length > 0 ?
                                            app.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element._id}>{element.title}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No app</MenuItem>

                                    }

                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Identifire Name" name="iname" value={iname} onChange={e => setName(e.target.value)} ></TextField>
                        </Grid>
                        {/* <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Stores</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={storeId}
                                onChange={(e) => setStoreId(e.target.value)}
                                label="Stores"
                            >
                                {
                                    content_type && content_type.length > 0 ?
                                        content_type.map((element, id) => {
                                            return (
                                                <MenuItem key={id} value={element.storeId}><img src={element.image}/>&nbsp;{element.description}</MenuItem>
                                            )
                                        }) :
                                        <MenuItem disabled value="no pages">No Stores Available</MenuItem>
                                }

                            </Select>

                        </FormControl>
                        </Grid> */}
                    </Grid>
                    <Grid container >
                    {/* <Grid item md={9} sm={12} >
                            


                        </Grid> */}
                        
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Code" name="code" value={code} onChange={e => setCode(e.target.value)} ></TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputFieldBucket} width="25" required label="Title" name="title" value={title} onChange={e => setTitle(e.target.value)} >
                            </TextField>
                            <Button style={{ height: "56px" }} variant="contained" onClick={toggle}>Multi-Language</Button>
                        </Grid>
                        <Grid item md={6} sm={12}>
                        <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={enable} onChange={enhandleChange} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>
                            
                        </Grid>
                        
                        <Grid item md={6} sm={12}>
                        <TextField variant="outlined" className={classes.inputFieldBucket} width="25" required label="Sub-Title" name="subTitle" value={subTitle} onChange={e => setSubTitle(e.target.value)} >
                            </TextField>
                            <Button style={{ height: "56px" }} variant="contained" onClick={subToggle}>Multi-Language</Button>

                        </Grid>
                        <Grid item md={6} sm={12}>
            Is BrandHub :&nbsp;<Switch 
            onChange={handleChangeToggle} value={continueToggle} checked={continueToggle}
             />
                </Grid>
                <Grid style={continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Banners
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev0}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v0.length > 0 ?
                                (v0.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev0(e, i)} /></p>))
                                : null}

                        </Grid>
                        <Grid style={continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Heading
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={handlev2}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                            </Button>
                            {v2.length > 0 ?
                                (v2.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deletev2(e, i)} /></p>))
                                : null}

                        </Grid>
                <Grid style={continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="Deep Link" name="deepLink" value={deepLink} onChange={e => setDeepLink(e.target.value)} ></TextField>
                        </Grid>
                        <Grid style={continueToggle == true ? { visibility: "visible" } : { visibility: "hidden" }} item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="AddId" name="addId" value={addId} onChange={e => setAddId(e.target.value)} ></TextField>
                        </Grid>
                        
                        <Grid item md={12}>
                            <Button className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                        </Grid>
                    </Grid>

                    {/* Title Modal */}
                    <Modal style={{ marginTop: "100px" }} isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>MultiLanguage Title</ModalHeader>
                        <ModalBody>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="English" name="name" value={title} onChange={e => setTitle(e.target.value)}>
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Hindi" name="name" value={hindiLang} onChange={e => setHindiLang(e.target.value)}>
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Marathi" name="name" value={marathiLang} onChange={e => setMarathiiLang(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Kannada" name="name" value={kannadaLang} onChange={e => setKannadaLang(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Telugu" name="name" value={teluguLang} onChange={e => setTeluguLang(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Punjabi" name="name" value={punjabiLang} onChange={e => setPunjabiLang(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Tamil" name="name" value={tamilLang} onChange={e => setTamilLang(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Gujrati" name="name" value={gujaratiLang} onChange={e => setGujaratiLang(e.target.value)} >
                            </TextField>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={toggle}>Save</Button>{' '}
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    {/* Sub-Title Modal */}
                    <Modal style={{ marginTop: "100px" }} isOpen={submodal} toggle={subToggle}>
                        <ModalHeader toggle={subToggle}>MultiLanguage SubTitle</ModalHeader>
                        <ModalBody>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="English" name="subtitle" value={subTitle} onChange={e => setSubTitle(e.target.value)}>
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Hindi" value={hindiSubTitle} onChange={e => setHindiSubTitle(e.target.value)}>
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Marathi" value={marathiSubTitle} onChange={e => setMarathiiSubTitle(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Kannada" value={kannadaSubTitle} onChange={e => setKannadaSubTitle(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Telugu" value={teluguSubTitle} onChange={e => setTeluguSubTitle(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Punjabi" value={punjabiSubTitle} onChange={e => setPunjabiSubTitle(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Tamil" value={tamilSubTitle} onChange={e => setTamilSubTitle(e.target.value)} >
                            </TextField>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Gujrati" value={gujaratiSubTitle} onChange={e => setGujaratiSubTitle(e.target.value)} >
                            </TextField>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={subToggle}>Save</Button>{' '}
                            <Button color="secondary" onClick={subToggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </Paper>
        </Fragment>
    )
}
